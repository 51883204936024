import { List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface MDWithCompMappingProps {
  content?: string
}

const MDWithCompMapping: React.FC<MDWithCompMappingProps> = ({ content }) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => <Typography variant='body1'>{props.children}</Typography>,
        ol: ({ node, ...props }) => <List dense>{props.children}</List>,
        ul: ({ node, ...props }) => <List dense>{props.children}</List>,
        li: ({ node, ...props }) => (
          <ListItem>
            <ListItemText disableTypography>
              <Typography component='span' variant='body1'>
                {props.children}
              </Typography>
            </ListItemText>
          </ListItem>
        )
      }}
    >
      {content ?? ''}
    </ReactMarkdown>
  )
}

export default MDWithCompMapping
