import { BaseApiController, Region, RegionalConfig, AuthProvider, AppConfig } from '@mit/aws-react'
import appConfig from '../../app-config'

// Override any methods from BaseApiController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  public readonly apiPath: string
  public readonly appConfig: AppConfig
  public readonly apiHost: string
  public readonly apiPathDigitalId: string
  public readonly apiPathPiDashboard: string
  public readonly apiPathFeature: string
  private readonly activeRegion: RegionalConfig

  public constructor () {
    super()
    this.appConfig = appConfig as unknown as AppConfig
    const region = new Region(this.appConfig)

    this.activeRegion = region.getActiveRegionConfig()
    this.apiHost = `https://${this.activeRegion.api.domain}`
    this.apiPath = this.activeRegion.api.paths.elo
    this.apiPathDigitalId = this.activeRegion.api.paths['digital-id']
    this.apiPathPiDashboard = this.activeRegion.api.paths.piDashboard
    this.apiPathFeature = this.activeRegion.api.paths.feature
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }

  /*
    If you do not use cognito, implement your authorization header logic here
  */
  public async getAuthorizationHeaders (): Promise<Record<string, string>> {
    const token = await new AuthProvider(this.activeRegion).getToken()

    return {
      Authorization: `Bearer ${token}`
    }
  }
}
