import { Box, Icon, TextField, Typography } from '@mui/material'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { searchBoxRef } from 'store'

interface ListFilterInputBoxProps {
  label: string
  changeHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  value?: string
  defaultValue?: string
}

export const ListFilterInputBox: React.FC<ListFilterInputBoxProps> = ({ label, changeHandler, value, defaultValue }) => {
  return (
    <TextField
      type='search'
      defaultValue={defaultValue}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ display: 'flex', alignSelf: 'center', mr: 1, fontSize: '14.4px' }}>
            <FontAwesomeIcon icon={['far', 'magnifying-glass']} color='grey' />
          </Icon>
          <Typography>{label}</Typography>
        </Box>
      }
      size='small'
      variant='filled'
      inputProps={{
        ref: searchBoxRef
      }}
      onChange={changeHandler}
    />
  )
}
