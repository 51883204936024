import { Action, action } from 'easy-peasy'

interface State {
  myValue: boolean
}
interface Actions {
  setMyValue: Action<this, boolean>
}
// eslint-disable-next-line
interface Thunks {}

export interface ISessionStorageModel extends State, Actions, Thunks {}

export const SessionStorageModel: ISessionStorageModel = {
  myValue: true,
  setMyValue: action((state, payload) => {
    state.myValue = payload
  })
}
