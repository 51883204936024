import { Box, Grid } from '@mui/material'
import { FilterDropDown } from 'components/Filters/FilterDropDown'
import { CardsSkeleton, ListFilterInputBox, NoResults } from 'components/UIComponents'
import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useStoreActions, useStoreState } from 'store/hooks'
import { components } from 'types/api'
import AwardItemCardComp from '../AwardItem/AwardItemCardComp'
import { AwardTypeFilterDropDown } from './AwardTypeFilterDropDown'

export const AwardsCards: React.FC = () => {
  const [doFetchInitialAwards, setDoFetchInitialAwards] = useState(true)
  const { profileFetched } = useStoreState((store) => store.ProfileModel)
  const { section, isLeafNodeDepartment } = useStoreState((store) => store.HigherLevelAwardsModel)
  const { allAwardsLists, awardsListTotalPages, awardsListCurrentPage, awardsListTotalItems, listFilters, showSkeleton, pageToFetch, fetchError, reFetchAwards, rolledUp } =
    useStoreState((store) => store.AwardsModel)
  const { setListFilters, clearListFilters, fetchInitialPage, fetchMorePages, setFiltersChanged, setPageToFetch, incrementPageToFetch, setRolledUp } = useStoreActions(
    (store) => store.AwardsModel
  )

  const { ref, inView /* , entry */ } = useInView({
    /* Optional options */
    triggerOnce: false,
    initialInView: true,
    threshold: 0
  })

  const adminRolesCheck = true
  useEffect(() => {
    if (reFetchAwards || doFetchInitialAwards) {
      // if (listLength === 0 || (listLength > 0 && pageToFetch === 1 && filtersChanged)) {
      setDoFetchInitialAwards(false)
      if (profileFetched) {
        fetchInitialPage({ listFilters, adminRolesCheck, rolledUp }) /* .catch(() => setFetchError(true)) */
      }
    }
  }, [adminRolesCheck, fetchInitialPage, doFetchInitialAwards, listFilters, profileFetched, reFetchAwards, rolledUp])

  useEffect(() => {
    if (pageToFetch > 1) {
      if (awardsListCurrentPage < awardsListTotalPages && pageToFetch > awardsListCurrentPage) {
        fetchMorePages({ pageToFetch, listFilters, adminRolesCheck, rolledUp }) /* .catch(() => setFetchError(true)) */
      }
    }
  }, [pageToFetch, listFilters, awardsListCurrentPage, awardsListTotalPages, awardsListTotalItems, fetchMorePages, adminRolesCheck, rolledUp])

  useEffect(() => {
    if (inView) {
      incrementPageToFetch()
    }
  }, [inView, incrementPageToFetch])

  useEffect(() => {
    return () => {
      setPageToFetch(1)
      clearListFilters()
      setRolledUp(false)
    }
  }, [clearListFilters, setPageToFetch, setRolledUp])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setListFilters({ title: e.target.value })
    setFiltersChanged(true)
    setPageToFetch(1)
  }

  const debounceHandleSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      handleSearch(e)
    },
    800,
    { leading: false }
  )

  return (
    <>
      <Box sx={{ p: 1, px: 0, width: '100%' }}>
        <Box sx={{ mt: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ListFilterInputBox
            label='Filter List'
            changeHandler={(e) => {
              debounceHandleSearch(e)
            }}
          />
          <Box>
            <FilterDropDown filterFor='Awards' />
            {section === 'deptItems' && !isLeafNodeDepartment && <AwardTypeFilterDropDown />}
          </Box>
        </Box>
        {/* <Divider sx={{ mt: 2 }} /> */}
        {allAwardsLists[0]?.results?.length === 0 && !showSkeleton && <NoResults page='Awards' />}
        <>
          <Grid container spacing={2.5} sx={{ mt: 0 /* , justifyContent: 'center' */ }}>
            {showSkeleton || (allAwardsLists.length === 0 && !fetchError) ? (
              <CardsSkeleton />
            ) : (
              allAwardsLists.length > 0 &&
              allAwardsLists.map((page, idx1) => {
                return page.results?.map((listItem, idx2) => <MemoGridItem key={listItem.awardNumber} idx1={idx1} idx2={idx2} listItem={listItem} />)
              })
            )}
            {awardsListCurrentPage < awardsListTotalPages && !fetchError && <CardsSkeleton />}
          </Grid>
          <Box ref={ref} />
        </>
      </Box>
    </>
  )
}

interface MemoGridItemProps {
  idx1: number
  idx2: number
  listItem: components['schemas']['AwardResponse']
}

const MemoGridItem = React.memo(
  ({ idx1, idx2, listItem }: MemoGridItemProps) => {
    return (
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        xl={3}
        // ref={idx1 === allProposalsLists.length - 1 && idx2 === ITEMS_PER_PAGE - 1 ? ref : undefined}
      >
        <AwardItemCardComp item={listItem} cardIndex={idx2} />
      </Grid>
    )
  },
  (prevProps, nextProps) => prevProps.listItem.awardNumber === nextProps.listItem.awardNumber
)
