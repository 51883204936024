import { Action, action, Thunk, thunk } from 'easy-peasy'
import { Profile } from 'types'
import { profileController } from 'api/controller'

interface State {
  profile: Profile
  profilePicture: string
  profileFetched: boolean
}
interface Actions {
  updateProfile: Action<this, Profile>
  updateProfilePicture: Action<this, string>
  setProfileFetched: Action<this, boolean>
}
interface Thunks {
  // fetchProfile: Thunk<this>
  // fetchProfilePicture: Thunk<this>
  fetchProfileWithPicture: Thunk<this>
}

export interface IProfileModel extends State, Actions, Thunks {}

export const ProfileModel: IProfileModel = {
  profile: {
    affiliation: '',
    department: '',
    display_name: '',
    email: '',
    first_name: '',
    initials: '',
    kerberos: '',
    kerberos_id: '',
    krb_name: '',
    last_name: '',
    mitHROrganisationId: '',
    mit_id: '',
    mitid: '',
    office_location: '',
    phone_number: '',
    source: ''
  },
  profilePicture: '',
  profileFetched: false,

  // ACTIONS
  updateProfile: action((state, payload) => {
    state.profile = { ...state.profile, ...payload }
  }),
  updateProfilePicture: action((state, payload) => {
    state.profilePicture = payload
  }),
  setProfileFetched: action((state, payload) => {
    state.profileFetched = true
  }),

  // THUNKS
  fetchProfileWithPicture: thunk(async (actions) => {
    Promise.all([profileController.getProfile(), profileController.getProfilePicture()])
      .then((respArr) => {
        actions.updateProfile(respArr[0])
        actions.updateProfilePicture(URL.createObjectURL(respArr[1]))
        actions.setProfileFetched(true)
      })
      .catch((e) => {
        actions.setProfileFetched(true)
        console.log(e)
      })
  })
}
