import React, { useEffect, useState } from 'react'
import { components } from 'types'
import { useStoreActions, useStoreState } from 'store/hooks'
import { Avatar, Box, Paper, Stack, Typography, Skeleton } from '@mui/material'
import sx from 'mui-sx'
import { animateEntranceSx } from 'components/styles'
import { profileController } from '../../../api/controller/ProfileController'

interface PiSingleItemProps {
  pi: components['schemas']['SummaryResponse']
  i: number
  sectionToSet: 'Award' | 'Proposal'
}

export const PiSingleItem: React.FC<PiSingleItemProps> = ({ pi, i, sectionToSet }) => {
  const piPicture = useStoreState((store) => store.HigherLevelProposalsModel.piPicByCode(pi.code))
  const {
    setSection: setProposalSection,
    pushBreadcrumbItems: pushProposalBcItems,
    removeLastBreadcrumbItem,
    pushPiPicture
  } = useStoreActions((store) => store.HigherLevelProposalsModel)
  const { setListFilters: setProposalsListFilters } = useStoreActions((store) => store.ProposalsModel)
  const { setListFilters: setAwardsListFilters } = useStoreActions((store) => store.AwardsModel)
  const { setSection: setAwardSection, pushBreadcrumbItems: pushAwardBcItems } = useStoreActions((store) => store.HigherLevelAwardsModel)
  const [profilePic, setProfilePic] = useState(() => piPicture?.url)
  const [loadingPic, setLoadingPic] = useState(false)

  const handleClick = (piCode?: string): void => {
    if (piCode !== undefined) {
      if (sectionToSet === 'Award') {
        setAwardsListFilters({ principalInvestigatorCode: piCode })
        setAwardSection('piItems')
        pushAwardBcItems({
          clickHandler: () => {
            setAwardSection('piList')
            removeLastBreadcrumbItem()
          }
        })
      } else {
        setProposalsListFilters({ principalInvestigatorCode: piCode })
        setProposalSection('piItems')
        pushProposalBcItems({
          clickHandler: () => {
            setAwardSection('piList')
            removeLastBreadcrumbItem()
          }
        })
      }
    }
  }

  const { code } = pi
  useEffect(() => {
    if (profilePic == null) {
      setLoadingPic(true)
      profileController
        .getProfilePicture(code)
        .then((res) => {
          const objURL = URL.createObjectURL(res)
          setProfilePic(objURL)
          if (code != null) {
            pushPiPicture({ piCode: code, url: objURL })
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingPic(false))
    }
  }, [code, profilePic, pushPiPicture])

  return (
    <Paper
      component='button'
      onClick={() => handleClick(pi.code)}
      elevation={0}
      sx={sx(
        {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
          borderRadius: '16px',
          width: '100%',
          p: 3,
          boxSizing: 'border-box',
          cursor: 'pointer'
        },
        animateEntranceSx(i / 5)
      )}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {loadingPic ? (
          <Skeleton variant='circular' sx={{ width: '64px', height: '64px' }} />
        ) : (
          <Avatar sx={{ width: 64, height: 64 }} alt={profilePic === '' ? undefined : pi.name} src={profilePic === '' ? undefined : profilePic}>
            {profilePic === ''
              ? undefined
              : pi.name
                ?.split(', ')
                .reverse()
                .map((word) => word[0])
                .join('')}
          </Avatar>
        )}
        <Box sx={{ ml: 2, textAlign: 'left' }}>
          <Typography sx={{ my: 0 }} variant='h5'>
            {pi.name}
          </Typography>
          <Typography variant='body1'>{pi.description}</Typography>
        </Box>
      </Box>
      <Stack sx={{ mr: 2 }} alignItems='end' spacing={0}>
        <Typography variant='body1' color='#029600' fontWeight='600'>
          {`${pi.contextCount ?? 0} ${sectionToSet}s`}
        </Typography>
        <Typography variant='body1'>{`${pi.count ?? 0} ${sectionToSet}s in total`}</Typography>
      </Stack>
    </Paper>
  )
}
