import { Box, List, ListItem, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { components } from 'types/api'
import StackedCircularCharts from '../../AwardItem/StackedCircularCharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// const CircProgSx = (color?: string): SxProps => {
//   return {
//     boxShadow: 'inset 0px 0px 0px 11px lightgrey',
//     borderRadius: '50%',
//     color: color ?? undefined
//   }
// }
export interface CircleGraphWithListProps {
  items: Array<{ title: string, value: string, color?: string }>
  color?: string
  // progress: number
  currentFinancial: components['schemas']['AwardSummaryFinancialResponse'] | undefined
  finFetchError?: boolean
  // finFetchError?: { message: string, statusCode: number }
  chartsData: {
    totalAmountSpent: number | undefined
    availableBalance: number | undefined
    outerChartSize: number
    innerChartSize: number
    currentDateProgress: number
    readonly timeLeftProgress: number
    readonly innerChartPosition: number
    readonly loadingFinancials: boolean
  }
}

const CircleGraphWithList: React.FC<CircleGraphWithListProps> = ({ items, color, /* progress  */ finFetchError, chartsData, currentFinancial }) => {
  return (
    <Box sx={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'start', mx: 1, minWidth: '350px' }}>
      <Box sx={{ pt: 1.5 }}>
        <StackedCircularCharts {...{ currentFinancial, chartsData }} />
      </Box>
      {/* <CircularProgress sx={CircProgSx(color)} variant='determinate' thickness={6} size={80} value={progress} />{' '} */}
      <List dense sx={{ width: 'calc(100% - 90px)' }}>
        {items.map((line, index) => (
          <ListItem key={`LI-${line.title}- ${line.value}`} divider={index !== items.length - 1} sx={{ width: '100%', justifyContent: 'space-between' }}>
            <Typography variant='body1'>{line.title}</Typography>
            <Typography variant='body1' sx={{ fontWeight: '600', color: index === 1 ? color : line.color }}>
              {finFetchError !== undefined && finFetchError ? (
                // {finFetchError !== undefined ? (
                <Tooltip arrow title='Financial info could not be retrieved'>
                  {/* <Tooltip arrow title={`Financial info could not be retrieved (Status Code: ${finFetchError.statusCode})`}> */}
                  <Box>
                    <FontAwesomeIcon icon={['far', 'circle-question']} color='rgba(0,0,0,0.2)' />
                  </Box>
                </Tooltip>
              ) : (
                line.value
              )}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default CircleGraphWithList
