import React from 'react'
import { components } from 'types/api'
import { Box, CircularProgress } from '@mui/material'
import { DougnutChart, fadeIn } from 'components/UIComponents'

interface StackedCircularChartsProps {
  currentFinancial: components['schemas']['AwardSummaryFinancialResponse'] | undefined
  // currentFinancial: components['schemas']['AwardFinancialResponse'] | undefined
  chartsData: {
    totalAmountSpent: number | undefined
    availableBalance: number | undefined
    outerChartSize: number
    innerChartSize: number
    currentDateProgress: number
    readonly timeLeftProgress: number
    readonly innerChartPosition: number
    readonly loadingFinancials: boolean
  }
}

const StackedCircularCharts: React.FC<StackedCircularChartsProps> = ({ chartsData, currentFinancial }) => {
  const { outerChartSize, innerChartSize, innerChartPosition, loadingFinancials, currentDateProgress, timeLeftProgress } = chartsData
  return (
    <Box sx={{ width: `${outerChartSize}px`, height: `${outerChartSize}px`, flex: '0 0 auto', /* justifySelf: 'flex-end', margin: '0 0 auto auto',  */ position: 'relative' }}>
      <>
        {loadingFinancials && <CircularProgress sx={{ width: '100% !important', height: '100% !important' }} color='info' thickness={6} disableShrink />}
        {!loadingFinancials && (
          <Box sx={{ width: `${outerChartSize}px`, '& div.Target-root': { padding: '0px' } /* , animation: `${fadeIn} 2s ease-out both` */ }}>
            <DougnutChart
              data={[
                { value: 'Total Amount Spent', area: currentFinancial?.totalAmountSpent ?? 0 },
                { value: 'Available Balance', area: currentFinancial?.availableBalance !== 0 ? currentFinancial?.availableBalance ?? 1 : 1 }
              ]}
              colors={['#41B040', '#0000001A']}
              size={outerChartSize}
              innerRadius={0.75}
            />
          </Box>
        )}
        <Box
          sx={{
            width: `${innerChartSize}px`,
            position: 'absolute',
            top: `${innerChartPosition}px`,
            left: `${innerChartPosition}px`,
            '& div.Target-root': { padding: '0px' },
            animation: `${fadeIn} 2s ease-out both`
          }}
        >
          <DougnutChart
            data={[
              { value: 'Current', area: currentDateProgress ?? 0 },
              { value: 'End Date', area: timeLeftProgress ?? 1 }
            ]}
            colors={['#52D5FF', '#0000001A']}
            size={innerChartSize}
            innerRadius={(0.9 * innerChartSize) / outerChartSize}
          />
        </Box>
      </>
    </Box>
  )
}

export default React.memo(StackedCircularCharts, (prevProps, nextProps) => prevProps.currentFinancial?.accountNumber === nextProps.currentFinancial?.accountNumber)
