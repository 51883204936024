import { Action, action, Computed, computed, Thunk, thunk } from 'easy-peasy'
import { components } from '../../types/api'
import { piDashController } from '../../api/controller/PiDashController'
import { ITEMS_PER_PAGE } from 'store'

interface State {
  fetchedProposalsList: components['schemas']['ProposalListResponse']
  allProposalsLists: Array<components['schemas']['ProposalListResponse']>
  proposalsListTotalPages: number
  proposalsListTotalItems: number
  proposalsListCurrentPage: number
  proposalsSummary: Array<components['schemas']['SummaryResponse']>
  listFilters: components['schemas']['ProposalResponse']
  fetchError: boolean
  showSkeleton: boolean
  loadingMore: boolean
  filtersChanged: boolean
  pageToFetch: number
  reFetchProposals: Computed<this, boolean>
  rolledUp: boolean
}
interface Actions {
  setFetchedProposalsList: Action<this, components['schemas']['ProposalListResponse']>
  setAllProposalsLists: Action<this, Array<components['schemas']['ProposalListResponse']>>
  pushAllProposalsLists: Action<this, components['schemas']['ProposalListResponse']>
  setProposalsListTotalPages: Action<this, number>
  setProposalsListTotalItems: Action<this, number>
  setProposalsListCurrentPage: Action<this, number>
  setProposalsSummary: Action<this, Array<components['schemas']['SummaryResponse']>>
  setListFilters: Action<this, {}>
  clearListFilters: Action<this>
  setFetchError: Action<this, boolean>
  setShowSkeleton: Action<this, boolean>
  setLoadingMore: Action<this, boolean>
  setFiltersChanged: Action<this, boolean>
  setPageToFetch: Action<this, number>
  incrementPageToFetch: Action<this>
  setRolledUp: Action<this, boolean>
}
// eslint-disable-next-line
interface Thunks {
  fetchInitialPage: Thunk<this, { listFilters: components['schemas']['ProposalResponse'], adminRolesCheck: boolean, rolledUp: boolean }>
  fetchMorePages: Thunk<this, { pageToFetch: number, listFilters: components['schemas']['ProposalResponse'], adminRolesCheck: boolean, rolledUp: boolean }>
}

export interface IProposalsModel extends State, Actions, Thunks {}

const initialListFilters = {
  proposalNumber: '',
  proposalId: '',
  documentNumber: '',
  primeSponsorCode: '',
  primeSponsorName: '',
  title: '',
  proposalStatusCode: '',
  proposalStatusDescription: '',
  sponsorCode: '',
  sponsorName: '',
  principalInvestigatorCode: '',
  principalInvestigatorName: '',
  leadUnitId: '',
  leadUnitName: '',
  startDate: '',
  endDate: '',
  deadlineDate: ''
}

export const ProposalsModel: IProposalsModel = {
  fetchedProposalsList: {},
  allProposalsLists: [],
  proposalsListTotalPages: 0,
  proposalsListTotalItems: 0,
  proposalsListCurrentPage: 0,
  proposalsSummary: [{}],
  fetchError: false,
  showSkeleton: false,
  loadingMore: false,
  filtersChanged: false,
  pageToFetch: 1,
  reFetchProposals: computed(
    (state) => (state.allProposalsLists.length === 0 && !state.showSkeleton) || (state.allProposalsLists.length > 0 && state.pageToFetch === 1 && state.filtersChanged)
  ),
  listFilters: { ...initialListFilters },
  rolledUp: false,

  // Actions
  setFetchedProposalsList: action((state, payload) => {
    state.fetchedProposalsList = payload
  }),

  setAllProposalsLists: action((state, payload) => {
    state.allProposalsLists = payload
  }),

  pushAllProposalsLists: action((state, payload) => {
    state.allProposalsLists.push(payload)
  }),

  setProposalsListCurrentPage: action((state, payload) => {
    state.proposalsListCurrentPage = payload
  }),

  setProposalsListTotalPages: action((state, payload) => {
    state.proposalsListTotalPages = payload
  }),

  setProposalsListTotalItems: action((state, payload) => {
    state.proposalsListTotalItems = payload
  }),

  setProposalsSummary: action((state, payload) => {
    state.proposalsSummary = payload
  }),

  setListFilters: action((state, payload) => {
    state.listFilters = { ...state.listFilters, ...payload }
  }),

  clearListFilters: action((state) => {
    state.listFilters = { ...initialListFilters }
  }),

  setFetchError: action((state, payload) => {
    state.fetchError = payload
  }),

  setShowSkeleton: action((state, payload) => {
    state.showSkeleton = payload
  }),

  setLoadingMore: action((state, payload) => {
    state.loadingMore = payload
  }),

  setFiltersChanged: action((state, payload) => {
    state.filtersChanged = payload
  }),

  setPageToFetch: action((state, payload) => {
    state.pageToFetch = payload
  }),

  incrementPageToFetch: action((state) => {
    state.pageToFetch += 1
  }),

  setRolledUp: action((state, payload) => {
    state.rolledUp = payload
  }),

  // Thunks
  fetchInitialPage: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    const { listFilters, adminRolesCheck, rolledUp } = payload
    if (!state.showSkeleton) {
      actions.setShowSkeleton(true)
    }
    if (state.fetchError) {
      actions.setFetchError(false)
    }
    try {
      actions.setFetchError(false)
      let data: components['schemas']['ProposalListResponse'] = {}
      if (rolledUp) {
        data = await piDashController.getRolledUpProposals(`${ITEMS_PER_PAGE}`, '1', listFilters)
      } else {
        data = await piDashController.getProposals(`${ITEMS_PER_PAGE}`, '1', listFilters, adminRolesCheck)
      }
      actions.setProposalsListTotalPages(data.totalPages ?? 0)
      actions.setProposalsListTotalItems(data.totalItems ?? 0)
      actions.setAllProposalsLists([data])
      actions.setProposalsListCurrentPage(1)
      // if (state.fetchError) {
      //   actions.setFetchError(false)
      // }
    } catch (err: any) {
      console.log(err)
      if (err.name !== 'AbortError') {
        actions.setFetchError(true)
        return await Promise.reject(err)
      }
    } finally {
      actions.setShowSkeleton(false)
      actions.setFiltersChanged(false)
    }
  }),

  fetchMorePages: thunk(async (actions, payload, { getState }) => {
    const { pageToFetch, listFilters, adminRolesCheck, rolledUp } = payload
    const state = getState()
    if (state.loadingMore) {
      actions.setLoadingMore(true)
    }
    if (state.fetchError) {
      actions.setFetchError(false)
    }
    try {
      let data: components['schemas']['ProposalListResponse'] = {}
      if (rolledUp) {
        data = await piDashController.getRolledUpProposals(`${ITEMS_PER_PAGE}`, `${pageToFetch}`, listFilters)
      } else {
        data = await piDashController.getProposals(`${ITEMS_PER_PAGE}`, `${pageToFetch}`, listFilters, adminRolesCheck)
      }
      actions.setProposalsListCurrentPage(payload.pageToFetch)
      if ((data.totalPages ?? 0) !== getState().proposalsListTotalPages) {
        actions.setProposalsListTotalPages(data.totalPages ?? 0)
      }
      if ((data.totalItems ?? 0) !== getState().proposalsListTotalItems) {
        actions.setProposalsListTotalItems(data.totalItems ?? 0)
      }
      actions.pushAllProposalsLists(data)
      if (state.fetchError) {
        actions.setFetchError(false)
      }
    } catch (err: any) {
      console.log(err)
      if (err.name !== 'AbortError') {
        actions.setFetchError(true)
        return await Promise.reject(err)
      }
    } finally {
      actions.setLoadingMore(false)
    }
  })
}
