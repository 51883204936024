import React from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { Slide } from '@mui/material'
import { keyframes } from '@emotion/react'

export const SlideUp = React.forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const SlideDown = React.forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='down' ref={ref} {...props} />
})

export const SlideLeft = React.forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='left' ref={ref} {...props} />
})

export const SlideRight = React.forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='right' ref={ref} {...props} />
})

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
`
export const fadeDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
`
export const fadeShadow = keyframes`
  100% {
    opacity: 0;
  }
`

export const bounceIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`
