import { Box, Grid, IconButton, Typography } from '@mui/material'
import { FilterDropDown } from 'components/Filters/FilterDropDown'
import { CardsSkeleton, DialogModal, GenericError, /* , FetchErrorToast,  SearchBoxIconWrapper */ ListFilterInputBox, NoResults } from 'components/UIComponents'
import MDWithCompMapping from 'components/UIComponents/Common/MDWithCompMapping'
// import { ProtocolsFilterModal } from 'components/Filters/ProtocolsFilterModal'
import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
import { useStoreActions, useStoreState } from 'store/hooks'
import ProtocolItemCardComp from '../ProtocolItem/ProtocolItemCardComp'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { components } from 'types'
// import { ITEMS_PER_PAGE } from '../../store/constants'

export const ProtocolsPage: React.FC = () => {
  const { appName } = useStoreState((state) => state.AppConfigModel)
  const [fetchError, setFetchError] = useState(false)
  // const [pageToFetch, setPageToFetch] = useState<number>(1)
  // const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const { profileFetched } = useStoreState((store) => store.ProfileModel)
  const [infoDialogueOpen, setInfoDialogueOpen] = useState(false)
  const { texts } = useStoreState((state) => state.GlobalModel)
  const infoTextTitle = texts.find((entry) => entry.textId === 'protocols.info.title')?.text
  const infoTextBody = texts.find((entry) => entry.textId === 'protocols.info.body')?.text
  const {
    allProtocolsLists,
    protocolsListTotalPages,
    protocolsListCurrentPage,
    protocolsListTotalItems,
    listFilters,
    showSkeleton,
    // filtersChanged,
    /* loadingMore, */ pageToFetch /* , fetchError */,
    reFetchProtocols
  } = useStoreState((store) => store.ProtocolsModel)
  const { setListFilters, fetchInitialPage, fetchMorePages, setFiltersChanged, setPageToFetch, incrementPageToFetch /* , setFetchError */ } = useStoreActions(
    (store) => store.ProtocolsModel
  )

  const { ref, inView /* , entry */ } = useInView({
    /* Optional options */
    triggerOnce: false,
    initialInView: true,
    threshold: 0
  })

  // const listLength = allProtocolsLists.length
  useEffect(() => {
    if (reFetchProtocols) {
      // if (listLength === 0 || (listLength > 0 && pageToFetch === 1 && filtersChanged)) {
      if (profileFetched) {
        fetchInitialPage(listFilters).catch(() => setFetchError(true))
      }
    }
  }, [fetchInitialPage, listFilters, profileFetched, reFetchProtocols])
  // }, [pageToFetch, listLength, listFilters, filtersChanged, fetchInitialPage, profileFetched])

  useEffect(() => {
    if (pageToFetch > 1) {
      if (protocolsListCurrentPage < protocolsListTotalPages && pageToFetch > protocolsListCurrentPage) {
        fetchMorePages({ pageToFetch, listFilters }).catch(() => setFetchError(true))
      }
    }
  }, [pageToFetch, listFilters, protocolsListCurrentPage, protocolsListTotalPages, protocolsListTotalItems, fetchMorePages])

  useEffect(() => {
    if (inView) {
      incrementPageToFetch()
      // setPageToFetch((ptf) => ptf + 1)
    }
  }, [inView, incrementPageToFetch])

  useEffect(() => {
    return () => setPageToFetch(1)
  }, [setPageToFetch])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setListFilters({ title: e.target.value })
    setFiltersChanged(true)
    setPageToFetch(1)
  }

  const debounceHandleSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      handleSearch(e)
    },
    800,
    { leading: false }
  )

  return (
    <>
      <Helmet>
        <title>PROTOCOLS</title>
        <meta name='description' content={` ${appName} PROTOCOLS PAGE DESCRIPTION`} />
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <Typography variant='h3' sx={{ color: 'common.AtlasBrand.brand_2.main', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box component='span'>NDA / DUA</Box>
          <IconButton onClick={() => setInfoDialogueOpen(true)}>
            <InfoOutlinedIcon />
          </IconButton>
        </Typography>
        <Box sx={{ mt: 2.5, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {/* <SearchBoxIconWrapper /> */}
          <ListFilterInputBox
            label='Filter List'
            changeHandler={(e) => {
              debounceHandleSearch(e)
            }}
          />
          <FilterDropDown filterFor='Protocols' />

          {/* <IconButton sx={{ ml: 1 }} aria-label='filter' onClick={() => setShowFilterModal(true)}>
            <FontAwesomeIcon icon={['far', 'filter']} color='#097AFF' />
          </IconButton>
          <ProtocolsFilterBadges listFilters={listFilters} /> */}
        </Box>
        {/* <Divider sx={{ mt: 2 }} /> */}
        {allProtocolsLists[0]?.results?.length === 0 && !showSkeleton && <NoResults page='Protocols' />}
        <Grid container spacing={2.5} sx={{ mt: 0 }}>
          {showSkeleton || (allProtocolsLists.length === 0 && !fetchError) ? (
            <CardsSkeleton />
          ) : (
            allProtocolsLists.length > 0 &&
            allProtocolsLists.map((page, idx1) => {
              return page.results?.map((listItem, idx2) => <MemoGridItem key={listItem.protocolId} idx2={idx2} listItem={listItem} />)
            })
          )}
          {protocolsListCurrentPage < protocolsListTotalPages && !fetchError && <CardsSkeleton />}
        </Grid>
        <Box ref={ref} />
        {fetchError && <GenericError page='Protocols' />}
        {/* <FetchErrorToast open={fetchError} closeHandler={() => setFetchError(false)} /> */}
        {/* <ProtocolsFilterModal
          open={showFilterModal}
          closeHandler={() => setShowFilterModal(false)}
          applyBtnHandler={() => {
            setFiltersChanged(true)
            setPageToFetch(1)
          }}
        /> */}
      </Box>
      <DialogModal open={infoDialogueOpen} closeHandler={() => setInfoDialogueOpen(false)} title={<Typography variant='h3'>{infoTextTitle}</Typography>}>
        <MDWithCompMapping content={infoTextBody} />
      </DialogModal>
    </>
  )
}

interface MemoGridItemProps {
  idx2: number
  listItem: components['schemas']['ProtocolResponse']
}

const MemoGridItem = React.memo(
  ({ idx2, listItem }: MemoGridItemProps) => {
    return (
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        xl={3}
        // ref={idx1 === allProposalsLists.length - 1 && idx2 === ITEMS_PER_PAGE - 1 ? ref : undefined}
      >
        <ProtocolItemCardComp item={listItem} index={idx2} />
      </Grid>
    )
  },
  (prevProps, nextProps) => prevProps.listItem.protocolId === nextProps.listItem.protocolId
)
