import { Box, Grid, IconButton, Typography } from '@mui/material'
import { FilterDropDown } from 'components/Filters/FilterDropDown'
import { CardsSkeleton, GenericError, /* , FetchErrorToast, SearchBoxIconWrapper */ ListFilterInputBox, NoResults } from 'components/UIComponents'
import MDWithCompMapping from 'components/UIComponents/Common/MDWithCompMapping'
// import { AgreementsFilterModal } from 'components/Filters/AgreementsFilterModal'
import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
import { useStoreActions, useStoreState } from 'store/hooks'
import AgreementItemCardComp from '../AgreementItem/AgreementItemCardComp'
import { DialogModal } from '../UIComponents/Common/DialogModal'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { components } from 'types'
// import { ITEMS_PER_PAGE } from '../../store/constants'

export const AgreementsPage: React.FC = () => {
  const { appName } = useStoreState((state) => state.AppConfigModel)
  const [fetchError, setFetchError] = useState(false)
  // const [pageToFetch, setPageToFetch] = useState<number>(1)
  // const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const { profileFetched } = useStoreState((store) => store.ProfileModel)
  const [infoDialogueOpen, setInfoDialogueOpen] = useState(false)
  const { texts } = useStoreState((state) => state.GlobalModel)
  const infoTextTitle = texts.find((entry) => entry.textId === 'nda-dua.info.title')?.text
  const infoTextBody = texts.find((entry) => entry.textId === 'nda-dua.info.body')?.text
  const {
    allAgreementsLists,
    agreementsListTotalPages,
    agreementsListCurrentPage,
    agreementsListTotalItems,
    listFilters,
    showSkeleton,
    // filtersChanged,
    /* loadingMore, */ pageToFetch /* , fetchError */,
    reFetchAgreements
  } = useStoreState((store) => store.AgreementsModel)
  const { setListFilters, fetchInitialPage, fetchMorePages, setFiltersChanged, setPageToFetch, incrementPageToFetch /* , setFetchError */ } = useStoreActions(
    (store) => store.AgreementsModel
  )

  const { ref, inView /* , entry */ } = useInView({
    /* Optional options */
    triggerOnce: false,
    initialInView: true,
    threshold: 0
  })

  // const listLength = allAgreementsLists.length
  useEffect(() => {
    if (reFetchAgreements) {
      // if (listLength === 0 || (listLength > 0 && pageToFetch === 1 && filtersChanged)) {
      if (profileFetched) {
        fetchInitialPage(listFilters).catch(() => setFetchError(true))
      }
    }
  }, [fetchInitialPage, listFilters, profileFetched, reFetchAgreements])
  // }, [pageToFetch, listLength, listFilters, filtersChanged, fetchInitialPage, profileFetched])

  useEffect(() => {
    if (pageToFetch > 1) {
      if (agreementsListCurrentPage < agreementsListTotalPages && pageToFetch > agreementsListCurrentPage) {
        fetchMorePages({ pageToFetch, listFilters }).catch(() => setFetchError(true))
      }
    }
  }, [pageToFetch, listFilters, agreementsListCurrentPage, agreementsListTotalPages, agreementsListTotalItems, fetchMorePages])

  useEffect(() => {
    if (inView) {
      incrementPageToFetch()
      // setPageToFetch((ptf) => ptf + 1)
    }
  }, [inView, incrementPageToFetch])

  useEffect(() => {
    return () => setPageToFetch(1)
  }, [setPageToFetch])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setListFilters({ title: e.target.value })
    setFiltersChanged(true)
    setPageToFetch(1)
  }

  const debounceHandleSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      handleSearch(e)
    },
    800,
    { leading: false }
  )

  return (
    <>
      <Helmet>
        <title>AGREEMENTS</title>
        <meta name='description' content={` ${appName} AWARDS PAGE DESCRIPTION`} />
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <Typography variant='h3' sx={{ color: 'common.AtlasBrand.brand_2.main', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box component='span'>NDA / DUA</Box>
          <IconButton onClick={() => setInfoDialogueOpen(true)}>
            <InfoOutlinedIcon />
          </IconButton>
        </Typography>
        <Box sx={{ mt: 2.5, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {/* <SearchBoxIconWrapper /> */}
          <ListFilterInputBox
            label='Filter List'
            changeHandler={(e) => {
              debounceHandleSearch(e)
            }}
          />
          <FilterDropDown filterFor='Agreements' />

          {/* <IconButton sx={{ ml: 1 }} aria-label='filter' onClick={() => setShowFilterModal(true)}>
            <FontAwesomeIcon icon={['far', 'filter']} color='#097AFF' />
          </IconButton>
          <AgreementsFilterBadges listFilters={listFilters} /> */}
        </Box>
        {/* <Divider sx={{ mt: 2 }} /> */}
        {allAgreementsLists[0]?.results?.length === 0 && !showSkeleton && <NoResults page='Agreements' />}
        <Grid container spacing={2.5} sx={{ mt: 0 }}>
          {showSkeleton || (allAgreementsLists.length === 0 && !fetchError) ? (
            <CardsSkeleton />
          ) : (
            allAgreementsLists.length > 0 &&
            allAgreementsLists.map((page, idx1) => {
              return page.results?.map((listItem, idx2) => <MemoGridItem key={listItem.agreementId} idx2={idx2} listItem={listItem} />)
            })
          )}
          {agreementsListCurrentPage < agreementsListTotalPages && !fetchError && <CardsSkeleton />}
        </Grid>
        <Box ref={ref} />
        {fetchError && <GenericError page='Proposals' />}
        {/* <FetchErrorToast open={fetchError} closeHandler={() => setFetchError(false)} /> */}
        {/* <AgreementsFilterModal
          open={showFilterModal}
          closeHandler={() => setShowFilterModal(false)}
          applyBtnHandler={() => {
            setFiltersChanged(true)
            setPageToFetch(1)
          }}
        /> */}
      </Box>
      <DialogModal open={infoDialogueOpen} closeHandler={() => setInfoDialogueOpen(false)} title={<Typography variant='h3'>{infoTextTitle}</Typography>}>
        <MDWithCompMapping content={infoTextBody} />
      </DialogModal>
    </>
  )
}

interface MemoGridItemProps {
  idx2: number
  listItem: components['schemas']['AgreementResponse']
}

const MemoGridItem = React.memo(
  ({ idx2, listItem }: MemoGridItemProps) => {
    return (
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        xl={3}
        // ref={idx1 === allProposalsLists.length - 1 && idx2 === ITEMS_PER_PAGE - 1 ? ref : undefined}
      >
        <AgreementItemCardComp item={listItem} index={idx2} />
      </Grid>
    )
  },
  (prevProps, nextProps) => prevProps.listItem.agreementId === nextProps.listItem.agreementId
)
