import { Box, Grid, Typography } from '@mui/material'
import { CardsSkeleton, GenericError, NoResults } from 'components/UIComponents'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useStoreActions, useStoreState } from 'store/hooks'
import ActionItemCardComp from '../ActionItem/ActionItemCardComp'

export const ActionsPage: React.FC = () => {
  const { appName } = useStoreState((state) => state.AppConfigModel)
  const [fetchError, setFetchError] = useState(false)
  const { profileFetched } = useStoreState((store) => store.ProfileModel)
  const { actions, loading } = useStoreState((store) => store.ActionsModel)
  const { fetchActions } = useStoreActions((store) => store.ActionsModel)
  const { results } = actions

  useEffect(() => {
    if (profileFetched) {
      setFetchError(false)
      fetchActions().catch(() => setFetchError(true))
    }
  }, [fetchActions, profileFetched])

  const getStateToRender = (): JSX.Element => {
    if (loading) {
      return (
        <Grid container spacing={2.5} sx={{ mt: 0 }}>
          <CardsSkeleton />
        </Grid>
      )
    }
    if (fetchError) {
      return <GenericError page='Actions' />
    }
    if (results?.length !== undefined) {
      if (results.length > 0) {
        return (
          <Grid container spacing={2.5} sx={{ mt: 0 }}>
            {results?.map((item, i) => (
              <Grid xs={12} md={6} lg={4} xl={3} item key={`AI-page-${i}`}>
                <ActionItemCardComp item={item} index={i} />
              </Grid>
            ))}
          </Grid>
        )
      } else {
        return <NoResults page='Actions' />
      }
    }
    return <></>
  }

  return (
    <>
      <Helmet>
        <title>ACTIONS</title>
        <meta name='description' content={` ${appName} ACTIONS PAGE DESCRIPTION`} />
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <Typography variant='h3' sx={{ color: 'common.AtlasBrand.brand_2.main' }}>
          Actions
        </Typography>
        {getStateToRender()}
      </Box>
    </>
  )
}
