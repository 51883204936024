import { Action, action, Thunk, thunk } from 'easy-peasy'
import { components } from 'types'
import { piDashController } from 'api/controller/PiDashController'

interface State {
  gettingStartedItems: Array<components['schemas']['GettingStartedResponse']>
  configuredItems: Array<components['schemas']['GettingStartedResponse']>
  removedItems: Array<components['schemas']['GettingStartedResponse']>
  configureActive: boolean
  loadingItems: boolean
}
interface Actions {
  setGettingStartedItems: Action<this, Array<components['schemas']['GettingStartedResponse']>>
  setRemovedItems: Action<this, Array<components['schemas']['GettingStartedResponse']>>
  addToRemovedItems: Action<this, components['schemas']['GettingStartedResponse']>
  clearFromRemovedItems: Action<this, components['schemas']['GettingStartedResponse']>
  setConfiguredItems: Action<this, Array<components['schemas']['GettingStartedResponse']>>
  addToConfiguredItems: Action<this, components['schemas']['GettingStartedResponse']>
  clearFromConfiguredItems: Action<this, components['schemas']['GettingStartedResponse']>
  setConfigureActive: Action<this, boolean>
  setLoadingItems: Action<this, boolean>
}
interface Thunks {
  fetchGettingStartedItems: Thunk<this>
}

export interface IGettingStartedModel extends State, Actions, Thunks {}

export const GettingStartedModel: IGettingStartedModel = {
  gettingStartedItems: [],
  configuredItems: [],
  removedItems: [],
  configureActive: false,
  loadingItems: false,

  // ACTIONS
  setGettingStartedItems: action((state, payload) => {
    state.gettingStartedItems = payload
  }),
  setRemovedItems: action((state, payload) => {
    state.removedItems = payload
  }),
  addToRemovedItems: action((state, payload) => {
    state.removedItems = [...state.removedItems].concat([payload])
  }),
  clearFromRemovedItems: action((state, payload) => {
    state.removedItems = [...state.removedItems].filter((listItem) => listItem.key !== payload.key)
  }),
  setConfiguredItems: action((state, payload) => {
    state.configuredItems = payload
  }),
  addToConfiguredItems: action((state, payload) => {
    state.configuredItems = [...state.configuredItems].concat([payload])
  }),
  clearFromConfiguredItems: action((state, payload) => {
    state.configuredItems = [...state.configuredItems].filter((listItem) => listItem.key !== payload.key)
  }),
  setConfigureActive: action((state, payload) => {
    state.configureActive = payload
  }),
  setLoadingItems: action((state, payload) => {
    state.loadingItems = payload
  }),

  // THUNKS
  fetchGettingStartedItems: thunk(async (actions) => {
    actions.setLoadingItems(true)
    try {
      const items = await piDashController.getGettingStarted()
      const sortedItems = items.sort((a, b) => {
        return (a.defaultPosition ?? 0) - (b.defaultPosition ?? 0)
      })
      actions.setGettingStartedItems(sortedItems)
      actions.setConfiguredItems(sortedItems)
    } catch (e) {
      console.log(e)
    } finally {
      actions.setLoadingItems(false)
    }
  })
}
