import React from 'react'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { Stack, Typography, Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatCurrency } from '../../../common/formatCurrency'

interface IconTitleValueStackProps {
  icon: IconName
  title: string
  currencyValue?: number
  stringValue?: string
  color: string
}

export const IconTitleValueStack: React.FC<IconTitleValueStackProps> = ({ icon, title, currencyValue, stringValue, color }) => {
  return (
    <Stack direction='column' justifyContent='center' alignItems='flex-end' spacing={1} sx={{ minWidth: '220px' }}>
      <Box component='span' sx={{ opacity: 0.5 }}>
        <FontAwesomeIcon icon={['fal', icon]} size='3x' />
      </Box>
      <Typography variant='body1'>{title}</Typography>
      <Typography variant={currencyValue !== undefined ? 'h2' : stringValue !== undefined ? 'h4' : 'body1'}>
        <Box component='span' sx={{ color: color }}>
          {currencyValue !== undefined ? formatCurrency.format(currencyValue) : stringValue ?? null}
        </Box>
      </Typography>
    </Stack>
  )
}
