import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useState } from 'react'
import { useStoreState, useStoreActions } from 'store/hooks'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export type FilterOptions = 'Internal' | 'All'

export const PiViewFilterDropDown: React.FC = () => {
  const { piListQueryParams: piListFilters } = useStoreState((store) => store.HigherLevelProposalsModel)
  const { setPiListQueryParams: setPiListFilters } = useStoreActions((store) => store.HigherLevelProposalsModel)
  const { onlyInternal } = piListFilters
  const [selection, setSelection] = useState<FilterOptions>(() => (onlyInternal ? 'Internal' : 'All'))

  const handleChange = (e: SelectChangeEvent): void => {
    const value: FilterOptions = e.target.value as FilterOptions
    setPiListFilters({ onlyInternal: value === 'Internal' })
    // setPiListPageToFetch(1)
    setSelection(value)
  }

  return (
    <FormControl variant='filled' sx={{ mx: 0.5, minWidth: 150 }}>
      <InputLabel id='demo-simple-select-filled-label'>View</InputLabel>
      <Select
        sx={{ backgroundColor: '#E1E6EC' }}
        labelId='demo-simple-select-filled-label'
        id='demo-simple-select-filled'
        value={selection}
        size='small'
        onChange={handleChange}
        IconComponent={ExpandMoreIcon}
      >
        <MenuItem value='All'>
          <Box sx={{ display: 'inline-block', fontWeight: 'bold' }}>All</Box>
        </MenuItem>
        <MenuItem value='Internal'>
          <Box sx={{ display: 'inline-block', fontWeight: 'bold' }}>Internal</Box>
        </MenuItem>
      </Select>
    </FormControl>
  )
}
