import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStoreState, useStoreActions } from 'store/hooks'
import { Section } from 'store/models/HigherLevelAwards'

export const ToggleSwitch: React.FC = () => {
  const { section, currentDepartment, isLeafNodeDepartment } = useStoreState((store) => store.HigherLevelAwardsModel)
  const { setSection } = useStoreActions((store) => store.HigherLevelAwardsModel)
  const { setListFilters } = useStoreActions((store) => store.AwardsModel)

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: Section): void => {
    if (newAlignment != null) {
      if (newAlignment === 'deptItems') {
        setListFilters({ leadUnitId: currentDepartment.code })
      }
      setSection(newAlignment)
    }
  }

  return (
    <ToggleButtonGroup value={section} exclusive onChange={handleChange}>
      {!isLeafNodeDepartment && (
        <ToggleButton sx={{ minWidth: '100px' }} value='unitsList'>
          <FontAwesomeIcon icon={['far', 'building']} />
          <Typography ml={1} component='span'>
            Units
          </Typography>
        </ToggleButton>
      )}
      <ToggleButton sx={{ minWidth: '100px' }} value='piList'>
        <FontAwesomeIcon icon={['far', 'user']} />
        <Typography ml={1} component='span'>
          PI
        </Typography>
      </ToggleButton>
      <ToggleButton sx={{ minWidth: '100px' }} value='deptItems'>
        <FontAwesomeIcon icon={['far', 'trophy']} />
        <Typography ml={1} component='span'>
          Awards
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
