import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Chip, Paper, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import { animateEntranceSx, cardSx, lineClamp } from 'components/styles'
import { SmallTitle } from 'components/UIComponents'
import { differenceInDays, format, isBefore } from 'date-fns'
import sx from 'mui-sx'
import numeral from 'numeral'
import React, { useState } from 'react'
import { useStoreState } from 'store/hooks'
import { components } from 'types'
import { getChipColor } from '../../common/getChipColor'
import { CardTitle, fadeIn } from '../UIComponents'
import AwardDetailsDrawer from './AwardDetailsDrawer'
import StackedCircularCharts from './StackedCircularCharts'
import { useLocation } from 'react-router-dom'

const getChipIcon = (status: string): IconName => {
  switch (status) {
    case 'Active':
      return 'play'
    case 'Hold':
    case 'Pending':
      return 'pause'
    case 'Inactive':
    case 'Restricted - No Postings Allowed':
      return 'stop'

    default:
      return 'question'
  }
}

const calculateDateProgress = (today: string, startDate: string, endDate: string): number => {
  const trimDate = (dateString: string): string => dateString.slice(0, 10)
  if (isBefore(new Date(trimDate(today)), new Date(trimDate(startDate)))) return 0
  if (isBefore(new Date(trimDate(endDate)), new Date(trimDate(today)))) return 100
  const totalDays = differenceInDays(new Date(trimDate(endDate)), new Date(trimDate(startDate)))
  const elapsedDays = differenceInDays(new Date(trimDate(today)), new Date(trimDate(startDate)))
  const progress = (elapsedDays / totalDays) * 100
  return progress
}

export interface IChartsData {
  totalAmountSpent: number | undefined
  availableBalance: number | undefined
  outerChartSize: number
  innerChartSize: number
  currentDateProgress: number
  readonly timeLeftProgress: number
  readonly innerChartPosition: number
  readonly loadingFinancials: boolean
}
interface AwardItemCardCompProps {
  item: components['schemas']['AwardResponse']
  cardIndex: number
}

const AwardItemCardComp: React.FC<AwardItemCardCompProps> = ({ item, cardIndex }) => {
  const [open, setOpen] = useState<boolean>(false)
  const currentFinancial = useStoreState((store) => store.AwardsModel.currentFinancial(item.accountNumber))
  const finFetchError = currentFinancial?.errorMessage !== undefined
  const todayDateString = format(new Date(), 'yyyy-MM-dd')
  // const dateProgress = useMemo(() => calculateDateProgress(todayDateString, item.effectiveDate, item.expirationDate), [item.effectiveDate, item.expirationDate, todayDateString])
  const dateProgress = calculateDateProgress(todayDateString, item.effectiveDate, item.expirationDate)

  const chartsData = {
    totalAmountSpent: currentFinancial?.totalAmountSpent,
    availableBalance: currentFinancial?.availableBalance,
    outerChartSize: 39,
    innerChartSize: 25,
    currentDateProgress: dateProgress,
    get timeLeftProgress () {
      return 100 - this.currentDateProgress
    },
    get innerChartPosition () {
      return (this.outerChartSize - this.innerChartSize) / 2
    },
    get loadingFinancials () {
      return (this.totalAmountSpent === undefined || this.availableBalance === undefined) && !finFetchError
    }
  }

  return (
    <>
      <CardContents item={item} currentFinancial={currentFinancial} chartsData={chartsData} finFetchError={finFetchError} cardIndex={cardIndex} setOpen={setOpen} />
      {/* <AwardItemDetailsDialog item={item} open={open} setOpen={setOpen} /> */}
      <AwardDetailsDrawer
        {...{
          item,
          open,
          setOpen,
          chartsData,
          currentTotals: currentFinancial,
          finFetchError
        }}
      />
    </>
  )
}

export default AwardItemCardComp

// Memoized CardContent below to prevent rerendering the content of all the cards when opening and closing the drawer -->
interface CardContentsProps {
  item: components['schemas']['AwardResponse']
  currentFinancial: components['schemas']['AwardSummaryFinancialResponse'] | undefined
  chartsData: IChartsData
  finFetchError: boolean
  cardIndex: number
  setOpen: (arg0: boolean) => void
}

const CardContents: React.FC<CardContentsProps> = ({ item, currentFinancial, chartsData, finFetchError, cardIndex, setOpen }) => {
  const { section } = useStoreState((store) => store.HigherLevelAwardsModel)
  const location = useLocation()
  const showAlternativeCard = location.pathname === '/admin-awards' && section === 'deptItems'
  return (
    <>
      <Paper component='button' elevation={0} sx={sx(cardSx, animateEntranceSx(cardIndex / 10))} onClick={() => setOpen(true)}>
        <Box sx={{ display: 'flex', flexFlow: 'row nowrap', minWidth: '100%', flex: '0 0 auto' }}>
          <CardTitle title={item.title} />
          <Box sx={{ margin: '0 0 auto auto' }}>
            <StackedCircularCharts currentFinancial={currentFinancial} chartsData={chartsData} />
          </Box>
          {/* {finFetchError !== undefined ? (
            <Tooltip arrow title={`Financial info could not be retrieved (StatusCode: ${finFetchError.statusCode})`}>
              <Box sx={{ margin: '0 0 auto auto' }}>
                <FontAwesomeIcon icon={['far', 'triangle-exclamation']} size='2x' color={finFetchError.statusCode >= 500 ? 'red' : 'rgba(0,0,0,0.2)'} />
              </Box>
            </Tooltip>
          ) : (
            <Box sx={{ margin: '0 0 auto auto' }}>
              <StackedCircularCharts {...{ currentFinancial, chartsData }} />
            </Box>
          )} */}
        </Box>
        <Box sx={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', flex: '0 0 auto' }}>
          <Stack sx={{ mr: 2 }} spacing={0}>
            {showAlternativeCard ? (
              <>
                <SmallTitle title='Lead Unit' />
                <Typography sx={lineClamp(2)} variant='body1'>
                  {item.leadUnitName}
                </Typography>
              </>
            ) : (
              <>
                <SmallTitle title='Sponsor' />
                <Typography sx={lineClamp(2)} variant='body1'>
                  {item.sponsorName}
                </Typography>
              </>
            )}
          </Stack>
        </Box>
        <Box sx={{ flex: '0 0 auto', width: '100%' }}>
          <Box sx={{ display: 'flex', flexFlow: 'row nowarp', justifyContent: 'space-between', alignItems: 'flex-end', height: '100%', width: '100%' }}>
            <Chip
              label={
                <Typography component='div' sx={{ p: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <FontAwesomeIcon icon={['fas', getChipIcon(item.awardStatusDescription)]} /> {item.awardStatusDescription}
                </Typography>
              }
              sx={{ maxWidth: '50%', height: '24px', borderRadius: '8px', fontWeight: '400' }}
              color={getChipColor(item.awardStatusDescription)}
            />
            <Box sx={{ display: 'flex', flexFlow: ' column nowrap', alignItems: 'flex-end' }}>
              <SmallTitle title='Available Balance' />
              <Typography variant='body2' sx={{ textAlign: 'right', fontWeight: '600', color: 'rgba(0,0,0,0.76)' }}>
                {currentFinancial?.availableBalance !== undefined && !finFetchError ? (
                  <Box component='span' sx={{ animation: `${fadeIn} 1s ease-out both` }}>
                    {numeral(currentFinancial.availableBalance).format('($ 0,0)')}
                    {/* {formatCurrency.format(currentFinancial.availableBalance)} */}
                  </Box>
                ) : finFetchError ? (
                  // <Tooltip arrow title={`Financial info could not be retrieved (StatusCode: ${finFetchError.statusCode})`}>
                  <Tooltip arrow title='Financial info could not be retrieved'>
                    <Typography variant='body1' color='rgba(0,0,0,0.4)'>
                      {/* <FontAwesomeIcon icon={['far', 'circle-question']} color={finFetchError.statusCode >= 500 ? 'red' : 'rgba(0,0,0,0.2)'} /> */}
                      <FontAwesomeIcon icon={['far', 'triangle-exclamation']} />
                      &nbsp;Unavailable
                      {/* &nbsp;{finFetchError.statusCode >= 500 ? 'Unable to load' : 'Unavailable'} */}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Skeleton variant='rectangular' sx={{ width: '100px', height: '26px' }} />
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  )
}
