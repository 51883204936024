// import actions from 'mock-data/actions.json'
import { add, format } from 'date-fns'
import { ITextsApi } from 'types'
import { components } from '../../types/api'
import { components as taskComponents } from '../../types/api_task'
import { BaseController } from './BaseController'
// import { AwardsFilterFields } from 'types'

class PiDashController extends BaseController {
  readonly url_awards: string
  readonly url_proposals: string
  readonly url_agreements: string
  readonly url_protocols: string
  // readonly url_actions: string
  readonly url_getting_started: string
  readonly url_lookups: string
  readonly url_departments: string
  readonly url_navigation: string
  readonly url_textsApi: string
  readonly url_tasks: string

  constructor () {
    super()

    // this.url_awards = `https://70svwgdd36.execute-api.us-east-1.amazonaws.com/develop/awards`
    // this.url_proposals = `https://70svwgdd36.execute-api.us-east-1.amazonaws.com/develop/proposals`
    // this.url_agreements = `https://70svwgdd36.execute-api.us-east-1.amazonaws.com/develop/agreements`
    // this.url_protocols = `https://70svwgdd36.execute-api.us-east-1.amazonaws.com/develop/protocols`
    // this.url_actions = `https://70svwgdd36.execute-api.us-east-1.amazonaws.com/develop/actions`
    // this.url_getting_started = `https://70svwgdd36.execute-api.us-east-1.amazonaws.com/develop/getting-started`
    // this.url_lookups = `https://70svwgdd36.execute-api.us-east-1.amazonaws.com/develop/lookups`
    this.url_awards = `${this.apiHost}/${this.apiPathPiDashboard}/awards`
    this.url_proposals = `${this.apiHost}/${this.apiPathPiDashboard}/proposals`
    this.url_agreements = `${this.apiHost}/${this.apiPathPiDashboard}/agreements`
    this.url_protocols = `${this.apiHost}/${this.apiPathPiDashboard}/protocols`
    // this.url_actions = `${this.apiHost}/${this.apiPathPiDashboard}/actions`
    this.url_getting_started = `${this.apiHost}/${this.apiPathPiDashboard}/getting-started`
    this.url_lookups = `${this.apiHost}/${this.apiPathPiDashboard}/lookups`
    this.url_departments = `${this.apiHost}/${this.apiPathPiDashboard}/departments`
    this.url_navigation = `${this.apiHost}/${this.apiPathPiDashboard}/navigation-menu`
    this.url_textsApi = `${this.apiHost}/text-v1/texts?appId=pi-dash`
    this.url_tasks = `${this.apiHost}/task-v1/tasks?appId=pi.dash`
  }

  private getOperator (property: string): string {
    switch (property) {
      case 'awardStatusDescription':
      case 'awardStatusCode':
      case 'proposalStatusDescription':
      case 'proposalStatusCode':
      case 'agreementStatusDescription':
      case 'agreementStatusCode':
      case 'protocolStatusDescription':
      case 'protocolStatusCode':
      case 'principalInvestigatorCode':
      case 'leadUnitId':
        return ':'
      case 'sponsor':
      case 'title':
        return '~'
      default:
        return '~'
    }
  }

  private queryBuilder (pageSize: string, page: string, listFilters: any): string {
    // query builder
    let query = `?pageSize=${pageSize}&page=${page}`
    const keyValueArr = Object.entries(listFilters).filter((kv) => kv[1] !== '')
    if (keyValueArr.length > 0) {
      query = query.concat(`&search=${keyValueArr[0][0]}${this.getOperator(keyValueArr[0][0])}${keyValueArr[0][1] as string}`)
      if (keyValueArr.length > 1) {
        keyValueArr.forEach((param, idx) => {
          if (idx > 0) {
            query = query.concat(`,${param[0]}${this.getOperator(param[0])}${param[1] as string}`)
          }
        })
      }
    }
    return query
  }

  private getDateRange (): string[] {
    const today = new Date()
    const yearFromNow = add(today, { years: 1 })
    return [format(today, 'yyyy-MM-dd'), format(yearFromNow, 'yyyy-MM-dd')]
  }

  public async getAwards (
    pageSize: string,
    page: string,
    listFilters: components['schemas']['AwardResponse'],
    adminRolesCheck: boolean = false
  ): Promise<components['schemas']['AwardListResponse']> {
    const response = await this.useFetch(
      'GET',
      `getAwards-page-${page}`,
      `${this.url_awards}${this.queryBuilder(pageSize, page, listFilters)}${adminRolesCheck ? '&adminRolesCheck=true' : ''}`
    )
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getRolledUpAwards (pageSize: string, page: string, listFilters: components['schemas']['AwardResponse']): Promise<components['schemas']['AwardListResponse']> {
    const { leadUnitId } = listFilters
    const response = await this.useFetch(
      'GET',
      `getRolledUpAwards-page-${page}`,
      `${this.url_departments}/${leadUnitId}/awards/${this.queryBuilder(pageSize, page, { ...listFilters, leadUnitId: '' })}`
    )
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getFinancialsList (accountNumbers: Set<string>, fetchKey: string): Promise<Array<components['schemas']['AwardSummaryFinancialResponse']>> {
    const response = await this.useFetch(
      'GET',
      `getFinancialsList-${fetchKey}`,
      `${this.url_awards}/financials?awardNumber=${[...Array.from(accountNumbers)].join('&awardNumber=')}`
    )
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getAwardFinancials (accountNumber: string, financialsIndex?: number, totalsOnly: boolean = false): Promise<components['schemas']['AwardFinancialResponse']> {
    const response = await this.useFetch(
      'GET',
      `getAwardFinancials-${financialsIndex ?? ''}-${accountNumber}`,
      `${this.url_awards}/${accountNumber}/financials${totalsOnly ? '?totalsOnly=true' : ''}`
    )
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    // return await Promise.reject(text)
    // eslint-disable-next-line
    return await Promise.reject({ message: text, statusCode: response.status })
  }

  public async getAwardsSummary (): Promise<Array<components['schemas']['SummaryResponse']>> {
    const response = await this.useFetch('GET', 'getAwardsSummary', `${this.url_awards}/summary`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getDepartmentsAwardsSummary (): Promise<Array<components['schemas']['DepartmentSummaryResponse']>> {
    const response = await this.useFetch('GET', 'getDepartmentsAwardsSummary', `${this.url_departments}/awards/summary`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getDepartmentAwardPiList (
    departmentId: string,
    rollup: boolean,
    page: number,
    onlyInternal: boolean,
    principalInvestigatorName: string
  ): Promise<components['schemas']['SummaryListResponse']> {
    const response = await this.useFetch(
      'GET',
      'getDepartmentsAwardsPiList',
      `${this.url_departments}/${departmentId}/awards/principal-investigators/summary?page=${page}${rollup ? '&rolledUp=true' : ''}${onlyInternal ? '&onlyInternal=true' : ''}${
        principalInvestigatorName !== '' ? `&search=principalInvestigatorName~${principalInvestigatorName}` : ''
      }`
    )
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getProposals (
    pageSize: string,
    page: string,
    listFilters: components['schemas']['ProposalResponse'],
    adminRolesCheck: boolean = false
  ): Promise<components['schemas']['ProposalListResponse']> {
    const response = await this.useFetch(
      'GET',
      `getProposals-page-${page}`,
      `${this.url_proposals}${this.queryBuilder(pageSize, page, listFilters)}${adminRolesCheck ? '&adminRolesCheck=true' : ''}`
    )
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getRolledUpProposals (
    pageSize: string,
    page: string,
    listFilters: components['schemas']['ProposalResponse']
    // adminRolesCheck: boolean = false
  ): Promise<components['schemas']['ProposalListResponse']> {
    const { leadUnitId } = listFilters
    const response = await this.useFetch(
      'GET',
      `getRolledUpProposals-page-${page}`,
      `${this.url_departments}/${leadUnitId}/proposals/${this.queryBuilder(pageSize, page, { ...listFilters, leadUnitId: '' })}`
    )
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getProposalsSummary (): Promise<Array<components['schemas']['SummaryResponse']>> {
    const response = await this.useFetch('GET', 'getProposalSummary', `${this.url_proposals}/summary`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getDepartmentsProposalsSummary (): Promise<Array<components['schemas']['DepartmentSummaryResponse']>> {
    const response = await this.useFetch('GET', 'getDepartmentsProposalsSummary', `${this.url_departments}/proposals/summary`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getDepartmentProposalPiList (
    departmentId: string,
    rollup: boolean,
    page: number,
    onlyInternal: boolean,
    principalInvestigatorName: string
  ): Promise<components['schemas']['SummaryListResponse']> {
    const response = await this.useFetch(
      'GET',
      'getDepartmentsProposalsPiList',
      `${this.url_departments}/${departmentId}/proposals/principal-investigators/summary?page=${page}${rollup ? '&rolledUp=true' : ''}${onlyInternal ? '&onlyInternal=true' : ''}${
        principalInvestigatorName !== '' ? `&search=principalInvestigatorName~${principalInvestigatorName}` : ''
      }`
    )
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getAgreements (pageSize: string, page: string, listFilters: components['schemas']['AgreementResponse']): Promise<components['schemas']['AgreementListResponse']> {
    const response = await this.useFetch('GET', `getAgreements-page-${page}`, `${this.url_agreements}${this.queryBuilder(pageSize, page, listFilters)}`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getAgreementsSummary (): Promise<Array<components['schemas']['SummaryResponse']>> {
    const response = await this.useFetch('GET', 'getAgreementsSummary', `${this.url_agreements}/summary`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getProtocols (pageSize: string, page: string, listFilters: components['schemas']['ProtocolResponse']): Promise<components['schemas']['ProtocolListResponse']> {
    const response = await this.useFetch('GET', `getProtocols-page-${page}`, `${this.url_protocols}${this.queryBuilder(pageSize, page, listFilters)}`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getProtocolsSummary (): Promise<Array<components['schemas']['SummaryResponse']>> {
    const response = await this.useFetch('GET', 'getProtocolsSummary', `${this.url_protocols}/summary`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getGettingStarted (): Promise<Array<components['schemas']['GettingStartedResponse']>> {
    const response = await this.useFetch('GET', 'getGettingStarted', `${this.url_getting_started}`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getLookupStatuses (): Promise<components['schemas']['LookupStatusResponse']> {
    const response = await this.useFetch('GET', 'getLookupStatuses', `${this.url_lookups}/statuses`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getActions (): Promise<taskComponents['schemas']['TaskListResponse']> {
    const [startDate, endDate] = this.getDateRange()
    const response = await this.useFetch('GET', 'getTasks', `${this.url_tasks}&rangeStartDate=${startDate}&rangeEndDate=${endDate}`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  // public async getActions (pageSize: string, page: string, listFilters: any /* components['schemas']['ActionResponse'] */): Promise<TempActionsListJson> {
  //   return await new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve(actions)
  //     }, 1000)
  //   })
  // }

  public async getNavigationItems (): Promise<Array<components['schemas']['MenuItem']>> {
    const response = await this.useFetch('GET', 'getNavItems', `${this.url_navigation}`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getTexts (): Promise<ITextsApi[]> {
    const response = await this.useFetch('GET', 'getTexts', `${this.url_textsApi}`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}

export const piDashController = new PiDashController()
