import { getStage, Region, RegionalConfig } from '@mit/aws-react'
import appConfig from '../app-config'

type EnvReturn = 'develop' | 'release' | 'master'

export const getEnv = (): EnvReturn => {
  switch (getStage(appConfig)) {
    case 'develop':
      return 'develop'
    case 'release':
      return 'release'
    case 'master':
      return 'master'
  }
  return 'master'
}

export const getActiveRegion = (): RegionalConfig => {
  const region = new Region(appConfig)

  return region.getActiveRegionConfig()
}
