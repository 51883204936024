import { Action, action, Computed, computed, Thunk, thunk } from 'easy-peasy'
import { components } from '../../types/api'
import { piDashController } from '../../api/controller/PiDashController'
import { ITEMS_PER_PAGE } from 'store'

interface State {
  fetchedProtocolsList: components['schemas']['ProtocolListResponse']
  allProtocolsLists: Array<components['schemas']['ProtocolListResponse']>
  protocolsListTotalPages: number
  protocolsListTotalItems: number
  protocolsListCurrentPage: number
  protocolsSummary: Array<components['schemas']['SummaryResponse']>
  listFilters: components['schemas']['ProtocolResponse']
  fetchError: boolean
  showSkeleton: boolean
  loadingMore: boolean
  filtersChanged: boolean
  pageToFetch: number
}
interface Actions {
  setFetchedProtocolsList: Action<this, components['schemas']['ProtocolListResponse']>
  setAllProtocolsLists: Action<this, Array<components['schemas']['ProtocolListResponse']>>
  pushAllProtocolsLists: Action<this, components['schemas']['ProtocolListResponse']>
  setProtocolsListTotalPages: Action<this, number>
  setProtocolsListTotalItems: Action<this, number>
  setProtocolsListCurrentPage: Action<this, number>
  setProtocolsSummary: Action<this, Array<components['schemas']['SummaryResponse']>>
  setListFilters: Action<this, {}>
  setFetchError: Action<this, boolean>
  setShowSkeleton: Action<this, boolean>
  setLoadingMore: Action<this, boolean>
  setFiltersChanged: Action<this, boolean>
  setPageToFetch: Action<this, number>
  incrementPageToFetch: Action<this>
  reFetchProtocols: Computed<this, boolean>
}
// eslint-disable-next-line
interface Thunks {
  fetchInitialPage: Thunk<this, components['schemas']['ProtocolResponse']>
  fetchMorePages: Thunk<this, { pageToFetch: number, listFilters: components['schemas']['ProtocolResponse'] }>
}

export interface IProtocolsModel extends State, Actions, Thunks {}

export const ProtocolsModel: IProtocolsModel = {
  fetchedProtocolsList: {},
  allProtocolsLists: [],
  protocolsListTotalPages: 0,
  protocolsListTotalItems: 0,
  protocolsListCurrentPage: 0,
  protocolsSummary: [],
  fetchError: false,
  showSkeleton: false,
  loadingMore: false,
  filtersChanged: false,
  pageToFetch: 1,
  reFetchProtocols: computed((state) => state.allProtocolsLists.length === 0 || (state.allProtocolsLists.length > 0 && state.pageToFetch === 1 && state.filtersChanged)),

  listFilters: {
    protocolNumber: '',
    protocolId: '',
    title: '',
    protocolTypeCode: '',
    protocolTypeDescription: '',
    protocolStatusCode: '',
    protocolStatusDescription: '',
    leadUnitId: '',
    leadUnitName: '',
    principalInvestigatorCode: '',
    principalInvestigatorName: '',
    approvalDate: '',
    expirationDate: ''
  },

  // Actions
  setFetchedProtocolsList: action((state, payload) => {
    state.fetchedProtocolsList = payload
  }),

  setAllProtocolsLists: action((state, payload) => {
    state.allProtocolsLists = payload
  }),

  pushAllProtocolsLists: action((state, payload) => {
    state.allProtocolsLists.push(payload)
  }),

  setProtocolsListCurrentPage: action((state, payload) => {
    state.protocolsListCurrentPage = payload
  }),

  setProtocolsListTotalPages: action((state, payload) => {
    state.protocolsListTotalPages = payload
  }),

  setProtocolsListTotalItems: action((state, payload) => {
    state.protocolsListTotalItems = payload
  }),

  setProtocolsSummary: action((state, payload) => {
    state.protocolsSummary = payload
  }),

  setListFilters: action((state, payload) => {
    state.listFilters = { ...state.listFilters, ...payload }
  }),

  setFetchError: action((state, payload) => {
    state.fetchError = payload
  }),

  setShowSkeleton: action((state, payload) => {
    state.showSkeleton = payload
  }),

  setLoadingMore: action((state, payload) => {
    state.loadingMore = payload
  }),

  setFiltersChanged: action((state, payload) => {
    state.filtersChanged = payload
  }),

  setPageToFetch: action((state, payload) => {
    state.pageToFetch = payload
  }),

  incrementPageToFetch: action((state) => {
    state.pageToFetch += 1
  }),

  // Thunks
  fetchInitialPage: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    if (!state.showSkeleton) {
      actions.setShowSkeleton(true)
    }
    if (state.fetchError) {
      actions.setFetchError(false)
    }
    try {
      const data = await piDashController.getProtocols(`${ITEMS_PER_PAGE}`, '1', payload)
      actions.setProtocolsListTotalPages(data.totalPages ?? 0)
      actions.setProtocolsListTotalItems(data.totalItems ?? 0)
      actions.setAllProtocolsLists([data])
      actions.setProtocolsListCurrentPage(1)
      if (state.fetchError) {
        actions.setFetchError(false)
      }
    } catch (err: any) {
      if (err.name !== 'AbortError') {
        console.log(err)
        actions.setFetchError(true)
        return await Promise.reject(err)
      }
    } finally {
      actions.setShowSkeleton(false)
      actions.setFiltersChanged(false)
    }
  }),

  fetchMorePages: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    if (state.loadingMore) {
      actions.setLoadingMore(true)
    }
    if (state.fetchError) {
      actions.setFetchError(false)
    }
    try {
      const data = await piDashController.getProtocols(`${ITEMS_PER_PAGE}`, `${payload.pageToFetch}`, payload.listFilters)
      actions.setProtocolsListCurrentPage(payload.pageToFetch)
      if ((data.totalPages ?? 0) !== getState().protocolsListTotalPages) {
        actions.setProtocolsListTotalPages(data.totalPages ?? 0)
      }
      if ((data.totalItems ?? 0) !== getState().protocolsListTotalItems) {
        actions.setProtocolsListTotalItems(data.totalItems ?? 0)
      }
      actions.pushAllProtocolsLists(data)
      if (state.fetchError) {
        actions.setFetchError(false)
      }
    } catch (err: any) {
      console.log(err)
      if (err.name !== 'AbortError') {
        actions.setFetchError(true)
        return await Promise.reject(err)
      }
    } finally {
      actions.setLoadingMore(false)
    }
  })
}
