import { Box, Drawer, DrawerProps, IconButton, Typography } from '@mui/material'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface DrawerCompProps extends DrawerProps {
  //  open : boolean
  // onClose?: () => void
  closeHandler: () => void
  title: string
  width?: string
  leftAction?: React.ReactNode
}

export const DrawerComp: React.FC<DrawerCompProps> = ({ leftAction, width, open, closeHandler, title, children, ...other }) => {
  return (
    <Drawer
      sx={{ '& div.MuiDrawer-paper': { width: width ?? '100%', '@media screen and (max-width: 900px)': { width: '100vw' } } }}
      open={open}
      onClose={closeHandler}
      anchor='right'
      {...other}
    >
      {/* <HuiDrawer open={open} gaTrackingName='' header='What is this?' onClose={() => setOpen(false)} anchor='right'> */}
      <Box
        sx={{
          p: 2,
          position: 'sticky',
          top: 0,
          zIndex: 10,
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'common.headerBackground.main'
        }}
      >
        <Typography variant='h4' sx={{ mb: 0, textAlign: 'center' }}>
          {title}
        </Typography>
        <IconButton aria-label='Close drawer' sx={{ position: 'absolute !important', top: '10px', right: '20px' }} onClick={closeHandler}>
          <FontAwesomeIcon icon={['far', 'times']} />
        </IconButton>
        {leftAction ?? null}
      </Box>
      {children}
    </Drawer>
  )
}
