import { Box } from '@mui/material'
import { DrawerComp } from 'components/UIComponents'
import { NextFinancialBlock } from 'components/UIComponents/Common/NextFinancialBlock'
import React, { useCallback, useEffect, useState } from 'react'
import { components } from 'types'
import DrawersBreadcrumbs from './DrawersBreadcrumbs'

interface ExpenseCategoryDrawerProps {
  item: components['schemas']['FinancialRowResponse'] | undefined
  open: boolean
  handleClose: () => void
  title?: string
  level: number
}

const ExpenseCategoryDrawer: React.FC<ExpenseCategoryDrawerProps> = ({ item, open, handleClose, /* nrOfOpenDrawers,setOpen,  */ title, level }) => {
  const clearBreadcrumbsAndClose = useCallback(() => {
    setBreadcrumbItems([{ label: 'Award', onClick: clearBreadcrumbsAndClose }])
    handleClose()
  }, [handleClose])

  const [breadcrumbItems, setBreadcrumbItems] = useState<Array<{ label: string, onClick: () => void }>>([])
  const [nextFinancialBlock, setNextFinancialBlock] = useState<components['schemas']['FinancialRowResponse'] | undefined>(undefined)

  useEffect(() => {
    setNextFinancialBlock(item)
  }, [item])

  useEffect(() => {
    setBreadcrumbItems([{ label: 'Award', onClick: clearBreadcrumbsAndClose }])
  }, [open, clearBreadcrumbsAndClose])

  const breadcrumbItemIndex = breadcrumbItems.findIndex((item) => item.label === nextFinancialBlock?.description)
  useEffect(() => {
    if (nextFinancialBlock?.description !== undefined) {
      if (breadcrumbItemIndex === -1) {
        setBreadcrumbItems((curr) =>
          curr.concat([
            {
              label: nextFinancialBlock.description,
              onClick: () => {
                setNextFinancialBlock(nextFinancialBlock)
                setBreadcrumbItems((curr) => curr.slice(0, breadcrumbItemIndex))
              }
            }
          ])
        )
      }
    }
  }, [nextFinancialBlock, breadcrumbItemIndex])

  return (
    // <DrawerComp width={`calc(100vw - 315px - ${level * 75}px)`} open={open} onClose={handleClose} anchor='right' title={title ?? 'Award Information'}>
    <DrawerComp
      width='50vw'
      open={open}
      closeHandler={clearBreadcrumbsAndClose}
      anchor='right'
      title={title ?? 'Award Information'}
      // leftAction={
      //   <Button
      //     size='small'
      //     variant='text'
      //     aria-label='Close drawer'
      //     sx={{ position: 'absolute !important', height: '100%', top: '0px', left: '20px' }}
      //     onClick={clearBreadcrumbsAndClose}
      //   >
      //     <FontAwesomeIcon icon={['far', 'chevron-left']} />
      //     &nbsp; Back
      //   </Button>
      // }
    >
      <Box sx={{ p: 3 }}>
        <DrawersBreadcrumbs breadcrumbItems={breadcrumbItems} />
        <NextFinancialBlock border='1px solid rgba(0, 0, 0, 0.1)' color='#FF8A00' item={nextFinancialBlock} setNextFinancialBlock={setNextFinancialBlock} />
        {/* <ProjectFinancialBlock border='1px solid rgba(0, 0, 0, 0.1)' color='#FF8A00' title={item?.description ?? ''} items={item?.rows} total={item?.amount ?? 0} level={level} /> */}
      </Box>
    </DrawerComp>
  )
}

export default ExpenseCategoryDrawer
