import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Chip, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { piDashController } from 'api/controller'
import { formatDate, getChipColor } from 'common'
import { AvatarWithTitleStack, DougnutChart, DrawerComp, GenericError, ProjectFinancialBlock } from 'components/UIComponents'
// import { DialogModal } from '../UIComponents'
import CircleGraphWithList from 'components/UIComponents/Common/CircleGraphWithList'
import { format } from 'date-fns'
import numeral from 'numeral'
import React, { useMemo, useState, useEffect } from 'react'
import { EXPENSES_COLORS } from 'store'
import { components } from 'types'
import { IconTitleValueStack } from '../UIComponents/Common/IconTitleValueStack'

interface AwardDetailsDrawerProps {
  item: components['schemas']['AwardResponse']
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentTotals: components['schemas']['AwardSummaryFinancialResponse'] | undefined
  finFetchError: boolean
  // finFetchError: { message: string, statusCode: number } | undefined
  chartsData: {
    totalAmountSpent: number | undefined
    availableBalance: number | undefined
    outerChartSize: number
    innerChartSize: number
    currentDateProgress: number
    readonly timeLeftProgress: number
    readonly innerChartPosition: number
    readonly loadingFinancials: boolean
  }
}

const AwardDetailsDrawer: React.FC<AwardDetailsDrawerProps> = ({ item, open, setOpen, currentTotals, finFetchError, chartsData }) => {
  const [currentFinancial, setCurrentFinancial] = useState<components['schemas']['AwardFinancialResponse'] | undefined>({
    authorizedTotal: currentTotals?.authorizedTotal ?? 0,
    totalAmountSpent: currentTotals?.totalAmountSpent ?? 0,
    committedAmount: currentTotals?.committedAmount ?? 0,
    availableBalance: currentTotals?.availableBalance ?? 0,
    rows: []
  })
  const [fetchError, setFetchError] = useState<boolean>(() => finFetchError)
  const maxWidth1200 = useMediaQuery('(max-width:1200px)')
  const todayDateString = format(new Date(), 'yyyy-MM-dd')
  const expenses = useMemo(() => currentFinancial?.rows?.find((row) => row.description === 'Expenses'), [currentFinancial?.rows])
  const directExpenses = useMemo(() => expenses?.rows?.find((row) => row.description === 'Direct Expenses'), [expenses])
  const inDirectExpenses = useMemo(() => expenses?.rows?.find((row) => row.description === 'Indirect Expenses'), [expenses])
  const doughnutChartData = (
    directExpenses?.rows?.map((expenseCat, i) => {
      return { value: expenseCat.description, area: expenseCat.amount !== undefined ? expenseCat.amount /*  * 0.9 */ : 0 }
    }) ?? []
  ).concat(
    inDirectExpenses?.rows?.map((expenseCat2, i2) => {
      return { value: expenseCat2.description, area: expenseCat2.amount !== undefined ? expenseCat2.amount /*  * 0.9 */ : 0 }
    }) ?? []
  )
  // const directExpenses = useMemo(() => currentFinancial?.rows[0]?.rows?.find((row) => row.description === 'Direct Expenses'), [currentFinancial])
  // const directExpenses = useMemo(
  //   () => currentFinancial?.rows[0]?.rows?.find((row) => row.description === 'Expenses')?.rows?.find((row) => row.description === 'Direct Expenses'),
  //   [currentFinancial]
  // )

  const updatedChartsData = {
    ...chartsData,
    outerChartSize: 80,
    innerChartSize: Math.ceil((80 / 39) * 25),
    get innerChartPosition (): number {
      return (this.outerChartSize - this.innerChartSize) / 2
    }
    // get loadingFinancials(): boolean {
    //   return this.totalAmountSpent === undefined || this.availableBalance === undefined
    // }
  }

  const circleGraph1Data = {
    // progress: getSpendProgress(400, 1000),
    color: '#52A80B',
    data: [
      // { title: 'Start Spend', value: numeral(currentFinancial?.committedAmount ?? 0).format('($ 0,0)') },
      { title: 'Total Amount Spent', value: numeral(currentFinancial?.totalAmountSpent ?? 0).format('($ 0,0)') },
      { title: 'Authorized Total', value: numeral(currentFinancial?.authorizedTotal ?? 0).format('($ 0,0)') }
    ]
  }
  const circleGraph2Data = {
    // progress: getDateProgress(todayDateString, item.effectiveDate, item.expirationDate),
    color: '#00ADE4',
    data: [
      { title: 'Project start date', value: formatDate(item.effectiveDate) },
      { title: 'Current date', value: formatDate(todayDateString) },
      { title: 'Project end date', value: formatDate(item.expirationDate) },
      { title: 'Obligation end date', value: formatDate(item.oblicationExpirationDate), color: '#E3124B' }
    ]
  }

  const accNr = item.accountNumber
  // const hasFetchedFinancials = currentFinancial?.rows !== undefined && currentFinancial?.rows?.length > 0
  const totalsHasRows = currentFinancial?.rows !== undefined && currentFinancial?.rows?.length > 0
  useEffect(() => {
    if (open && !totalsHasRows) {
      setCurrentFinancial({
        authorizedTotal: currentTotals?.authorizedTotal ?? 0,
        totalAmountSpent: currentTotals?.totalAmountSpent ?? 0,
        committedAmount: currentTotals?.committedAmount ?? 0,
        availableBalance: currentTotals?.availableBalance ?? 0,
        rows: []
      })
      piDashController
        .getAwardFinancials(accNr)
        .then((data) => {
          setCurrentFinancial(data)
        })
        .catch((err) => {
          console.log(err)
          setFetchError(true)
        })
    }
  }, [accNr, currentTotals?.authorizedTotal, currentTotals?.availableBalance, currentTotals?.committedAmount, currentTotals?.totalAmountSpent, open, totalsHasRows])

  return (
    <DrawerComp
      width='calc(100vw - 315px)'
      open={open}
      closeHandler={() => setOpen(false)}
      anchor='right'
      title='Award Information'
      leftAction={
        <Button
          size='small'
          variant='text'
          aria-label='Close drawer'
          sx={{ position: 'absolute !important', height: '100%', top: '0px', left: '20px' }}
          onClick={() => setOpen(false)}
        >
          <FontAwesomeIcon icon={['far', 'chevron-left']} />
          &nbsp; Back to Awards
        </Button>
      }
    >
      <Box sx={{ p: 3, m: 1 }}>
        <Typography variant='h3'>{item.title}</Typography>
        <Box sx={{ mt: 3, my: 3 }}>
          <Chip label={item.awardStatusDescription} sx={{ height: '24px', borderRadius: '8px', fontWeight: 'bold' }} color={getChipColor(item.awardStatusDescription)} />
        </Box>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item>
            <AvatarWithTitleStack bold title='Sponsor' name={item.sponsorName} />
          </Grid>
          {item.primeSponsorName !== undefined && (
            <Grid item>
              <AvatarWithTitleStack bold title='Prime Sponsor' name={item.primeSponsorName} />
            </Grid>
          )}
          <Grid item>
            <AvatarWithTitleStack bold title='PI' name={item.principalInvestigatorName} />
          </Grid>
          <Grid item>
            <AvatarWithTitleStack bold title='Lead Unit' name={item.leadUnitName} />
          </Grid>
          <Grid item>
            <AvatarWithTitleStack bold title='Account Number' name={item.accountNumber} />
          </Grid>
          <Grid item>
            <AvatarWithTitleStack bold title='Award Number' name={item.awardNumber} />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ flexWrap: maxWidth1200 ? 'wrap' : 'nowrap', my: 3 }}>
          <Grid item xs={12} lg={6}>
            <CircleGraphWithList
              items={circleGraph1Data.data}
              color={circleGraph1Data.color} /*  progress={circleGraph1Data.progress} */
              currentFinancial={currentTotals}
              // currentFinancial={currentFinancial}
              chartsData={{ ...updatedChartsData, currentDateProgress: 0, timeLeftProgress: 1 }}
              finFetchError={finFetchError}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CircleGraphWithList
              items={circleGraph2Data.data}
              color={circleGraph2Data.color} /*  progress={circleGraph2Data.progress} */
              currentFinancial={undefined}
              chartsData={updatedChartsData}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pr: 3 }} justifyContent='space-evenly'>
          <Grid item>
            <IconTitleValueStack icon='money-check-dollar' title='Authorized Total' currencyValue={currentFinancial?.authorizedTotal ?? 0} color='#008A7D' />
          </Grid>
          <Grid item>
            <IconTitleValueStack icon='file-invoice-dollar' title='Total Amount Spent' currencyValue={currentFinancial?.totalAmountSpent ?? 0} color='#24904B' />
          </Grid>
          <Grid item>
            <IconTitleValueStack icon='message-dollar' title='Committed Amount' currencyValue={currentFinancial?.committedAmount ?? 0} color='#289726' />
          </Grid>
          <Grid item>
            <IconTitleValueStack icon='piggy-bank' title='Available Balance' currencyValue={currentFinancial?.availableBalance ?? 0} color='#52A80B' />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 /* , backgroundColor: '#E8EEF4' */ }}>
        <Typography variant='body1' sx={{ opacity: 0.5 }}>
          {'Project Financials'.toUpperCase()}
        </Typography>
        {/* {finFetchError !== undefined ? ( */}
        {fetchError ? (
          // <GenericError page={`Financials (StatusCode ${finFetchError.statusCode})`} />
          <GenericError page='Financials' />
        ) : (
          <Grid container spacing={4} sx={{ '@media screen and (min-width: 1200px)': { flexWrap: 'nowrap' } }}>
            <Grid item xs={12} lg={12} xl={8}>
              {expenses?.rows?.map((category, i) => (
                <React.Fragment key={category.description}>
                  {category !== undefined
                    ? category.rows?.map((expenseCat, i1) => (
                      <React.Fragment key={`${i1}-${expenseCat.description}`}>
                        <ProjectFinancialBlock
                          color={EXPENSES_COLORS[i1 % EXPENSES_COLORS.length]}
                          title={expenseCat.description}
                          items={expenseCat.rows}
                          level={0}
                          border='1px solid rgba(0, 0, 0, 0.1)'
                          total={expenseCat.amount ?? 0}
                        />
                      </React.Fragment>
                    ))
                    : undefined}
                  <Box sx={{ backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '16px', width: '100%', my: 5, py: 1, px: 3, boxSizing: 'border-box' }}>
                    <Typography variant='body2' sx={{ fontWeight: '600', display: 'flex', justifyContent: 'space-between' }} color='white'>
                      <Box component='span'>{`Total ${category.description}`}</Box>
                      <Box component='span'>{numeral(category?.amount ?? 0).format('($ 0,0)')}</Box>
                    </Typography>
                  </Box>
                </React.Fragment>
              ))}
            </Grid>
            <Grid container spacing={4} item direction={maxWidth1200 ? 'row' : 'column'} justifyContent='flex-start' alignItems='center' xs={12} md={12} lg={4}>
              <Grid item>
                <Paper
                  elevation={0}
                  sx={{
                    my: 5,
                    boxSizing: 'border-box',
                    width: '312px' /* , height: '428px' */,
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    borderRadius: '16px',
                    mx: 'auto',
                    p: 1,
                    py: 2
                  }}
                >
                  <Typography variant='h5' sx={{ textAlign: 'center' }}>
                    Accumulative Expenditure
                  </Typography>
                  <Box
                    width='100%'
                    sx={{
                      '& div.Target-root': { padding: '0px', m: 'auto', '& div#center-axis-container svg': { filter: 'drop-shadow(0px 6px 9px rgba(0, 0, 0, 0.2))' } },
                      '& div#bottom-container > ul > li > svg': { mr: 1 },
                      '& div#center-container': { maxHeight: '56%' },
                      position: 'relative'
                    }}
                  >
                    <DougnutChart
                      data={doughnutChartData}
                      colors={
                        doughnutChartData.map((_, i) => {
                          return EXPENSES_COLORS[i % EXPENSES_COLORS.length]
                          // return ['#E3124B', '#8500FF', '#FF8A00', '#00000040'][i]
                        }) ?? []
                      }
                      innerRadius={0.8}
                      // size={200}
                      height={400}
                      legend
                      animate
                    />
                    <Box
                      sx={{
                        width: '100%',
                        height: '228px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant='h2'>{numeral(currentFinancial?.totalAmountSpent).format('($ 0.00 a)')}</Typography>
                      {/* <Typography variant='h2'>{numeral(directExpenses?.amount ?? 0).format('($ 0.00 a)')}</Typography> */}
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item>
                <Paper
                  elevation={0}
                  sx={{ my: 5, boxSizing: 'border-box', width: '312px', height: '428px', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '16px', mx: 'auto', p: 1, py: 2 }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </DrawerComp>
  )
}

export default AwardDetailsDrawer
