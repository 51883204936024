import React from 'react'
// import './styles.css';
import { Box, Container, IconButton, Paper, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { GridContextProvider, GridDropZone, GridItem, swap, move } from 'react-grid-dnd'
// import { useStoreActions } from 'store/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { components } from 'types'
import cloneDeep from 'lodash/cloneDeep'
import { Items } from 'components/routes'
import { IconName } from '@fortawesome/fontawesome-svg-core'

type ItemsIndex = 'shown' | 'deleted'

interface LayoutSettingsProps {
  items: Items
  setItems: React.Dispatch<React.SetStateAction<Items>>
}

const LayoutSettings: React.FC<LayoutSettingsProps> = ({ items, setItems }) => {
  const minWidth1160 = useMediaQuery('(min-width:1160px)')
  const minWidth900 = useMediaQuery('(min-width:900px)')

  const onChange = (sourceId: string, sourceIndex: number, targetIndex: number, targetId?: string): void => {
    if (targetId !== null && targetId !== undefined) {
      const result = move(items[sourceId as ItemsIndex], items[targetId as ItemsIndex], sourceIndex, targetIndex)
      return setItems({
        ...cloneDeep(items),
        [sourceId]: result[0],
        [targetId]: result[1]
      })
    }

    const result = swap(items[sourceId as ItemsIndex], sourceIndex, targetIndex)
    return setItems({
      ...cloneDeep(items),
      [sourceId]: result
    })
    // const nextState = swap(items, sourceIndex, targetIndex);
    // setItems(nextState);
  }

  // const deletedDropZoneHeight = Math.ceil(items.deleted.length || 1 / 3)
  // const shownDropZoneHeight = Math.ceil(items.shown.length / 3)

  const removeItemHandler = (removedItemKey: string): void => {
    const itemsCopy = cloneDeep(items)
    const newShownItems = itemsCopy.shown.filter((item) => item.key !== removedItemKey)
    const newDeletedItems = itemsCopy.deleted.concat([...itemsCopy.shown.filter((item) => item.key === removedItemKey)])
    setItems({ shown: newShownItems, deleted: newDeletedItems })
  }
  const addItemHandler = (addedItemKey: string): void => {
    const itemsCopy = cloneDeep(items)
    const newShownItems = itemsCopy.shown.concat([...itemsCopy.deleted.filter((item) => item.key === addedItemKey)])
    const newDeletedItems = itemsCopy.deleted.filter((item) => item.key !== addedItemKey)
    setItems({ shown: newShownItems, deleted: newDeletedItems })
  }

  return (
    <Container sx={{ textAlign: 'center' }}>
      <GridContextProvider onChange={onChange}>
        <GridDropZone id='shown' boxesPerRow={minWidth1160 ? 3 : minWidth900 ? 2 : 1} rowHeight={100} style={{ height: minWidth1160 ? '400px' : minWidth900 ? '600px' : '800px' }}>
          {items.shown.map((item) => (
            <GridItem key={`item-${item.key ?? ''}`}>
              <ItemElement item={item} isRemoved={false} removeItemHandler={removeItemHandler} />
            </GridItem>
          ))}
        </GridDropZone>
        <Box sx={{ mt: 5 }}>
          <Typography variant='h3'>Drag items below to remove from view</Typography>
          <GridDropZone
            id='deleted'
            boxesPerRow={minWidth1160 ? 3 : minWidth900 ? 2 : 1}
            rowHeight={100}
            // style={{ height: minWidth1160 ? `${deletedDropZoneHeight*100}px` : minWidth900 ? `'600px'` : '800px' }}>
            style={{ height: minWidth1160 ? '400px' : minWidth900 ? '600px' : '800px', opacity: 0.5, border: '1px dashed black' }}
          >
            {items.deleted.map((item) => (
              <GridItem key={`del-${item.key ?? ''}`}>
                <ItemElement item={item} isRemoved addItemHandler={addItemHandler} />
              </GridItem>
            ))}
          </GridDropZone>
        </Box>
      </GridContextProvider>
    </Container>
  )
}

interface ItemElementProps {
  item: components['schemas']['GettingStartedResponse']
  isRemoved: boolean
  removeItemHandler?: (removedItemKey: string) => void
  addItemHandler?: (addedItemKey: string) => void
}

const ItemElement: React.FC<ItemElementProps> = ({ item, isRemoved, removeItemHandler, addItemHandler }) => {
  // const { addToRemovedItems, clearFromRemovedItems, addToConfiguredItems, clearFromConfiguredItems } = useStoreActions((state) => state.GettingStartedModel)

  // const removeItemHandler = (): void => {
  //   clearFromConfiguredItems(item)
  //   // setSortedList((currList) => currList.filter((listItem) => listItem.key !== item.key))
  //   addToRemovedItems(item)
  // }

  // const restoreItemHandler = (): void => {
  //   clearFromRemovedItems(item)
  //   addToConfiguredItems(item)
  //   // setSortedList((currRemovedItems) => currRemovedItems.concat([item]))
  // }

  return (
    <Paper elevation={0} sx={{ m: 1, backgroundColor: 'grey.100', cursor: 'move' /* , width: '100%', height: '100%'  */ }}>
      <Box sx={{ display: 'flex', justifyContent: isRemoved ? 'flex-end' : 'space-between', alignItems: 'center', borderBottom: '1px dotted lightgrey' }}>
        {!isRemoved ? (
          <>
            <Box sx={{ fontSize: '1rem', padding: '3px 8px' }}>
              <FontAwesomeIcon icon={['fal', 'arrows-up-down-left-right']} color='grey' />
            </Box>
            <Tooltip title='Remove from view' arrow>
              <IconButton
                aria-label='hide'
                sx={{ fontSize: '1rem', padding: '3px 8px', color: 'success.main' }}
                onClick={removeItemHandler !== undefined ? () => removeItemHandler(item.key) : undefined}
              >
                <FontAwesomeIcon icon={['far', 'eye']} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title='Add to view' arrow>
            <IconButton
              aria-label='show'
              sx={{ fontSize: '1rem', padding: '3px 8px', color: 'error.main' }}
              onClick={addItemHandler !== undefined ? () => addItemHandler(item.key) : undefined}
            >
              <FontAwesomeIcon icon={['far', 'eye-slash']} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='body1'>
          <FontAwesomeIcon icon={['far', item.icon === 'global' ? 'globe' : (item.icon as IconName)]} /> {item.title}
        </Typography>
      </Box>
    </Paper>
  )
}

export default LayoutSettings
