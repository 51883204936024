import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import sx from 'mui-sx'
import { animateEntranceSx } from '../../styles/common'

export const NoResults: React.FC<{ page: string }> = ({ page }) => (
  <Paper elevation={0} sx={sx({ border: '1px solid lightgrey', textAlign: 'center', p: 5, mt: 5, borderRadius: '9.6px' }, animateEntranceSx(0))}>
    <Box sx={{ width: '100%', textAlign: 'center', fontSize: '30px', mb: '16px' }}>
      <FontAwesomeIcon icon={['far', 'folder-open']} color='grey' />
    </Box>
    <Typography variant='h3'>{`No ${page} Found`}</Typography>
    <Typography variant='body1'>Please filter or reset your search criteria</Typography>
  </Paper>
)
