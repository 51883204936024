import { Action, action, Computed, computed, Thunk, thunk, ThunkOn, thunkOn } from 'easy-peasy'
import { components } from '../../types/api'
import { piDashController } from '../../api/controller/PiDashController'
import { ITEMS_PER_PAGE } from 'store/constants'
import memoize from 'fast-memoize'

interface State {
  fetchedAwardsList: components['schemas']['AwardListResponse']
  allAwardsLists: Array<components['schemas']['AwardListResponse']>
  awardsListTotalPages: number
  awardsListTotalItems: number
  awardsListCurrentPage: number
  awardsSummary: Array<components['schemas']['SummaryResponse']>
  listFilters: components['schemas']['AwardResponse']
  fetchError: boolean
  showSkeleton: boolean
  loadingMore: boolean
  loadingFinancials: boolean
  filtersChanged: boolean
  pageToFetch: number
  awardFinancials: Array<components['schemas']['AwardSummaryFinancialResponse']>
  currentFinancial: Computed<this, (accNr: string) => components['schemas']['AwardSummaryFinancialResponse'] | undefined>
  accountNumbersToFetch: Set<string>
  // awardFinancials: components['schemas']['AwardFinancialResponse'] | undefined
  indexOfFinancialToFetch: number
  // allAwardsFinancials: Array<components['schemas']['AwardFinancialResponse']>
  reFetchAwards: Computed<this, boolean>
  rolledUp: boolean
}
interface Actions {
  setFetchedAwardsList: Action<this, components['schemas']['AwardListResponse']>
  setAllAwardsLists: Action<this, Array<components['schemas']['AwardListResponse']>>
  pushAllAwardsLists: Action<this, components['schemas']['AwardListResponse']>
  setAwardsListTotalPages: Action<this, number>
  setAwardsListTotalItems: Action<this, number>
  setAwardsListCurrentPage: Action<this, number>
  setAwardsSummary: Action<this, Array<components['schemas']['SummaryResponse']>>
  setListFilters: Action<this, {}>
  clearListFilters: Action<this>
  setFetchError: Action<this, boolean>
  setShowSkeleton: Action<this, boolean>
  setLoadingMore: Action<this, boolean>
  setLoadingFinancials: Action<this, boolean>
  setFiltersChanged: Action<this, boolean>
  setPageToFetch: Action<this, number>
  incrementPageToFetch: Action<this>
  setAwardFinancials: Action<this, Array<components['schemas']['AwardSummaryFinancialResponse']>>
  setAccountNumbersToFetch: Action<this, Set<string>>
  // setAwardFinancials: Action<this, components['schemas']['AwardFinancialResponse']>
  // pushAllAwardsFinancials: Action<this, components['schemas']['AwardFinancialResponse']>
  pushAllAwardsFinancials: Action<this, Array<components['schemas']['AwardSummaryFinancialResponse']>>
  // clearAllAwardsFinancials: Action<this>
  incrIndexOfFinancialToFetch: Action<this>
  clearIndexOfFinancialToFetch: Action<this>
  setRolledUp: Action<this, boolean>
}
// eslint-disable-next-line
interface Thunks {
  fetchInitialPage: Thunk<this, { listFilters: components['schemas']['AwardResponse'], adminRolesCheck: boolean, rolledUp: boolean }>
  fetchMorePages: Thunk<this, { pageToFetch: number, listFilters: components['schemas']['AwardResponse'], adminRolesCheck: boolean, rolledUp: boolean }>
  // fetchAwardFinancials: Thunk<this, string>
  fetchAwardSummary: Thunk<this>
}

interface ThunkOns {
  onFetchAwards: ThunkOn<this>
}

export interface IAwardsModel extends State, Actions, Thunks, ThunkOns {}

const initialListFilters = {
  accountNumber: '',
  awardNumber: '',
  title: '',
  awardStatusCode: '1',
  awardStatusDescription: '',
  sponsorCode: '',
  sponsorName: '',
  primeSponsorCode: '',
  primeSponsorName: '',
  principalInvestigatorCode: '',
  principalInvestigatorName: '',
  leadUnitId: '',
  leadUnitName: '',
  effectiveDate: '',
  expirationDate: '',
  oblicationExpirationDate: ''
}

export const AwardsModel: IAwardsModel = {
  fetchedAwardsList: {},
  allAwardsLists: [],
  awardsListTotalPages: 0,
  awardsListTotalItems: 0,
  awardsListCurrentPage: 0,
  awardsSummary: [],
  fetchError: false,
  showSkeleton: false,
  loadingMore: false,
  loadingFinancials: false,
  filtersChanged: false,
  pageToFetch: 1,

  listFilters: initialListFilters,

  rolledUp: false,

  awardFinancials: [],
  currentFinancial: computed((state) => {
    return memoize((accNr: string) => state.awardFinancials.find((fin) => fin.accountNumber === accNr))
  }),
  accountNumbersToFetch: new Set(),
  // allAwardsFinancials: [],
  indexOfFinancialToFetch: 0,
  reFetchAwards: computed(
    (state) => (state.allAwardsLists.length === 0 && !state.showSkeleton) || (state.allAwardsLists.length > 0 && state.pageToFetch === 1 && state.filtersChanged)
  ),

  // Actions
  setFetchedAwardsList: action((state, payload) => {
    state.fetchedAwardsList = payload
  }),

  setAllAwardsLists: action((state, payload) => {
    state.allAwardsLists = payload
  }),

  pushAllAwardsLists: action((state, payload) => {
    state.allAwardsLists.push(payload)
  }),

  setAwardsListCurrentPage: action((state, payload) => {
    state.awardsListCurrentPage = payload
  }),

  setAwardsListTotalPages: action((state, payload) => {
    state.awardsListTotalPages = payload
  }),

  setAwardsListTotalItems: action((state, payload) => {
    state.awardsListTotalItems = payload
  }),

  setAwardsSummary: action((state, payload) => {
    state.awardsSummary = payload
  }),

  setListFilters: action((state, payload) => {
    state.listFilters = { ...state.listFilters, ...payload }
  }),

  clearListFilters: action((state) => {
    state.listFilters = { ...initialListFilters }
  }),

  setFetchError: action((state, payload) => {
    state.fetchError = payload
  }),

  setShowSkeleton: action((state, payload) => {
    state.showSkeleton = payload
  }),

  setLoadingMore: action((state, payload) => {
    state.loadingMore = payload
  }),

  setLoadingFinancials: action((state, payload) => {
    state.loadingFinancials = payload
  }),

  setFiltersChanged: action((state, payload) => {
    state.filtersChanged = payload
  }),

  setPageToFetch: action((state, payload) => {
    state.pageToFetch = payload
  }),

  incrementPageToFetch: action((state) => {
    state.pageToFetch += 1
  }),

  setAwardFinancials: action((state, payload) => {
    state.awardFinancials = payload
  }),

  setAccountNumbersToFetch: action((state, payload) => {
    state.accountNumbersToFetch = payload
  }),

  pushAllAwardsFinancials: action((state, payload) => {
    state.awardFinancials = state.awardFinancials.concat(payload)
  }),
  // pushAllAwardsFinancials: action((state, payload) => {
  //   state.allAwardsFinancials.push(payload)
  // }),

  // clearAllAwardsFinancials: action((state) => {
  //   state.allAwardsFinancials = []
  // }),

  incrIndexOfFinancialToFetch: action((state) => {
    state.indexOfFinancialToFetch += 1
  }),

  clearIndexOfFinancialToFetch: action((state) => {
    state.indexOfFinancialToFetch = 0
  }),

  setRolledUp: action((state, payload) => {
    state.rolledUp = payload
  }),

  // Thunks
  fetchInitialPage: thunk(async (actions, payload, { getState }) => {
    const { listFilters, adminRolesCheck, rolledUp } = payload
    const pageToFetch = 1
    const state = getState()
    if (!state.showSkeleton) {
      actions.setShowSkeleton(true)
    }
    if (state.fetchError) {
      actions.setFetchError(false)
    }
    // if (getState().indexOfFinancialToFetch !== 0) {
    //   actions.clearIndexOfFinancialToFetch()
    // }
    try {
      let data: components['schemas']['AwardListResponse'] = {}
      if (rolledUp) {
        data = await piDashController.getRolledUpAwards(`${ITEMS_PER_PAGE}`, `${pageToFetch}`, listFilters)
      } else {
        data = await piDashController.getAwards(`${ITEMS_PER_PAGE}`, `${pageToFetch}`, listFilters, adminRolesCheck)
      }
      actions.setAwardsListTotalPages(data.totalPages ?? 0)
      actions.setAwardsListTotalItems(data.totalItems ?? 0)
      actions.setAllAwardsLists([data])
      actions.setAwardsListCurrentPage(1)
      const accountNumbers = data.results?.map((awardItem) => awardItem.accountNumber)
      if (accountNumbers !== undefined) {
        actions.setAccountNumbersToFetch(new Set([...accountNumbers]))
        // actions.setAccountNumbersToFetch([...Array.from(new Set(accountNumbers))])
      }
      if (state.fetchError) {
        actions.setFetchError(false)
      }
    } catch (err: any) {
      console.log(err)
      if (err.name !== 'AbortError') {
        actions.setFetchError(true)
        return await Promise.reject(err)
      }
    } finally {
      actions.setShowSkeleton(false)
      actions.setFiltersChanged(false)
    }
  }),

  fetchMorePages: thunk(async (actions, payload, { getState }) => {
    const { pageToFetch, listFilters, adminRolesCheck, rolledUp } = payload
    const state = getState()
    if (!state.loadingMore) {
      actions.setLoadingMore(true)
    }
    if (state.fetchError) {
      actions.setFetchError(false)
    }
    try {
      actions.setFetchError(false)

      let data: components['schemas']['AwardListResponse'] = {}
      if (rolledUp) {
        data = await piDashController.getRolledUpAwards(`${ITEMS_PER_PAGE}`, `${pageToFetch}`, listFilters)
      } else {
        data = await piDashController.getAwards(`${ITEMS_PER_PAGE}`, `${pageToFetch}`, listFilters, adminRolesCheck)
      }
      actions.setAwardsListCurrentPage(payload.pageToFetch)
      if ((data.totalPages ?? 0) !== getState().awardsListTotalPages) {
        actions.setAwardsListTotalPages(data.totalPages ?? 0)
      }
      if ((data.totalItems ?? 0) !== getState().awardsListTotalItems) {
        actions.setAwardsListTotalItems(data.totalItems ?? 0)
      }
      actions.pushAllAwardsLists(data)
      const accountNumbers = data.results?.map((awardItem) => awardItem.accountNumber)
      if (accountNumbers !== undefined) {
        actions.setAccountNumbersToFetch(new Set([...accountNumbers]))
        // actions.setAccountNumbersToFetch([...Array.from(new Set(accountNumbers))])
      }
      // if (state.fetchError) {
      //   actions.setFetchError(false)
      // }
    } catch (err: any) {
      console.log(err)
      if (err.name !== 'AbortError') {
        actions.setFetchError(true)
        return await Promise.reject(err)
      }
    } finally {
      actions.setLoadingMore(false)
    }
  }),

  fetchAwardSummary: thunk(async (actions) => {
    try {
      const awardSummary = await piDashController.getAwardsSummary()
      actions.setAwardsSummary(awardSummary)
    } catch (error) {
      console.log(error)
    }
  }),

  // Listeners
  onFetchAwards: thunkOn(
    (actions) => actions.setAccountNumbersToFetch,
    async (actions, target) => {
      actions.setLoadingFinancials(true)
      try {
        const fetchKey = Array.from(target.payload).join('')
        const data = await piDashController.getFinancialsList(target.payload, fetchKey)
        // actions.setAwardFinancials(data)
        actions.pushAllAwardsFinancials(data)
      } catch (error: any) {
        console.log(error.name) // Todo error handling
        if (error.name !== 'AbortError') {
          actions.setFetchError(true)
          return await Promise.reject(error)
        }
      } finally {
        actions.setLoadingFinancials(false)
      }
    }
  )
}
