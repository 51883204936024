import React /* , { useState } */ from 'react'
import { Paper, Chip, Box, Stack, Typography } from '@mui/material'
import { CardTitle, SmallTitle } from '../UIComponents'
import { getChipColor } from '../../common/getChipColor'
import { format, isAfter } from 'date-fns'
// import ActionItemDetailsDialog from '../ActionItem/ActionItemDetailsDialog'
import { animateEntranceSx, cardSx } from 'components/styles'
import sx from 'mui-sx'
// import ActionItemDetailsDialog from './ActionItemDetailsDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { components } from 'types/api_task'
import { formatDate } from 'common'

interface ActionItemCardCompProps {
  // item: components['schemas']['ActionResponse']
  item: components['schemas']['TaskResponse']
  index: number
}

const ActionItemCardComp: React.FC<ActionItemCardCompProps> = ({ item, index }) => {
  // const [open, setOpen] = useState<boolean>(false)
  const todayDateString = format(new Date(), 'yyyy-MM-dd')
  const trimDate = (dateString: string): string => dateString.slice(0, 10)
  const getDateColor = (dueDate?: string): string => {
    if (dueDate !== undefined) {
      if (isAfter(new Date(trimDate(todayDateString)), new Date(trimDate(dueDate)))) return 'error.main'
    }
    return 'rgba(0,0,0,0.76)'
  }

  return (
    <>
      <Paper component='button' elevation={0} sx={sx(cardSx, animateEntranceSx(index / 10))}>
        <CardTitle title={item.title} />
        <Box sx={{ width: '100%', flex: '0 0 auto' }}>
          <Box sx={{ display: 'flex', flexFlow: 'row nowarp', justifyContent: 'space-between', alignItems: 'flex-end', height: '100%' }}>
            {item.urgency === 'LOW' ? (
              <Box />
            ) : (
              <Chip label={<FontAwesomeIcon icon={['fas', 'exclamation']} />} sx={{ height: '24px', borderRadius: '8px', fontWeight: '400' }} color={getChipColor(item.urgency)} />
            )}
            <Stack spacing={0} alignItems='flex-end'>
              <SmallTitle title='Due Date' />
              <Typography variant='body2' sx={{ fontWeight: '600', color: getDateColor(item.dueDate) }}>
                {item.dueDate !== undefined ? formatDate(item.dueDate) : ''}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

export default ActionItemCardComp
