import React from 'react'
import Box from '@mui/material/Box'
import { Chart, PieSeries, Legend } from '@devexpress/dx-react-chart-material-ui'
import { Palette, Animation } from '@devexpress/dx-react-chart'
// import { bounceIn } from '../Common/TransitionComponents'

interface DougnutChartProps {
  data: Array<{ value: string, area: number }>
  colors: string[]
  size?: number
  innerRadius: number
  legend?: boolean
  height?: number
  animate?: boolean
}

export const DougnutChart: React.FC<DougnutChartProps> = ({ data, colors, size, height, innerRadius, legend = false, animate = false }) => {
  // const scheme = [color, '#0000001A']
  const scheme = colors

  // const data = [
  //   { value: 'Total Amount Spent', area: 17088.19 },
  //   { value: 'Available Balance', area: 2911.81 },
  // ];

  return (
    <Box>
      <Chart width={size} height={height ?? size} data={data}>
        <Palette scheme={scheme} />
        <PieSeries valueField='area' argumentField='value' innerRadius={innerRadius} outerRadius={1} />
        {animate && <Animation duration={2000} />}
        {legend && <Legend position='bottom' />}
      </Chart>
    </Box>
  )
}
