import React from 'react'
import { Grid, Skeleton } from '@mui/material'

export const CardsSkeleton: React.FC = () => {
  return (
    <>
      {[...Array(3)].map((_, i) => (
        <Grid item xs={12} md={6} lg={4} xl={3} key={`AISC-${i}`}>
          <Skeleton variant='rectangular' sx={{ width: '100%', height: '199px', borderRadius: '8px' }} />
        </Grid>
      ))}
    </>
  )
}
