import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, List, ListItem, ListItemButton, Typography } from '@mui/material'
import { animateEntranceSx } from 'components/styles'
import sx from 'mui-sx'
import numeral from 'numeral'
import React, { useLayoutEffect, useState } from 'react'
import { components } from 'types'

interface NextFinancialBlockProps {
  color: string
  item?: components['schemas']['FinancialRowResponse']
  border?: string
  setNextFinancialBlock: React.Dispatch<
  React.SetStateAction<
  | {
    hierarchyLevel: string
    type: string
    description: string
    amount?: number | undefined
    rows?: Array<components['schemas']['FinancialRowResponse']> | undefined
  }
  | undefined
  >
  >
}

export const NextFinancialBlock: React.FC<NextFinancialBlockProps> = ({ color, item, border, setNextFinancialBlock }) => {
  const [animateIn, setAnimateIn] = useState(true)

  useLayoutEffect(() => {
    setAnimateIn(true)
    setTimeout(() => {
      setAnimateIn(false)
    }, 1800)
  }, [item])

  return (
    <>
      <Box
        sx={sx(
          { backgroundColor: 'white', borderRadius: '16px', width: '100%', my: '24px', p: 3, boxSizing: 'border-box' },
          { border: border ?? undefined },
          { condition: animateIn, sx: animateEntranceSx(0) }
        )}
      >
        <Typography variant='h4' sx={{ fontWeight: '600' }} color={color}>
          {item?.description ?? ''}
        </Typography>
        <List>
          {item?.rows?.map((item, index) => {
            const canDrillDown = item.rows !== undefined && item.rows.length > 0
            return (
              <React.Fragment key={`LI-${index}-${item.description}`}>
                <ListItem divider sx={{ pr: 0 }}>
                  <ListItemButton disabled={!canDrillDown} onClick={() => setNextFinancialBlock(item)}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography variant='body1'>{item.description}</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                        <Typography variant='body1'>{numeral(item.amount ?? 0).format('($ 0,0)')}</Typography>
                        {/* <Typography variant='body1'>{formatCurrency.format(item.amount ?? 0)}</Typography> */}
                        {canDrillDown && (
                          // <IconButton
                          //   sx={{ pr: 0, py: 0 }}
                          //   onClick={() => {
                          //     setNextFinancialBlock(item)
                          //     // incrNrOfOpenDrawers()
                          //   }}
                          // >
                          <ChevronRightIcon />
                          // </IconButton>
                        )}
                      </Box>
                    </Box>
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            )
          })}
        </List>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'start' }}>
          <Typography variant='body1' sx={{ mr: 1.5 }}>
            Total
          </Typography>
          <Typography variant='h2'>{numeral(item?.amount ?? 0).format('($ 0,0)')}</Typography>
          {/* <Typography variant='h2'>{formatCurrency.format(item?.amount ?? 0)}</Typography> */}
        </Box>
      </Box>
    </>
  )
}
