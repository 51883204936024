import { Box, Grid } from '@mui/material'
import { GenericError, ListFilterInputBox, NoResults, PiListSkeleton } from 'components/UIComponents'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useStoreActions, useStoreState } from 'store/hooks'
import { PiSingleItem } from '../UIComponents/CardUIComponents/PiSingleItem'
import { PiTypeFilterDropDown } from './PiTypeFilterDropDown'
import { PiViewFilterDropDown } from './PiViewFilterDropDown'
import debounce from 'lodash.debounce'

export const PiList: React.FC = () => {
  const {
    currentDepartment,
    fetchedPiList,
    piListFetchError,
    piListShowSkeleton,
    allPiList,
    piListQueryParams: piListFilters,
    piListFiltersChanged,
    piListPageToFetch,
    piListSearch,
    isLeafNodeDepartment
  } = useStoreState((store) => store.HigherLevelProposalsModel)
  const { fetchPiList, setFetchedPiList, setPiListFiltersChanged, incrPiListPageToFetch, setPiListSearch } = useStoreActions((store) => store.HigherLevelProposalsModel)

  const { ref, inView /* , entry */ } = useInView({
    /* Optional options */
    triggerOnce: true,
    initialInView: false,
    threshold: 0
  })

  const { departmentCode, currentPage } = fetchedPiList
  const { code } = currentDepartment
  const { rollup, onlyInternal } = piListFilters
  const departmentChanged = code !== departmentCode

  useEffect(() => {
    if (code !== undefined) {
      if (piListPageToFetch > 1 && piListPageToFetch > (currentPage ?? piListPageToFetch)) {
        fetchPiList({ departmentId: code, page: piListPageToFetch, rollup: rollup, onlyInternal: onlyInternal, principalInvestigatorName: piListSearch })
      }
      if (departmentChanged || piListFiltersChanged) {
        if (piListPageToFetch === 1 && !piListShowSkeleton /* -> current fetch in progress */) {
          fetchPiList({ departmentId: code, page: 1, rollup: rollup, onlyInternal: onlyInternal, principalInvestigatorName: piListSearch })
          setPiListFiltersChanged(false)
        }
      }
    } else {
      setFetchedPiList({})
    }
  }, [
    fetchPiList,
    setFetchedPiList,
    piListPageToFetch,
    rollup,
    code,
    departmentChanged,
    setPiListFiltersChanged,
    piListFiltersChanged,
    currentPage,
    onlyInternal,
    piListSearch,
    piListShowSkeleton
  ])

  useEffect(() => {
    if (inView) {
      incrPiListPageToFetch()
    }
  }, [inView, incrPiListPageToFetch])

  useEffect(() => {
    return () => setPiListSearch('')
  }, [setPiListSearch])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setPiListSearch(e.target.value)
  }

  const debounceHandleSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      handleSearch(e)
    },
    800,
    { leading: false }
  )

  return (
    <>
      <Box sx={{ mt: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <ListFilterInputBox
          label="Search PI's"
          changeHandler={(e) => {
            debounceHandleSearch(e)
          }}
        />
        <Box>
          <PiViewFilterDropDown />
          {!isLeafNodeDepartment && <PiTypeFilterDropDown />}
        </Box>
      </Box>
      <Grid container mt={2} direction='column' spacing={1}>
        {piListShowSkeleton ? (
          <PiListSkeleton />
        ) : allPiList.length === 0 ? (
          <NoResults page="PI's" />
        ) : (
          <>
            {allPiList.map((pi, i) => (
              <Grid item xs={12} key={pi.code} ref={i === allPiList.length - 1 && allPiList.length < (fetchedPiList.totalItems ?? 0) ? ref : undefined}>
                <PiSingleItem pi={pi} i={i < 10 ? i : i - (piListPageToFetch - 1) * 10} sectionToSet='Proposal' />
              </Grid>
            ))}
            {/* {(loadingMorePis || allPiList.length === 0) && <PiListSkeleton />} */}
            {(fetchedPiList?.currentPage ?? 0) < (fetchedPiList?.totalPages ?? 1) && <PiListSkeleton />}
          </>
        )}
        {piListFetchError && <GenericError page="PI's" />}
      </Grid>
    </>
  )
}
