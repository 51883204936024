import React from 'react'
import { Typography } from '@mui/material'

interface SmallTitleProps {
  title: string
}

export const SmallTitle: React.FC<SmallTitleProps> = ({ title }) => {
  return <Typography variant='subtitle2' sx={{ whiteSpace: 'nowrap' }}>{title}</Typography>
}
