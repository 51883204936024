import { Stack, Typography } from '@mui/material'
import React from 'react'
import { SmallTitle/* , AvatarWithName */ } from '../'

interface AvatarWithTitleStackProps {
  title: string
  imgSrc?: string
  name?: string
  bold?: boolean
  alignItems?: string
}

export const AvatarWithTitleStack: React.FC<AvatarWithTitleStackProps> = ({ title, imgSrc, name, bold = false, alignItems = 'flex-start' }) => {
  return (
    <Stack sx={{ mr: 2 }} spacing={0} alignItems={alignItems}>
      <SmallTitle title={title} />
      {/* <AvatarWithName name={name} imgSrc={imgSrc ?? undefined} /> */}
      <Typography variant='body1' sx={{ fontWeight: bold ? 'bold' : 'normal' }}>{name}</Typography>
    </Stack>
  )
}

export default AvatarWithTitleStack
