import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Box, Button, Typography } from '@mui/material'
import { treeSearch } from 'common'
import { PiList } from 'components/HighLevelProposals/PiList'
import { ProposalsCards } from 'components/HighLevelProposals/ProposalsCards'
// import ProposalUnitsBreadcrumbs from 'components/HighLevelProposals/ProposalUnitsBreadcrumbs'
import { ToggleSwitch } from 'components/HighLevelProposals/ToggleSwitch'
import { UnitsCards } from 'components/HighLevelProposals/UnitsCards'
import { ListFilterInputBox } from 'components/UIComponents'
import { UndergoingMaintenance } from 'components/UIComponents/Common/UndergoingMaintenance'
// import { components } from 'types'
import debounce from 'lodash.debounce'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { searchBoxRef } from 'store'
import { useStoreActions, useStoreState } from 'store/hooks'
import { useUpdateEffect } from 'usehooks-ts'

export const HighLevelProposalsPage: React.FC = () => {
  const { appName } = useStoreState((state) => state.AppConfigModel)
  const {
    section,
    currentDepartment,
    breadcrumbItems,
    allDepartmentsList,
    // hideToggle,
    hidePiToggle,
    unitSearchString: searchString,
    unitSearchResults: searchResults
  } = useStoreState((store) => store.HigherLevelProposalsModel)
  const { setCurrentDepartment, setCurrentDepartmentsLists, setSection, clearBreadcrumbItems, setSearchString, setSearchResults, clearSearchResults, clearSearchString } =
    useStoreActions((store) => store.HigherLevelProposalsModel)

  const renderSection = (): JSX.Element => {
    switch (section) {
      case 'unitsList':
        return <UnitsCards />
      case 'piList':
        return <PiList />
      case 'piItems':
      case 'deptItems':
        return <ProposalsCards />
      default:
        return <UndergoingMaintenance />
    }
  }

  const handleBackClick = (): void => {
    if (searchString !== '') {
      clearSearchString()
      if (searchBoxRef.current != null) {
        searchBoxRef.current.value = ''
      }
    }
    let handler: (() => void) | undefined
    if (breadcrumbItems.length > 1) {
      if (section === 'piItems' || searchResults.length > 0) {
        handler = breadcrumbItems[breadcrumbItems.length - 1].clickHandler
      } else {
        handler = breadcrumbItems[breadcrumbItems.length - 2].clickHandler
      }
      if (handler !== undefined) {
        handler()
      }
    } else {
      setCurrentDepartment({})
      setCurrentDepartmentsLists(allDepartmentsList)
      clearBreadcrumbItems()
    }
    if (section === 'piList' || section === 'deptItems') {
      setSection('unitsList')
    } else if (section === 'piItems') {
      setSection('piList')
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchString(e.target.value)
  }

  const debounceHandleSearch = debounce((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    handleSearch(e)
  }, 800)

  useEffect(() => {
    if (searchString !== '') {
      setSearchResults(treeSearch(allDepartmentsList, searchString))
    }
  }, [allDepartmentsList, searchString, setSearchResults])

  useUpdateEffect(() => {
    if (searchString === '') {
      clearSearchResults()
    }
  }, [searchString, allDepartmentsList, clearSearchResults])

  return (
    <>
      <Helmet>
        <title>DEPARTMENT PROPOSALS</title>
        <meta name='description' content={` ${appName} DEPARTMENT PROPOSALS PAGE DESCRIPTION`} />
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <Typography variant='h3' sx={{ color: 'common.AtlasBrand.brand_2.main' }}>
          Department Proposals
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {section === 'unitsList' && (
            <ListFilterInputBox
              label='Search units'
              defaultValue={searchString}
              changeHandler={(e) => {
                if (e.target.value === '') {
                  handleSearch(e)
                } else {
                  debounceHandleSearch(e)
                }
              }}
            />
          )}
        </Box>
        <Box sx={{ mt: 2, position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {currentDepartment.name !== undefined && (
            <Box>
              <Box>
                <Button sx={{ ml: -2 }} variant='text' size='small' onClick={handleBackClick}>
                  <ArrowBackIosNewIcon />{' '}
                  {section === 'piItems'
                    ? 'Back to PI List'
                    : searchResults.length > 0
                      ? breadcrumbItems[breadcrumbItems.length - 1]?.label ?? 'Back'
                      : breadcrumbItems[breadcrumbItems.length - 2]?.label ?? 'Back'}
                </Button>
              </Box>
              <Typography variant='h5' sx={{ fontWeight: '600', mb: 0, mx: 'auto' }}>
                {searchResults.length > 0 ? 'Search Results' : currentDepartment.name}
              </Typography>
            </Box>
          )}
          {console.log('hidePiToggle', hidePiToggle)}
          {!hidePiToggle && (
            <Box sx={{ mr: 0, ml: 'auto' }}>
              <ToggleSwitch />
            </Box>
          )}
        </Box>
        {renderSection()}
      </Box>
    </>
  )
}
