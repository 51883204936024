import { appInitialized } from '@mit/aws-react'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const initializeApp = async (): Promise<void> => {
    // Simulate api call. Don't use timeout
    // You can access easy peasy state in this hook

    setTimeout(() => {
      appInitialized({
        isLoading: false,
        hasAccess: true,
        hasError: false
      })
    }, 3000)
  }

  return { initializeApp }
}
