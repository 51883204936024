import { piDashController } from 'api/controller'
import { Action, action, Thunk, thunk } from 'easy-peasy'
import { ITextsApi } from 'types'
import { components } from 'types/api'
import backUpTexts from 'mock-data/textApi.json'
interface State {
  auth: object
  statuses: components['schemas']['LookupStatusResponse'] | undefined
  agreementsSummary: Array<components['schemas']['SummaryResponse']>
  proposalsSummary: Array<components['schemas']['SummaryResponse']>
  awardsSummary: Array<components['schemas']['SummaryResponse']>
  protocolsSummary: Array<components['schemas']['SummaryResponse']>
  loadingSummaries: boolean
  texts: ITextsApi[]
}
interface Actions {
  setAuth: Action<this, object>
  setStatuses: Action<this, components['schemas']['LookupStatusResponse']>
  setAgreementsSummary: Action<this, Array<components['schemas']['SummaryResponse']>>
  setProposalsSummary: Action<this, Array<components['schemas']['SummaryResponse']>>
  setAwardsSummary: Action<this, Array<components['schemas']['SummaryResponse']>>
  setProtocolsSummary: Action<this, Array<components['schemas']['SummaryResponse']>>
  setLoadingSummaries: Action<this, boolean>
  setTexts: Action<this, ITextsApi[]>
}
// eslint-disable-next-line
interface Thunks {
  fetchStatuses: Thunk<this>
  fetchAllSummaries: Thunk<this>
  fetchTexts: Thunk<this>
}

export interface IGlobalModel extends State, Actions, Thunks {}

export const GlobalModel: IGlobalModel = {
  auth: {},
  statuses: undefined,
  agreementsSummary: [],
  proposalsSummary: [],
  awardsSummary: [],
  protocolsSummary: [],
  loadingSummaries: false,
  texts: backUpTexts,

  // Actions
  setAuth: action((state, payload) => {
    state.auth = payload
  }),

  setStatuses: action((state, payload) => {
    state.statuses = payload
  }),

  setAwardsSummary: action((state, payload) => {
    state.awardsSummary = payload
  }),

  setProposalsSummary: action((state, payload) => {
    state.proposalsSummary = payload
  }),

  setAgreementsSummary: action((state, payload) => {
    state.agreementsSummary = payload
  }),

  setProtocolsSummary: action((state, payload) => {
    state.protocolsSummary = payload
  }),

  setLoadingSummaries: action((state, payload) => {
    state.loadingSummaries = payload
  }),

  setTexts: action((state, payload) => {
    state.texts = payload
  }),

  // Thunks
  fetchStatuses: thunk(async (actions) => {
    try {
      const statusData = await piDashController.getLookupStatuses()
      actions.setStatuses(statusData)
    } catch (error) {
      console.log(error)
    }
  }),

  fetchAllSummaries: thunk(async (actions) => {
    const { setAwardsSummary, setProposalsSummary, setAgreementsSummary, setProtocolsSummary, setLoadingSummaries } = actions
    const actionsArr = [setAwardsSummary, setProposalsSummary, setAgreementsSummary, setProtocolsSummary]
    setLoadingSummaries(true)
    Promise.all([piDashController.getAwardsSummary(), piDashController.getProposalsSummary(), piDashController.getAgreementsSummary(), piDashController.getProtocolsSummary()])
      .then((dataArr) => {
        dataArr.forEach((resp, i) => {
          actionsArr[i](resp)
        })
        setLoadingSummaries(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingSummaries(false)
      })
  }),

  fetchTexts: thunk(async (actions) => {
    try {
      const textsData = await piDashController.getTexts()
      actions.setTexts(textsData)
    } catch (error) {
      console.log(error)
    }
  })
}
