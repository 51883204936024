// Create our number formatter.
export const formatCurrency = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
})
export const formatCurrencyCompact = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',
  // notation: 'compact', // < -- Doesn't work in Safari
  // compactDisplay: 'long',

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2 // (causes 2500.99 to be printed as $2,501)
})
