import { Action, action, Computed, computed, Thunk, thunk } from 'easy-peasy'
import { components } from '../../types/api'
import { piDashController } from '../../api/controller/PiDashController'
import { ITEMS_PER_PAGE } from 'store/constants'

interface State {
  fetchedAgreementsList: components['schemas']['AgreementListResponse']
  allAgreementsLists: Array<components['schemas']['AgreementListResponse']>
  agreementsListTotalPages: number
  agreementsListTotalItems: number
  agreementsListCurrentPage: number
  agreementsSummary: Array<components['schemas']['SummaryResponse']>
  listFilters: components['schemas']['AgreementResponse']
  fetchError: boolean
  showSkeleton: boolean
  loadingMore: boolean
  filtersChanged: boolean
  pageToFetch: number
  reFetchAgreements: Computed<this, boolean>
}
interface Actions {
  setFetchedAgreementsList: Action<this, components['schemas']['AgreementListResponse']>
  setAllAgreementsLists: Action<this, Array<components['schemas']['AgreementListResponse']>>
  pushAllAgreementsLists: Action<this, components['schemas']['AgreementListResponse']>
  setAgreementsListTotalPages: Action<this, number>
  setAgreementsListTotalItems: Action<this, number>
  setAgreementsListCurrentPage: Action<this, number>
  setAgreementsSummary: Action<this, Array<components['schemas']['SummaryResponse']>>
  setListFilters: Action<this, {}>
  setFetchError: Action<this, boolean>
  setShowSkeleton: Action<this, boolean>
  setLoadingMore: Action<this, boolean>
  setFiltersChanged: Action<this, boolean>
  setPageToFetch: Action<this, number>
  incrementPageToFetch: Action<this>
}
// eslint-disable-next-line
interface Thunks {
  fetchInitialPage: Thunk<this, components['schemas']['AgreementResponse']>
  fetchMorePages: Thunk<this, { pageToFetch: number, listFilters: components['schemas']['AgreementResponse'] }>
}

export interface IAgreementsModel extends State, Actions, Thunks {}

export const AgreementsModel: IAgreementsModel = {
  fetchedAgreementsList: {},
  allAgreementsLists: [],
  agreementsListTotalPages: 0,
  agreementsListTotalItems: 0,
  agreementsListCurrentPage: 0,
  agreementsSummary: [{}],
  fetchError: false,
  showSkeleton: false,
  loadingMore: false,
  filtersChanged: false,
  pageToFetch: 1,
  reFetchAgreements: computed((state) => state.allAgreementsLists.length === 0 || (state.allAgreementsLists.length > 0 && state.pageToFetch === 1 && state.filtersChanged)),

  listFilters: {
    title: '',
    agreementStatusCode: '',
    agreementStatusDescription: '',
    agreementId: '',
    agreementTypeCode: '',
    agreementTypeDescription: '',
    organization: '',
    principalInvestigatorCode: '',
    principalInvestigatorName: '',
    requestorCode: '',
    requestorName: '',
    leadUnitId: '',
    leadUnitName: '',
    startDate: '',
    endDate: ''
  },

  // Actions
  setFetchedAgreementsList: action((state, payload) => {
    state.fetchedAgreementsList = payload
  }),

  setAllAgreementsLists: action((state, payload) => {
    state.allAgreementsLists = payload
  }),

  pushAllAgreementsLists: action((state, payload) => {
    state.allAgreementsLists.push(payload)
  }),

  setAgreementsListCurrentPage: action((state, payload) => {
    state.agreementsListCurrentPage = payload
  }),

  setAgreementsListTotalPages: action((state, payload) => {
    state.agreementsListTotalPages = payload
  }),

  setAgreementsListTotalItems: action((state, payload) => {
    state.agreementsListTotalItems = payload
  }),

  setAgreementsSummary: action((state, payload) => {
    state.agreementsSummary = payload
  }),

  setListFilters: action((state, payload) => {
    state.listFilters = { ...state.listFilters, ...payload }
  }),

  setFetchError: action((state, payload) => {
    state.fetchError = payload
  }),

  setShowSkeleton: action((state, payload) => {
    state.showSkeleton = payload
  }),

  setLoadingMore: action((state, payload) => {
    state.loadingMore = payload
  }),

  setFiltersChanged: action((state, payload) => {
    state.filtersChanged = payload
  }),

  setPageToFetch: action((state, payload) => {
    state.pageToFetch = payload
  }),

  incrementPageToFetch: action((state) => {
    state.pageToFetch += 1
  }),

  // Thunks
  fetchInitialPage: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    if (!state.showSkeleton) {
      actions.setShowSkeleton(true)
    }
    if (state.fetchError) {
      actions.setFetchError(false)
    }
    try {
      const data = await piDashController.getAgreements(`${ITEMS_PER_PAGE}`, '1', payload)
      actions.setAgreementsListTotalPages(data.totalPages ?? 0)
      actions.setAgreementsListTotalItems(data.totalItems ?? 0)
      actions.setAllAgreementsLists([data])
      actions.setAgreementsListCurrentPage(1)
      if (state.fetchError) {
        actions.setFetchError(false)
      }
    } catch (err: any) {
      console.log(err)
      if (err.name !== 'AbortError') {
        actions.setFetchError(true)
        return await Promise.reject(err)
      }
    } finally {
      actions.setShowSkeleton(false)
      actions.setFiltersChanged(false)
    }
  }),

  fetchMorePages: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    if (state.loadingMore) {
      actions.setLoadingMore(true)
    }
    if (state.fetchError) {
      actions.setFetchError(false)
    }
    try {
      const data = await piDashController.getAgreements(`${ITEMS_PER_PAGE}`, `${payload.pageToFetch}`, payload.listFilters)
      actions.setAgreementsListCurrentPage(payload.pageToFetch)
      if ((data.totalPages ?? 0) !== getState().agreementsListTotalPages) {
        actions.setAgreementsListTotalPages(data.totalPages ?? 0)
      }
      if ((data.totalItems ?? 0) !== getState().agreementsListTotalItems) {
        actions.setAgreementsListTotalItems(data.totalItems ?? 0)
      }
      actions.pushAllAgreementsLists(data)
      if (state.fetchError) {
        actions.setFetchError(false)
      }
    } catch (err: any) {
      console.log(err)
      if (err.name !== 'AbortError') {
        actions.setFetchError(true)
        return await Promise.reject(err)
      }
    } finally {
      actions.setLoadingMore(false)
    }
  })
}
