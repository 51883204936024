import { SxProps } from '@mui/material'
import { fadeUp, fadeShadow } from 'components/UIComponents'

export const cardSx: SxProps = {
  position: 'relative',
  p: 2,
  backgroundColor: '#white',
  width: '100%',
  // width: '338px',
  height: '199px',
  borderRadius: '8px',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'space-between',
  outline: 'none',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  textAlign: 'left',
  boxSizing: 'border-box',
  '&:hover, &:focus': {
    boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.1)'
  },
  transition: 'all 0.2s ease-out',
  cursor: 'pointer'
}

export const summaryCardSx: SxProps = {
  boxSizing: 'border-box',
  width: '360px',
  '@media screen and (max-width: 375px)': {
    width: '332px'
  },
  flex: '0 1 auto',
  // m: 1,
  p: 2,
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.1)'
}

export const animateEntranceSx = (delay: number): SxProps => {
  return {
    animation: `${fadeUp} 0.7s ease-out both`,
    animationDelay: `${delay}s`,
    '&::after': {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      display: 'block',
      content: "''",
      boxShadow: '8px 6px 32px 0px rgba(0,0,0,0.75)',
      animation: `${fadeShadow} 1.8s ease-out both`,
      animationDelay: `${delay}s`,
      borderRadius: '8px',
      pointerEvents: 'none'
    }
  }
}

export const gettingStartedCardsSx: SxProps = {
  border: '1px solid lightgrey',
  // padding: '20px',
  p: 3.5,
  borderRadius: '8px',
  fontSize: '0.8em'
}

export const lineClamp = (lines: number): SxProps => {
  return {
    width: 'calc(100%)',
    overflow: 'hidden',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    wordBreak: 'normal'
  }
}

export const overflowEllipsis: SxProps = {
  p: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

export const cardInfoBlockSx: SxProps = {
  p: 2,
  backgroundColor: '#e8eff4',
  width: '235px',
  height: '155px',
  borderRadius: '16px',
  display: 'flex',
  flexFlow: 'column nowrap',
  // justifyContent: 'space-between',
  justifyContent: 'flex-end',
  outline: 'none',
  border: 'none',
  textAlign: 'left',
  m: 1
}
