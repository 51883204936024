import { Box, /*  Chip, */ FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React /* , { useEffect } */ from 'react'
import { useStoreState, useStoreActions } from 'store/hooks'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
interface FilterDropDownProps {
  filterFor: string
}

export const FilterDropDown: React.FC<FilterDropDownProps> = ({ filterFor }) => {
  // const { fetchStatuses } = useStoreActions((store) => store.GlobalModel)
  const { statuses /* , awardsSummary, proposalsSummary, agreementsSummary, protocolsSummary */ } = useStoreState((store) => store.GlobalModel)
  const { listFilters: awardsFilters } = useStoreState((store) => store.AwardsModel)
  const { listFilters: proposalsFilters } = useStoreState((store) => store.ProposalsModel)
  const { listFilters: agreementsFilters } = useStoreState((store) => store.AgreementsModel)
  const { listFilters: protocolsFilters } = useStoreState((store) => store.ProtocolsModel)
  const { setFiltersChanged: setAwardsFiltersChanged, setListFilters: setAwardsFilters, setPageToFetch: setPageToFetchAwards } = useStoreActions((store) => store.AwardsModel)
  const {
    setFiltersChanged: setProposalsFiltersChanged,
    setListFilters: setProposalsFilters,
    setPageToFetch: setPageToFetchProposals
  } = useStoreActions((store) => store.ProposalsModel)
  const {
    setFiltersChanged: setAgreementsFiltersChanged,
    setListFilters: setAgreementsFilters,
    setPageToFetch: setPageToFetchAgreements
  } = useStoreActions((store) => store.AgreementsModel)
  const {
    setFiltersChanged: setProtocolsFiltersChanged,
    setListFilters: setProtocolsFilters,
    setPageToFetch: setPageToFetchProtocols
  } = useStoreActions((store) => store.ProtocolsModel)

  // useEffect(() => {
  //   if (statuses === undefined) {
  //     fetchStatuses()
  //   }
  // }, [statuses, fetchStatuses])

  let forStatuses
  if (statuses !== undefined) {
    // forStatuses = statuses[filterFor.toLowerCase() as 'proposals' | 'awards']
    switch (filterFor) {
      case 'Awards':
        forStatuses = statuses.awards
        // forStatuses = awardsSummary
        break
      case 'Proposals':
        forStatuses = statuses.proposals
        // forStatuses = proposalsSummary
        break
      case 'Agreements':
        forStatuses = statuses.agreements
        // forStatuses = agreementsSummary
        break
      case 'Protocols':
      default:
        forStatuses = statuses.protocols
        // forStatuses = protocolsSummary
        break
    }
  }

  const getValue = (forFilter: string): string => {
    const allValue = `All ${forFilter}`
    switch (forFilter) {
      case 'Awards':
        return awardsFilters.awardStatusCode === '' ? allValue : awardsFilters.awardStatusCode
      case 'Proposals':
        return proposalsFilters.proposalStatusCode === '' ? allValue : proposalsFilters.proposalStatusCode
      case 'Agreements':
        return agreementsFilters.agreementStatusCode === '' ? allValue : agreementsFilters.agreementStatusCode
      case 'Protocols':
      default:
        return protocolsFilters.protocolStatusCode === '' ? allValue : protocolsFilters.protocolStatusCode
    }
  }

  const handleChange = (e: SelectChangeEvent): void => {
    const isAll = e.target.value === `All ${filterFor}`
    if (filterFor === 'Awards') {
      setAwardsFilters({ awardStatusCode: isAll ? '' : e.target.value })
      // setAwardsFilters({ awardStatusDescription: e.target.value })
      setAwardsFiltersChanged(true)
      setPageToFetchAwards(1)
    } else if (filterFor === 'Proposals') {
      setProposalsFilters({ proposalStatusCode: isAll ? '' : e.target.value })
      // setProposalsFilters({ proposalStatusDescription: e.target.value })
      setProposalsFiltersChanged(true)
      setPageToFetchProposals(1)
    } else if (filterFor === 'Agreements') {
      setAgreementsFilters({ agreementStatusCode: isAll ? '' : e.target.value })
      // setAgreementsFilters({ proposalStatusDescription: e.target.value })
      setAgreementsFiltersChanged(true)
      setPageToFetchAgreements(1)
    } else if (filterFor === 'Protocols') {
      setProtocolsFilters({ protocolStatusCode: isAll ? '' : e.target.value })
      // setProtocolsFilters({ proposalStatusDescription: e.target.value })
      setProtocolsFiltersChanged(true)
      setPageToFetchProtocols(1)
    }
  }

  return (
    <FormControl variant='filled' sx={{ ml: 'auto', mr: 0, minWidth: 150 }}>
      <InputLabel id='demo-simple-select-filled-label'>{filterFor}</InputLabel>
      <Select
        sx={{ backgroundColor: '#E1E6EC' }}
        labelId='demo-simple-select-filled-label'
        id='demo-simple-select-filled'
        value={getValue(filterFor)}
        size='small'
        onChange={handleChange}
        IconComponent={ExpandMoreIcon}
      >
        <MenuItem value={`All ${filterFor}`}>
          <Box sx={{ display: 'inline-block', fontWeight: 'bold' }}>{`All ${filterFor === 'Agreements' ? 'NDA / DUA' : filterFor}`}</Box>
        </MenuItem>
        {forStatuses !== undefined
          ? forStatuses.map((stat, i) => (
            <MenuItem sx={{ justifyContent: 'space-between' }} key={`${filterFor}-${stat.name ?? 'unnamed'}`} value={stat.code}>
              <Box sx={{ display: 'inline-block', fontWeight: 'bold' }}>{stat.name}</Box>
              {/* <Chip label={stat.count} sx={{ ml: 1, height: '24px', borderRadius: '8px' }} /> */}
            </MenuItem>
          ))
          : null}
      </Select>
    </FormControl>
  )
}
