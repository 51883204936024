import { Box, Typography } from '@mui/material'
import React from 'react'

interface CardTitleProps {
  title: string
}

export const CardTitle: React.FC<CardTitleProps> = ({ title }) => {
  return (
    <Box sx={{ overflow: 'hidden', flex: '0 1 auto' }}>
      <Typography variant='h5' sx={{ width: 'calc(100%)', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', display: '-webkit-box', wordBreak: 'normal' }}>
        {title}
      </Typography>
    </Box>
  )
}
