import { Button, TextField, Typography } from '@mui/material'
import React from 'react'
import { DialogModal } from './DialogModal'

export const NeedHelpModal: React.FC<{ needHelpOpen: boolean, setNeedHelpOpen: (arg: boolean) => void }> = ({ needHelpOpen, setNeedHelpOpen }) => {
  return (
    <>
      <DialogModal open={needHelpOpen} closeHandler={() => setNeedHelpOpen(false)} title={<Typography variant='h3'>How can we help?</Typography>}>
        <Typography variant='body1'>
          Sumbit your question. A member of the Research administration Team will respond shortly. For additional follow up or questions, please email ra-help@mit.edu directly
        </Typography>
        <TextField sx={{ my: 2 }} minRows={3} variant='filled' fullWidth multiline label='Type your question or comment here' />
        <Button sx={{ mr: 0, ml: 'auto' }} variant='contained'>
          Send Message
        </Button>
        {/* <MDWithCompMapping content={infoTextBody} /> */}
      </DialogModal>
    </>
  )
}
