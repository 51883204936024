import React from 'react'
import { Paper, Box, Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Masonry from '@mui/lab/Masonry'
import { useStoreState } from 'store/hooks'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import sx from 'mui-sx'
import { animateEntranceSx, gettingStartedCardsSx } from '../styles/common'

const MasonryComponent: React.FC = () => {
  const { gettingStartedItems } = useStoreState((state) => state.GettingStartedModel)
  return (
    <>
      <Masonry columns={{ xs: 1, md: 2, lg: 3, xl: 4 }} sx={{ m: 0 }} spacing={2}>
        {gettingStartedItems.map((item, index) => (
          <Paper key={item.key} sx={sx(gettingStartedCardsSx, animateEntranceSx(index / 10))} elevation={0}>
            <Box sx={{ width: '100%', textAlign: 'center', fontSize: '30px', mb: '16px' }}>
              <FontAwesomeIcon icon={['far', item.icon as IconName]} />
              {/* <HuiIcon icon={item.icon} type='far' /> */}
            </Box>
            <Typography variant='h4' sx={{ textAlign: 'center' }}>
              {item.title}
            </Typography>
            {/* <Typography variant='body1'>{item.text}</Typography> */}
            <Typography variant='body1'>{item.description}</Typography>
            {item.links !== undefined && (
              <List>
                {item.links.map((listItem, idx) => (
                  <ListItem key={`li-${idx}`} disablePadding divider={idx !== (item?.links?.length ?? 0) - 1}>
                    <ListItemButton LinkComponent='a' component='a' href={listItem.link}>
                      <ListItemIcon sx={{ minWidth: '24px' }}>
                        <FontAwesomeIcon icon={['fas', listItem.icon === 'global' ? 'globe' : (listItem.icon as IconName)]} color='#018a7d' />
                        {/* <HuiIcon icon={listItem.icon as any} type='fas' color='#007BC2' /> */}
                      </ListItemIcon>
                      <ListItemText primary={listItem.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        ))}
      </Masonry>
    </>
  )
}

export default MasonryComponent
