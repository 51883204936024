import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Masonry } from '@mui/lab'
import { Box, Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Skeleton, Typography } from '@mui/material'
import { randomInt } from 'common'
import { animateEntranceSx, summaryCardSx } from 'components/styles'
import sx from 'mui-sx'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'

const getColor = (itemDescription: string | undefined): { bg: string, text: string } => {
  switch (itemDescription) {
    case 'Active':
    case 'Funded':
      return { bg: 'rgba(2, 150, 0, 0.1)', text: 'rgba(2, 150, 0)' }
    case 'Restricted':
    case 'Inactive':
    case 'Closed':
    case 'Terminated':
      return { bg: 'rgba(227, 18, 75, 0.1)', text: 'rgba(227, 18, 75)' }
    case 'Pending':
    case 'Hold':
    default:
      return { bg: 'rgb(255, 138, 0, 0.1)', text: 'rgb(255, 138, 0)' }
  }
}

const getIcon = (itemDescription: string): IconName => {
  switch (itemDescription) {
    case 'Hold':
      return 'hourglass'
    case 'Pending':
      return 'clock'
    case 'Active':
    case 'Funded':
      return 'circle-check'
    default:
      return 'circle-exclamation'
  }
}

export const MyResearch: React.FC = () => {
  const { appName } = useStoreState((state) => state.AppConfigModel)
  const { awardsSummary, proposalsSummary, agreementsSummary, protocolsSummary, loadingSummaries } = useStoreState((state) => state.GlobalModel)
  const { setListFilters: setAwardsFilters, setPageToFetch: setPageToFetchAwards, setAllAwardsLists } = useStoreActions((store) => store.AwardsModel)
  const { setListFilters: setProposalsFilters, setPageToFetch: setPageToFetchProposals, setAllProposalsLists } = useStoreActions((store) => store.ProposalsModel)
  const { setListFilters: setAgreementsFilters, setPageToFetch: setPageToFetchAgreements, setAllAgreementsLists } = useStoreActions((store) => store.AgreementsModel)
  const { setListFilters: setProtocolsFilters, setPageToFetch: setPageToFetchProtocols, setAllProtocolsLists } = useStoreActions((store) => store.ProtocolsModel)

  const pages = ['Proposals', 'Awards', 'Agreements', 'Protocols']
  const navigate = useNavigate()

  const handleClick = (filter: string, page: string): void => {
    switch (page) {
      case 'Awards':
        setAwardsFilters({ awardStatusCode: filter })
        setAllAwardsLists([])
        setPageToFetchAwards(1)
        navigate(`/${page.toLowerCase()}`)
        break
      case 'Proposals':
        setProposalsFilters({ proposalStatusCode: filter })
        setAllProposalsLists([])
        setPageToFetchProposals(1)
        navigate(`/${page.toLowerCase()}`)
        break
      case 'Agreements':
        setAgreementsFilters({ agreementStatusCode: filter })
        setAllAgreementsLists([])
        setPageToFetchAgreements(1)
        navigate(`/${page.toLowerCase()}`)
        break
      case 'Protocols':
        setProtocolsFilters({ protocolStatusCode: filter })
        setAllProtocolsLists([])
        setPageToFetchProtocols(1)
        navigate(`/${page.toLowerCase()}`)
        break

      default:
        break
    }
  }

  return (
    <>
      <Helmet>
        <title>MY RESEARCH</title>
        <meta name='description' content={` ${appName} AWARDS PAGE DESCRIPTION`} />
      </Helmet>
      <Typography variant='h3' sx={{ mb: 6, pt: 1, width: '100%', color: 'common.AtlasBrand.brand_1.main' }}>
        My Research
      </Typography>
      <Box sx={{ width: '100%' /* , '@media screen and (max-width: 950px)': { width: '100%' } */, margin: 'auto' }}>
        <Masonry columns={{ xs: 1, md: 2, lg: 3, xl: 4 }} spacing={2}>
          {[proposalsSummary, awardsSummary, agreementsSummary, protocolsSummary].map((summary, i) => (
            <Paper elevation={0} sx={sx(summaryCardSx, { condition: !loadingSummaries, sx: animateEntranceSx(i / 5) })} key={`sum-${i}`}>
              <Typography variant='h4'>{pages[i] === 'Agreements' ? 'NDA / DUA' : pages[i]}</Typography>
              <List dense>
                {loadingSummaries && (
                  <>
                    {[...Array(randomInt(2, 8))].map((_, i) => (
                      <ListItem key={`ls-skel-${i}`} divider={i < 4}>
                        <Skeleton variant='circular' sx={{ width: '16px', height: '16px', mr: 1 }} />
                        <Skeleton variant='rectangular' sx={{ height: '16px', my: 1, width: '250px' }} />
                        <Skeleton variant='rectangular' sx={{ width: '32px', height: '16px', ml: 'auto', mr: 1 }} />
                        <FontAwesomeIcon icon={['far', 'chevron-right']} color='grey' />
                      </ListItem>
                    ))}
                  </>
                )}
                {summary.length > 0 &&
                  !loadingSummaries &&
                  summary.map((line, index) => {
                    const firstWord = line.name?.split(' ')[0]
                    const color = getColor(firstWord ?? '')
                    return (
                      <ListItem
                        key={`LI-${line.name ?? 'unnamed'}-${line.count ?? index}`}
                        divider={index !== summary.length - 1}
                        // secondaryAction={
                        //   <IconButton onClick={() => handleClick(line.code ?? '', pages[i])}>
                        //     <Typography>
                        //       <FontAwesomeIcon icon={['far', 'chevron-right']} />
                        //     </Typography>
                        //   </IconButton>
                        // }
                      >
                        <ListItemButton sx={{ maxWidth: '100%' }} onClick={() => handleClick(line.code ?? '', pages[i])}>
                          <ListItemIcon sx={{ minWidth: '25px' }}>
                            <FontAwesomeIcon icon={['far', getIcon(firstWord ?? '')]} color={color.text} />
                          </ListItemIcon>
                          <ListItemText
                            disableTypography
                            primary={
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{ p: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{line.name}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                                  <Chip label={line.count} sx={{ mr: 1, height: '24px', borderRadius: '8px', color: color.text, backgroundColor: color.bg }} />
                                  <FontAwesomeIcon icon={['far', 'chevron-right']} />
                                </Box>
                                {/* <Chip label={line.count} sx={{ height: '24px', borderRadius: '8px', color: color.text, backgroundColor: color.bg }} /> */}
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
              </List>
            </Paper>
          ))}
        </Masonry>
      </Box>
    </>
  )
}
