import { Action, action, Thunk, thunk } from 'easy-peasy'
import { components } from '../../types/api_task'
import { piDashController } from '../../api/controller/PiDashController'
// import { ITEMS_PER_PAGE } from 'store'
// import { TempActionsJson, TempActionsListJson } from 'types'

interface State {
  actions: components['schemas']['TaskListResponse']
  loading: boolean
}
interface Actions {
  setActions: Action<this, components['schemas']['TaskListResponse']>
  setLoading: Action<this, boolean>
}
// eslint-disable-next-line
interface Thunks {
  fetchActions: Thunk<this>
}

export interface IActionsModel extends State, Actions, Thunks {}

export const ActionsModel: IActionsModel = {
  // State
  actions: {},
  loading: false,

  // Actions
  setActions: action((state, payload) => {
    state.actions = payload
  }),

  setLoading: action((state, payload) => {
    state.loading = payload
  }),

  // Thunks
  fetchActions: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    if (!state.loading) {
      actions.setLoading(true)
    }
    try {
      const data = await piDashController.getActions()
      actions.setActions(data)
    } catch (err: any) {
      console.log(err)
      if (err.name !== 'AbortError') {
        return await Promise.reject(err)
      }
    } finally {
      actions.setLoading(false)
    }
  })
}
