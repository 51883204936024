import { Box, Chip, Paper, Stack, Typography } from '@mui/material'
import { formatDate } from 'common'
import { animateEntranceSx, cardSx, lineClamp } from 'components/styles'
import { SmallTitle } from 'components/UIComponents'
import sx from 'mui-sx'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { components } from 'types'
import { getChipColor } from '../../common/getChipColor'
// import { format } from 'date-fns'
import ProposalItemDetailsDialog from '../ProposalItem/ProposalItemDetailsDialog'
import { CardTitle } from '../UIComponents'
import { useStoreState } from 'store/hooks'

interface ProposalItemCardCompProps {
  item: components['schemas']['ProposalResponse']
  index: number
}

const ProposalItemCardComp: React.FC<ProposalItemCardCompProps> = ({ item, index }) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <Paper component='button' onClick={() => setOpen(true)} elevation={0} sx={sx(cardSx, animateEntranceSx(index / 10))}>
        <MemoizedCardContents item={item} />
      </Paper>
      <ProposalItemDetailsDialog item={item} open={open} setOpen={setOpen} />
    </>
  )
}

export default ProposalItemCardComp

// Memoized CardContent to prevent rerendering the content of all the cards when opening and closing the modal -->
interface CardContentsProps {
  item: components['schemas']['ProposalResponse']
}

const CardContents: React.FC<CardContentsProps> = ({ item }) => {
  const { section } = useStoreState((store) => store.HigherLevelProposalsModel)
  const location = useLocation()
  const showAlternativeCard = location.pathname === '/admin-proposals' && section === 'deptItems'
  return (
    <>
      <CardTitle title={item.title} />
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', flex: '0 0 auto' }}>
        <Stack sx={{ mr: 2 }} spacing={0}>
          {showAlternativeCard ? (
            <>
              <SmallTitle title='Lead Unit' />
              <Typography sx={lineClamp(2)} variant='body1'>
                {item.leadUnitName}
              </Typography>
            </>
          ) : (
            <>
              <SmallTitle title='Sponsor' />
              <Typography sx={lineClamp(2)} variant='body1'>
                {item.sponsorName}
              </Typography>
            </>
          )}
        </Stack>
      </Box>
      <Box sx={{ width: '100%', flex: '0 0 auto' }}>
        <Box sx={{ display: 'flex', flexFlow: 'row nowarp', justifyContent: 'space-between', alignItems: 'flex-end', height: '100%' }}>
          <Chip
            label={
              <Typography component='div' sx={{ p: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.proposalStatusDescription}
              </Typography>
            }
            sx={{ maxWidth: '50%', height: '24px', borderRadius: '8px', fontWeight: '400' }}
            color={getChipColor(item.proposalStatusDescription)}
          />
          <Stack spacing={0} alignItems='flex-end'>
            <SmallTitle title='Start Date' />
            <Typography variant='body2' sx={{ fontWeight: '600', color: 'rgba(0,0,0,0.76)' }}>
              {formatDate(item.startDate, true)}
              {/* {item.startDate !== undefined ? format(new Date(item.startDate?.slice(0, 10) ?? ''), 'MMM dd, yyyy') : ''} */}
            </Typography>
          </Stack>
          {/* <AvatarWithTitleStack alignItems='flex-end' title='Start Date' name={item.startDate !== null ? format(new Date(item.startDate?.slice(0, 10) ?? ''), 'MMMM dd, yyyy') : ''} /> */}
        </Box>
      </Box>
    </>
  )
}

const MemoizedCardContents = React.memo(CardContents)
