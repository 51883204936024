import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useState } from 'react'
import { useStoreState, useStoreActions } from 'store/hooks'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export type FilterOptions = 'Current unit' | 'Roll up'

export const PiTypeFilterDropDown: React.FC = () => {
  const { piListQueryParams: piListFilters } = useStoreState((store) => store.HigherLevelAwardsModel)
  const { setPiListQueryParams: setPiListFilters } = useStoreActions((store) => store.HigherLevelAwardsModel)
  const { rollup } = piListFilters
  const [selection, setSelection] = useState<FilterOptions>(() => (rollup ? 'Roll up' : 'Current unit'))

  const handleChange = (e: SelectChangeEvent): void => {
    const value: FilterOptions = e.target.value as FilterOptions
    setPiListFilters({ rollup: value === 'Roll up' })
    setSelection(value)
  }

  return (
    <FormControl variant='filled' sx={{ mx: 0.5, minWidth: 150 }}>
      <InputLabel id='demo-simple-select-filled-label'>Type</InputLabel>
      <Select
        sx={{ backgroundColor: '#E1E6EC' }}
        labelId='demo-simple-select-filled-label'
        id='demo-simple-select-filled'
        value={selection}
        size='small'
        onChange={handleChange}
        IconComponent={ExpandMoreIcon}
      >
        <MenuItem value='Current unit'>
          <Box sx={{ display: 'inline-block', fontWeight: 'bold' }}>Current Unit</Box>
        </MenuItem>
        <MenuItem value='Roll up'>
          <Box sx={{ display: 'inline-block', fontWeight: 'bold' }}>Roll up</Box>
        </MenuItem>
      </Select>
    </FormControl>
  )
}
