import React, { FC } from 'react'
import { createStore, StoreProvider } from 'easy-peasy'
import { getEnv } from '../utility/Environment'
// import { getEnv } from 'common'
import { model } from './models'
import appConfig from '../app-config'

export * from './hooks'
export * from './constants'
export * from './searchBoxRef'

/* The localStorage and SessionStorge unique key is build from the name the version and the model name. eg: [appname-dev][0.0][LocalStorageModel]
 * Update this version number when you would like to force a new storage key on the client browser.
 * When store changes structure or jou just want to wipe thier Local or Session storage for this app on thier next visit.
 * src/TemplateConstants/store/model/LocalPresist.ts OR src/TemplateConstants/store/model/SessionPresist.ts
 */
const store = createStore(model, {
  name: `${appConfig.name}-${getEnv()}`,
  version: 0.0
})

const CoreProvider: FC = ({ children }) => <StoreProvider store={store}> {children} </StoreProvider>
export { store, CoreProvider }
