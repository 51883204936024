import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const formatDate = (date: string | undefined, short = false): string => {
  if (date !== null && date !== undefined && date !== '') {
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
    const zonedDate = utcToZonedTime(date, timeZoneName)
    // const dt = new Date(date.slice(0, 10))
    // const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)

    if (short) {
      return format(zonedDate, 'MMM dd, yyyy')
    }
    return format(zonedDate, 'MMMM dd, yyyy')
  }
  return '---'
}
