import { IconName } from '@fortawesome/fontawesome-svg-core'
import { Box, Chip, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { formatDate } from 'common'
import { cardInfoBlockSx } from 'components/styles'
import { AvatarWithTitleStack, IconTitleValueStack } from 'components/UIComponents'
import React from 'react'
import { components } from 'types'
import { getChipColor } from '../../common/getChipColor'
import { DialogModal } from '../UIComponents'

interface AgreementItemDetailsDialogProps {
  item: components['schemas']['AgreementDetailResponse']
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AgreementItemDetailsDialog: React.FC<AgreementItemDetailsDialogProps> = ({ item, open, setOpen }) => {
  const maxWidth600 = useMediaQuery('(max-width:600px)')

  const cardBlocksData: Array<{ icon: IconName, title: string, date: string, color: string }> = [
    {
      icon: 'play',
      color: '#04A091',
      title: 'Start Date',
      date: item.startDate
    },
    {
      icon: 'stop',
      color: '#30AF5D',
      title: 'End Date',
      date: item.endDate
    }
  ]

  const cardBlock = (icon: IconName, title: string, date: string, color: string, index: number): JSX.Element => (
    <Paper elevation={0} sx={cardInfoBlockSx}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box sx={{ display: 'flex', flexFlow: ' column nowrap', alignItems: 'end' }}>
          <IconTitleValueStack icon={icon} title={title} color={color} stringValue={formatDate(date, true)} />
        </Box>
      </Box>
    </Paper>
  )

  return (
    <div>
      <DialogModal maxDialogWidth='100%' closeHandler={() => setOpen(false)} open={open} title='NDA / DUA Info' slideDirection='left'>
        <>
          <Box sx={{ m: 1 }}>
            <Typography variant='h3'>{item.title}</Typography>
            <Typography variant='h5' sx={{ fontWeight: 600 }}>
              {item.agreementTypeDescription}
            </Typography>
            <Box sx={{ my: 3 }}>
              <Chip
                label={item.agreementStatusDescription}
                sx={{ height: '24px', borderRadius: '8px', fontWeight: 'bold' }}
                color={getChipColor(item.agreementStatusDescription)}
              />
            </Box>
            <Grid container spacing={2} sx={{ mb: maxWidth600 ? 5 : 20, flexWrap: maxWidth600 ? undefined : 'nowrap' }}>
              <Grid item>
                <AvatarWithTitleStack bold title='Lead Unit Name' name={item.leadUnitName} />
              </Grid>
              <Grid item>
                <AvatarWithTitleStack bold title='Organization' name={item.organization} />
              </Grid>
              <Grid item>
                <AvatarWithTitleStack bold title='PI' name={item.principalInvestigatorName} />
              </Grid>
              <Grid item>
                <AvatarWithTitleStack bold title='Requestor' name={item.requestorName} />
              </Grid>
            </Grid>
          </Box>

          {/* Cards */}
          <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between', flexFlow: 'row wrap' }}>
            {cardBlocksData.map((item, index) => cardBlock(item.icon, item.title, item.date, item.color, index))}
          </Box>
          {/* End of Cards */}
        </>
      </DialogModal>
    </div>
  )
}

export default AgreementItemDetailsDialog
