import { BaseController } from './BaseController'

import { Profile } from 'types'

class ProfileController extends BaseController {
  public async getProfile (): Promise<Profile> {
    // const response = await this.useFetch('GET', 'profile', `https://arnwetbogj.execute-api.us-east-1.amazonaws.com/develop/profile`)
    const response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

    if (response.status === 200) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getProfilePicture (id?: string): Promise<Blob> {
    // const response = await this.useFetch('GET', 'picture', `https://arnwetbogj.execute-api.us-east-1.amazonaws.com/develop/picture`)
    const response = await this.useFetch('GET', `picture${id ?? ''}`, `${this.apiHost}/${this.apiPathDigitalId}/picture${id === undefined ? '' : '/' + id}`)

    if (response.status === 200) {
      return response.blob()
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}

export const profileController = new ProfileController()
