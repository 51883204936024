import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, List, ListItem, ListItemButton, Typography } from '@mui/material'
import ExpenseCategoryDrawer from 'components/AwardItem/ExpenseCategoryDrawer'
import sx from 'mui-sx'
import numeral from 'numeral'
import React, { useState } from 'react'
import { components } from 'types'

interface ProjectFinancialBlockProps {
  color: string
  title: string
  items?: Array<components['schemas']['FinancialRowResponse']>
  // listItems: Array<{ description: string; value: number; subCatRows: components['schemas']['FinancialRowResponse']}>
  total: number
  border?: string
  level: number
}

export const ProjectFinancialBlock: React.FC<ProjectFinancialBlockProps> = ({ color, title, items, total, border, level }) => {
  const [nextFinancialBlock, setNextFinancialBlock] = useState<components['schemas']['FinancialRowResponse'] | undefined>(undefined)
  const handleClose = (): void => {
    setNextFinancialBlock(undefined)
  }
  return (
    <>
      <Box sx={sx({ backgroundColor: 'white', borderRadius: '16px', width: '100%', my: 5, p: 3, boxSizing: 'border-box' }, { border: border ?? undefined })}>
        <Typography variant='h4' sx={{ fontWeight: '600' }} color={color}>
          {title}
        </Typography>
        <List>
          {items?.map((item, index) => {
            const canDrillDown = item.rows !== undefined && item.rows.length > 0
            return (
              <React.Fragment key={`LI-${index}-${item.description}`}>
                <ListItem divider sx={{ pr: 0 }}>
                  <ListItemButton
                    disabled={!canDrillDown}
                    onClick={() => {
                      setNextFinancialBlock(item)
                      // incrNrOfOpenDrawers()
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography variant='body1'>{item.description}</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                        <Typography variant='body1'>{numeral(item.amount ?? 0).format('($ 0,0)')}</Typography>
                        {/* <Typography variant='body1'>{formatCurrency.format(item.amount ?? 0)}</Typography> */}
                        {canDrillDown && (
                          // <IconButton
                          //   sx={{ pr: 0, py: 0 }}
                          //   onClick={() => {
                          //     setNextFinancialBlock(item)
                          //     // incrNrOfOpenDrawers()
                          //   }}>
                          <ChevronRightIcon />
                          // </IconButton>
                        )}
                      </Box>
                    </Box>
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            )
          })}
        </List>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'start' }}>
          <Typography variant='body1' sx={{ mr: 1.5 }}>
            Total
          </Typography>
          {/* <Typography variant='h2'>{formatCurrency.format(total)}</Typography> */}
          <Typography variant='h2'>{numeral(total).format('($ 0,0)')}</Typography>
        </Box>
      </Box>
      <ExpenseCategoryDrawer level={1} title={title} item={nextFinancialBlock} open={nextFinancialBlock !== undefined} handleClose={handleClose} />
    </>
  )
}
