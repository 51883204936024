import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Initialization, Logout } from '@mit/aws-react'
import { HuiThemeProvider } from '@mit/hui-core'
import { useAppInitializer } from 'hooks/Initializer'
import { useStoreState, CoreProvider } from 'store'
import appConfig from 'app-config'
import reportWebVitals from './reportWebVitals'
import 'scss/main.scss'
// import { addIconLib } from 'common'
// import '@fontsource/open-sans/400.css'
// import '@fontsource/open-sans/700.css'
import { GettingStarted, AwardsPage, ProtocolsPage, ProposalsPage, AgreementsPage, /* UnderConstruction,  */ MyResearch } from 'components/routes'
import piDashThemeOverride from './components/styles/piDashThemeOverride.json'
import { ActionsPage } from 'components/routes/ActionsPage'
import { HighLevelProposalsPage } from 'components/routes/HighLevelProposalsPage'
import { HighLevelAwardsPage } from 'components/routes/HighLevelAwardsPage'

// addIconLib()

const App = React.lazy(async () => await import('components/app/App').then((module) => ({ default: module.App })))
// const Home = React.lazy(async () => await import('components/routes/Home').then((module) => ({ default: module.Home })))
// const GettingStarted = React.lazy(async () => await import('components/routes/GettingStarted').then((module) => ({ default: module.GettingStarted })))
// const AwardsPage = React.lazy(async () => await import('components/routes/AwardsPage').then((module) => ({ default: module.AwardsPage })))
// const ProtocolsPage = React.lazy(async () => await import('components/routes/ProtocolsPage').then((module) => ({ default: module.ProtocolsPage })))
// const ProposalsPage = React.lazy(async () => await import('components/routes/ProposalsPage').then((module) => ({ default: module.ProposalsPage })))
// const AgreementsPage = React.lazy(async () => await import('components/routes/AgreementsPage').then((module) => ({ default: module.AgreementsPage })))

const WrappedApp: FC = () => {
  const { appName, appContact /* , appTheme, appDescription */ } = useStoreState((state) => state.AppConfigModel)
  const { initializeApp } = useAppInitializer()

  return (
    <>
      <Router>
        <React.StrictMode>
          <Routes>
            <Route
              // path='/'
              element={
                <Initialization
                  App={App}
                  appInitializer={initializeApp}
                  appConfig={appConfig}
                  useCognitoAuthentication
                  loaderProps={{
                    contactEmail: appContact,
                    name: appName,
                    isLoading: true,
                    // exit: false,
                    type: 'Default'
                    // theme: appTheme
                  }}
                />
              }
            >
              <Route path='/my-research' element={<MyResearch />} />
              <Route path='/proposals' element={<ProposalsPage />} />
              <Route path='/awards' element={<AwardsPage />} />
              <Route path='/agreements' element={<AgreementsPage />} />
              <Route path='/protocols' element={<ProtocolsPage />} />
              <Route path='/actions' element={<ActionsPage />} />
              <Route path='/getting-started' element={<GettingStarted />} />
              <Route path='/logout' element={<Logout />} />
              <Route path='/admin-proposals' element={<HighLevelProposalsPage />} />
              <Route path='/admin-awards' element={<HighLevelAwardsPage />} />
              <Route index element={<MyResearch />} />
            </Route>
          </Routes>
        </React.StrictMode>
      </Router>
    </>
  )
}

ReactDOM.render(
  <CoreProvider>
    <HuiThemeProvider variant='pidash' themeOverride={piDashThemeOverride as any}>
      <WrappedApp />
    </HuiThemeProvider>
  </CoreProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
