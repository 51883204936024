const appConfig = {
  "name": "pi-dashboard",
  "version": "1.0.0",
  "stage": "develop",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-dev.mit.edu",
      "amplify": {
        "userPoolId": "us-east-1_14cFcpuS8",
        "userPoolWebClientId": "73n09568sgov2pf2uv2k9jrlk",
        "oauth": {
          "domain": "atlas-auth-dev.mit.edu",
          "scope": [
            "profile",
            "openid",
            "user/all",
            "digital-id/user"
          ],
          "redirectSignIn": "https://pi-dash-dev.mit.edu",
          "redirectSignOut": "https://pi-dash-dev.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-dev.mit.edu",
        "paths": {
          "piDashboard": "pi-dash-v1",
          "feature": "feature-v1",
          "digital-id": "digital-id-v1"
        }
      }
    },
    {
      "name": "us-west-1",
      "isPrimaryRegion": false,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-dev.mit.edu",
      "amplify": {
        "userPoolId": "us-west-1_zBZDEjiKC",
        "userPoolWebClientId": "oo50u2bge5o18k2htr1t8mmo1",
        "oauth": {
          "domain": "atlas-auth-us-west-dev.mit.edu",
          "scope": [
            "profile",
            "openid",
            "user/all",
            "digital-id/user"
          ],
          "redirectSignIn": "https://pi-dash-dev.mit.edu",
          "redirectSignOut": "https://pi-dash-dev.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-us-west-dev.mit.edu",
        "paths": {
          "piDashboard": "pi-dash-v1",
          "feature": "feature-v1",
          "digital-id": "digital-id-v1"
        }
      }
    }
  ]
};
export default appConfig;