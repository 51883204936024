import { components } from 'types'
import { breadthSync } from 'tree-traversal'
import { ActionCreator } from 'easy-peasy'
import { ICurrentDepartment } from 'store/models/HigherLevelProposals'
// import { useStoreActions } from 'store/hooks'

export const getBreadcrumbsPath = (
  selectedUnit: components['schemas']['DepartmentSummaryResponse'],
  storeState: {
    allDepartmentsList: Array<components['schemas']['DepartmentSummaryResponse']>
    // searchString: string
  },
  storeActions: {
    setCurrentDepartment: ActionCreator<ICurrentDepartment>
    setCurrentDepartmentsLists: ActionCreator<Array<components['schemas']['DepartmentSummaryResponse']>>
    removeBreadcrumbItems: ActionCreator<string | undefined>
    // clearSearchString: ActionCreator<void>
  }
): Array<{ label?: string, clickHandler?: () => void }> => {
  const { allDepartmentsList /* , searchString */ } = storeState
  const { setCurrentDepartment, setCurrentDepartmentsLists, removeBreadcrumbItems /* , clearSearchString */ } = storeActions

  const createdHistory: Array<{ label?: string, clickHandler?: () => void }> = []

  const getParentNode = (topLevelDept: components['schemas']['DepartmentSummaryResponse'], selectedUnit: components['schemas']['DepartmentSummaryResponse']): void => {
    breadthSync(topLevelDept, {
      subnodesAccessor: (node: components['schemas']['DepartmentSummaryResponse']) => node.children,
      onNode: (node: components['schemas']['DepartmentSummaryResponse'], userdata: components['schemas']['DepartmentSummaryResponse']) => {
        if (node.code === userdata.parent) {
          createdHistory.push({
            label: node.name,
            clickHandler: () => {
              setCurrentDepartment({ name: node.name, code: node.code })
              if (node.children !== undefined) {
                setCurrentDepartmentsLists(node.children)
              }
              removeBreadcrumbItems(node.name)
            }
          })
          getParentNode(topLevelDept, { level: node.level, parent: node.parent })
        }
      },
      userdata: selectedUnit
    })
  }

  allDepartmentsList.forEach((topLevelDept) => getParentNode(topLevelDept, selectedUnit))
  const breadcrumbsPath = createdHistory.reverse().concat([
    {
      label: selectedUnit.name,
      clickHandler: () => {
        setCurrentDepartment({ name: selectedUnit.name, code: selectedUnit.code })
        if (selectedUnit.children !== undefined) {
          setCurrentDepartmentsLists(selectedUnit.children)
        }
        removeBreadcrumbItems(selectedUnit.name)
      }
    }
  ])

  return breadcrumbsPath
}
