import { Box, Paper, Stack, Typography } from '@mui/material'
// import ProposalSummItemDetailsDialog from '../ProposalSummItem/ProposalSummItemDetailsDialog'
import { animateEntranceSx, cardSx } from 'components/styles'
import { SmallTitle } from 'components/UIComponents'
import sx from 'mui-sx'
// import numeral from 'numeral'
import React, { useState, useLayoutEffect } from 'react'
import { components } from 'types'
import { CardTitle } from '../UIComponents'
import { useStoreActions, useStoreState } from 'store/hooks'
import { getBreadcrumbsPath } from 'common/getBreadcrumbsPath'
import { searchBoxRef } from 'store'

interface ProposalSummItemCardCompProps {
  item: components['schemas']['DepartmentSummaryResponse']
  index: number
  // setItems: (nextDeptList: components['schemas']['DepartmentSummaryResponse'][]) => void
}

const ProposalSummItemCardComp: React.FC<ProposalSummItemCardCompProps> = ({ item, index /* , setItems */ }) => {
  // const [open, setOpen] = useState<boolean>(false)
  const [animateIn, setAnimateIn] = useState(false)
  const { allDepartmentsList, unitSearchString: searchString, unitSearchResults: searchResults } = useStoreState((store) => store.HigherLevelProposalsModel)
  const { setCurrentDepartmentsLists, removeBreadcrumbItems, setCurrentDepartment, pushBreadcrumbItems, setBreadcrumbItems, clearSearchString } = useStoreActions(
    (store) => store.HigherLevelProposalsModel
  )

  useLayoutEffect(() => {
    setAnimateIn(true)
    const setAction = (): void => {
      setAnimateIn(false)
    }
    const delay = 2000 + (index / 10) * 1000
    const timeout = setTimeout(setAction, delay)
    return () => clearTimeout(timeout)
  }, [item.code, index])

  const handleClick = (item: components['schemas']['DepartmentSummaryResponse'], i: number): void => {
    if (item !== undefined) {
      if (item.children !== undefined) {
        if (searchString !== '') {
          clearSearchString()
          if (searchBoxRef.current != null) {
            searchBoxRef.current.value = ''
          }
        }
        setCurrentDepartment({ name: item.name, code: item.code })
        setCurrentDepartmentsLists(item.children)
        if (searchResults.length === 0) {
          pushBreadcrumbItems({
            label: item.name,
            clickHandler: () => {
              setCurrentDepartment({ name: item.name, code: item.code })
              if (item.children !== undefined) {
                setCurrentDepartmentsLists(item.children)
              }
              removeBreadcrumbItems(item.name)
            }
          })
        } else {
          setBreadcrumbItems(
            getBreadcrumbsPath(
              item,
              { allDepartmentsList /* , searchString */ },
              { setCurrentDepartment, setCurrentDepartmentsLists, removeBreadcrumbItems /* , clearSearchString */ }
            )
          )
          // Find and set breadcrumbs path
        }
      }
    }
  }

  return (
    <>
      <Paper
        component='button'
        onClick={() => handleClick(item, index)}
        elevation={0}
        sx={sx(cardSx, { background: 'radial-gradient(circle, #FFFFFF00 0%, #0000000D 100%)', border: 'none' }, { condition: animateIn, sx: animateEntranceSx(index / 10) })}
      >
        <CardTitle title={item.name ?? '{No Title}'} />
        <Box sx={{ width: '100%', display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-between', flex: '0 0 auto' }}>
          <Stack spacing={0}>
            <SmallTitle title='Proposals' />
            <Typography variant='body1' color='rgba(0, 0, 0, 0.5)' fontWeight='600' fontSize='20px'>
              {item.count}
            </Typography>
          </Stack>
          <Stack sx={{ alignItems: 'flex-end' }} spacing={0}>
            <SmallTitle title='Accummalitive Proposals' />
            {item.accumulativeCount != null && (
              <Typography variant='body1' fontWeight='600' fontSize='20px'>
                {/* {item.count} */}
                {item.accumulativeCount}
              </Typography>
            )}
          </Stack>
        </Box>
      </Paper>
      {/* <ProposalSummItemDetailsDialog item={item} open={open} setOpen={setOpen} /> */}
    </>
  )
}

export default ProposalSummItemCardComp
