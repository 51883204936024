import { Box, Grid, IconButton, Typography } from '@mui/material'
import { FilterDropDown } from 'components/Filters/FilterDropDown'
import { CardsSkeleton, DialogModal, ListFilterInputBox, NoResults } from 'components/UIComponents'
import MDWithCompMapping from 'components/UIComponents/Common/MDWithCompMapping'
// import { ProposalsFilterModal } from 'components/Filters/ProposalsFilterModal'
import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
import { useStoreActions, useStoreState } from 'store/hooks'
import ProposalItemCardComp from '../ProposalItem/ProposalItemCardComp'
import { GenericError } from '../UIComponents/Common/GenericError'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { components } from 'types/api'

export const ProposalsPage: React.FC = () => {
  const [doFetchInitialProposals, setDoFetchInitialProposals] = useState(true) // temp. Replace with other loggic
  const { appName } = useStoreState((state) => state.AppConfigModel)
  const { profileFetched } = useStoreState((store) => store.ProfileModel)
  const [fetchError, setFetchError] = useState(false)
  const [infoDialogueOpen, setInfoDialogueOpen] = useState(false)
  const { texts } = useStoreState((state) => state.GlobalModel)
  const infoTextTitle = texts.find((entry) => entry.textId === 'proposals.info.title')?.text
  const infoTextBody = texts.find((entry) => entry.textId === 'proposals.info.body')?.text
  const { allProposalsLists, proposalsListTotalPages, proposalsListCurrentPage, proposalsListTotalItems, listFilters, showSkeleton, pageToFetch, reFetchProposals } = useStoreState(
    (store) => store.ProposalsModel
  )
  const { setListFilters, fetchInitialPage, fetchMorePages, setFiltersChanged, setPageToFetch, incrementPageToFetch /* , setFetchError */ } = useStoreActions(
    (store) => store.ProposalsModel
  )

  const { ref, inView /* , entry */ } = useInView({
    /* Optional options */
    triggerOnce: false,
    initialInView: true,
    threshold: 0
  })

  // const listLength = allProposalsLists.length
  const adminRolesCheck = false
  const rolledUp = false
  useEffect(() => {
    if (reFetchProposals || doFetchInitialProposals) {
      setDoFetchInitialProposals(false)
      if (profileFetched) {
        fetchInitialPage({ listFilters, adminRolesCheck, rolledUp }).catch(() => setFetchError(true))
      }
    }
  }, [adminRolesCheck, doFetchInitialProposals, fetchInitialPage, listFilters, profileFetched, reFetchProposals, rolledUp])
  // }, [pageToFetch, listLength, listFilters, filtersChanged, fetchInitialPage, profileFetched, adminRolesCheck])

  useEffect(() => {
    if (pageToFetch > 1) {
      if (proposalsListCurrentPage < proposalsListTotalPages && pageToFetch > proposalsListCurrentPage) {
        fetchMorePages({ pageToFetch, listFilters, adminRolesCheck, rolledUp }).catch(() => setFetchError(true))
      }
    }
  }, [pageToFetch, listFilters, proposalsListCurrentPage, proposalsListTotalPages, proposalsListTotalItems, fetchMorePages, adminRolesCheck, rolledUp])

  useEffect(() => {
    if (inView) {
      incrementPageToFetch()
    }
  }, [inView, incrementPageToFetch])

  useEffect(() => {
    return () => setPageToFetch(1)
  }, [setPageToFetch])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setListFilters({ title: e.target.value })
    setFiltersChanged(true)
    setPageToFetch(1)
  }

  const debounceHandleSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      handleSearch(e)
    },
    800,
    { leading: false }
  )

  return (
    <>
      <Helmet>
        <title>PROPOSALS</title>
        <meta name='description' content={` ${appName} PROPOSALS PAGE DESCRIPTION`} />
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <Typography variant='h3' sx={{ color: 'common.AtlasBrand.brand_2.main', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box component='span'>Proposals</Box>
          <IconButton onClick={() => setInfoDialogueOpen(true)}>
            <InfoOutlinedIcon />
          </IconButton>
        </Typography>
        <Box sx={{ mt: 2.5, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <ListFilterInputBox
            label='Filter List'
            changeHandler={(e) => {
              debounceHandleSearch(e)
            }}
          />

          <FilterDropDown filterFor='Proposals' />

          {/* <IconButton sx={{ ml: 1 }} aria-label='filter' onClick={() => setShowFilterModal(true)}>
            <FontAwesomeIcon icon={['far', 'filter']} color='#097AFF' />
          </IconButton>
          <ProposalsFilterBadges listFilters={listFilters} /> */}
        </Box>
        {allProposalsLists[0]?.results?.length === 0 && !showSkeleton && <NoResults page='Proposals' />}
        <>
          <Grid container spacing={2.5} sx={{ mt: 0 }}>
            {showSkeleton || (allProposalsLists.length === 0 && !fetchError) ? (
              <CardsSkeleton />
            ) : (
              allProposalsLists.length > 0 &&
              allProposalsLists.map((page, idx1) => {
                return page.results?.map((listItem, idx2) => <MemoGridItem key={listItem.documentNumber} idx2={idx2} listItem={listItem} />)
              })
            )}
            {proposalsListCurrentPage < proposalsListTotalPages && !fetchError && <CardsSkeleton />}
          </Grid>
          <Box ref={ref} />
        </>
        {fetchError && <GenericError page='Proposals' />}
      </Box>
      <DialogModal open={infoDialogueOpen} closeHandler={() => setInfoDialogueOpen(false)} title={<Typography variant='h3'>{infoTextTitle}</Typography>}>
        <MDWithCompMapping content={infoTextBody} />
      </DialogModal>
    </>
  )
}

interface MemoGridItemProps {
  idx2: number
  listItem: components['schemas']['ProposalResponse']
}

const MemoGridItem = React.memo(
  ({ idx2, listItem }: MemoGridItemProps) => {
    return (
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        xl={3}
        // ref={idx1 === allProposalsLists.length - 1 && idx2 === ITEMS_PER_PAGE - 1 ? ref : undefined}
      >
        <ProposalItemCardComp item={listItem} index={idx2} />
      </Grid>
    )
  },
  (prevProps, nextProps) => prevProps.listItem.documentNumber === nextProps.listItem.documentNumber
)
