import React from 'react'
import { Box, Grid, Paper, Skeleton, Stack } from '@mui/material'

export const PiListSkeleton: React.FC = () => {
  return (
    <>
      {[...Array(3)].map((_, i) => (
        <Grid item xs={12} key={`pls-${i}`}>
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              backgroundColor: 'white',
              borderRadius: '16px',
              width: '100%',
              p: 3,
              boxSizing: 'border-box'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton variant='circular' sx={{ width: '64px', height: '64px' }} />
              <Box sx={{ ml: 2 }}>
                <Box sx={{ mb: 1 }}>
                  <Skeleton variant='rectangular' sx={{ width: '150px', height: '22px' }} />
                </Box>
                <Box>
                  <Skeleton variant='rectangular' sx={{ width: '250px', height: '22px' }} />
                </Box>
              </Box>
            </Box>
            <Stack sx={{ mr: 2 }} alignItems='end' spacing={0}>
              <Box>
                <Skeleton sx={{ height: '22px', width: '80px' }} />
              </Box>
              <Box>
                <Skeleton sx={{ height: '22px', width: '120px' }} />
              </Box>
            </Stack>
          </Paper>
        </Grid>
      ))}
    </>
  )
}
// <Grid item xs={12} key={`PLS-${i}`}>
//   <Skeleton variant='rectangular' sx={{ width: '100%', height: '114px', borderRadius: '8px' }} />
// </Grid>
