import React, { useState } from 'react'
import { Paper, Chip, Box, Stack, Typography } from '@mui/material'
import { components } from 'types'
import { AvatarWithTitleStack, CardTitle, SmallTitle } from '../UIComponents'
import { getChipColor } from '../../common/getChipColor'
// import { format } from 'date-fns'
import { formatDate } from 'common'
// import ProtocolItemDetailsDialog from '../ProtocolItem/ProtocolItemDetailsDialog'
import { animateEntranceSx, cardSx } from 'components/styles'
import sx from 'mui-sx'
import ProtocolItemDetailsDialog from './ProtocolItemDetailsDialog'

interface ProtocolItemCardCompProps {
  item: components['schemas']['ProtocolResponse']
  index: number
}

const ProtocolItemCardComp: React.FC<ProtocolItemCardCompProps> = ({ item, index }) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <Paper component='button' elevation={0} onClick={() => setOpen(true)} sx={sx(cardSx, animateEntranceSx(index / 10))}>
        <MemoizedCardContents item={item} />
      </Paper>
      <ProtocolItemDetailsDialog item={item} open={open} setOpen={setOpen} />
    </>
  )
}

export default ProtocolItemCardComp

interface CardContentsProps {
  item: components['schemas']['ProtocolResponse']
}

const CardContents: React.FC<CardContentsProps> = ({ item }) => {
  return (
    <>
      <CardTitle title={item.title} />
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', flex: '0 0 auto' }}>
        <AvatarWithTitleStack title='Type' name={item.protocolTypeDescription} />
      </Box>
      <Box sx={{ width: '100%', flex: '0 0 auto' }}>
        <Box sx={{ display: 'flex', flexFlow: 'row nowarp', justifyContent: 'space-between', alignItems: 'flex-end', height: '100%' }}>
          <Chip
            label={
              <Typography component='div' sx={{ p: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.protocolStatusDescription === 'Restricted - No Postings Allowed' ? 'Restricted' : item.protocolStatusDescription}
              </Typography>
            }
            sx={{ maxWidth: '50%', height: '24px', borderRadius: '8px', fontWeight: '400' }}
            color={getChipColor(item.protocolStatusDescription)}
          />
          <Stack spacing={0} alignItems='flex-end'>
            <SmallTitle title='Approval Date' />
            <Typography variant='body2' sx={{ fontWeight: '600', color: 'rgba(0,0,0,0.76)' }}>
              {/* {item.approvalDate !== undefined ? format(new Date(item.approvalDate?.slice(0, 10) ?? ''), 'MMM dd, yyyy') : ''} */}
              {formatDate(item.approvalDate, true)}
            </Typography>
          </Stack>
          {/* <AvatarWithTitleStack alignItems='flex-end' title='Start Date' name={item.approvalDate !== undefined ? format(new Date(item.approvalDate?.slice(0, 10) ?? ''), 'MMMM dd, yyyy') : ''} /> */}
        </Box>
      </Box>
    </>
  )
}

const MemoizedCardContents = React.memo(CardContents)
