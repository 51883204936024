import { IconName } from '@fortawesome/fontawesome-svg-core'
import { Box, Chip, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { formatDate } from 'common'
import { cardInfoBlockSx } from 'components/styles'
import { AvatarWithTitleStack } from 'components/UIComponents'
import { IconTitleValueStack } from 'components/UIComponents/Common/IconTitleValueStack'
import React from 'react'
import { components } from 'types'
import { getChipColor } from '../../common/getChipColor'
import { DialogModal } from '../UIComponents'

interface ProposalItemDetailsDialogProps {
  item: components['schemas']['ProposalDetailResponse']
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface ICardBlocksData {
  dateIcon: IconName
  costIcon: IconName
  dateTitle: string
  costTitle: string
  date: string
  color: string
  costEl?: number
}

const ProposalItemDetailsDialog: React.FC<ProposalItemDetailsDialogProps> = ({ item, open, setOpen }) => {
  const maxWidth600 = useMediaQuery('(max-width:600px)')

  const cardBlocksData: ICardBlocksData[] = [
    {
      dateIcon: 'play',
      costIcon: 'money-check-dollar',
      color: '#04A091',
      dateTitle: 'Start Date',
      costTitle: 'Total Cost',
      date: item.startDate,
      costEl: item.totalCost
    },
    {
      dateIcon: 'stop',
      costIcon: 'file-invoice-dollar',
      color: '#30AF5D',
      dateTitle: 'End Date',
      costTitle: 'Indirect Cost',
      date: item.endDate,
      costEl: item.indirectCost
    },
    {
      dateIcon: 'flag-checkered',
      costIcon: 'message-dollar',
      color: '#59BD2B',
      dateTitle: 'Deadline',
      costTitle: 'Direct Cost',
      date: item.deadlineDate,
      costEl: item.directCost
    }
  ]

  const dateCardBlock = (icon: IconName, title: string, color: string, date: string, index: number): JSX.Element => (
    <Paper elevation={0} sx={cardInfoBlockSx}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box sx={{ display: 'flex', flexFlow: ' column nowrap', alignItems: 'end' }}>
          <IconTitleValueStack icon={icon} title={title} color={color} stringValue={formatDate(date, true)} />
        </Box>
      </Box>
    </Paper>
  )

  const costCardBlock = (icon: IconName, title: string, color: string, index: number, currencyValue?: number): JSX.Element => (
    <Paper elevation={0} sx={cardInfoBlockSx}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box sx={{ display: 'flex', flexFlow: ' column nowrap', alignItems: 'end' }}>
          <IconTitleValueStack icon={icon} title={title} color={color} currencyValue={currencyValue} />
        </Box>
      </Box>
    </Paper>
  )

  return (
    <div>
      <DialogModal maxDialogWidth='900px' closeHandler={() => setOpen(false)} open={open} title='Proposal Info' slideDirection='left'>
        <>
          <Box sx={{ m: 1 }}>
            <Typography variant='h3'>{item.title}</Typography>
            <Box sx={{ my: 3 }}>
              <Chip label={item.proposalStatusDescription} sx={{ height: '24px', borderRadius: '8px', fontWeight: 'bold' }} color={getChipColor(item.proposalStatusDescription)} />
            </Box>
            <Grid container spacing={2} sx={{ mb: 5, flexWrap: maxWidth600 ? undefined : 'nowrap' }}>
              <Grid item>
                <AvatarWithTitleStack bold title='Sponsor' name={item.sponsorName} />
              </Grid>
              {item.primeSponsorName !== undefined && (
                <Grid item>
                  <AvatarWithTitleStack bold title='Sponsor' name={item.primeSponsorName} />
                </Grid>
              )}
              <Grid item>
                <AvatarWithTitleStack bold title='Lead Unit' name={item.leadUnitName} />
              </Grid>
              <Grid item>
                <AvatarWithTitleStack bold title='PI' name={item.principalInvestigatorName} />
              </Grid>
              <Grid item>
                <AvatarWithTitleStack bold title='Proposal Number' name={item.proposalNumber} />
              </Grid>
            </Grid>
          </Box>

          {/* Cards */}
          <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between', flexFlow: 'row wrap' }}>
            {cardBlocksData.map((item, index) => dateCardBlock(item.dateIcon, item.dateTitle, item.color, item.date, index))}
            {cardBlocksData.map((item, index) => costCardBlock(item.costIcon, item.costTitle, item.color, index, item.costEl))}
          </Box>
          {/* End of Cards */}
        </>
      </DialogModal>
    </div>
  )
}

export default ProposalItemDetailsDialog
