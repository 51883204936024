import { BaseController } from './BaseController'

export class TemplateController extends BaseController {
  public async getLookups (): Promise<Object> {
    const response = await this.useFetch('GET', 'lookups', `${this.apiHost}/${this.apiPath}/lookups`)

    if (response.status === 200) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}
