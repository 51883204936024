import { depthSync } from 'tree-traversal'
import { components } from 'types'

export const treeSearch = (
  arrayOfNodes: Array<components['schemas']['DepartmentSummaryResponse']>,
  searchString: string
): Array<components['schemas']['DepartmentSummaryResponse']> => {
  let searchResults: Array<components['schemas']['DepartmentSummaryResponse']> = []
  arrayOfNodes.forEach((node) => {
    depthSync(node, {
      subnodesAccessor: (node: components['schemas']['DepartmentSummaryResponse']) => node.children,
      onNode: (node: components['schemas']['DepartmentSummaryResponse']) => {
        const searchMatch: boolean = node.name !== undefined ? !!node.name.toLowerCase().includes(searchString.toLowerCase()) : false
        if (searchMatch) {
          searchResults = searchResults.concat([node])
        }
      }
    })
  })
  return searchResults
}
