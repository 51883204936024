import { Action, action, Computed, computed, Thunk, thunk, ActionOn, actionOn } from 'easy-peasy'
import { components } from '../../types/api'
import { piDashController } from '../../api/controller/PiDashController'

interface IPiListFilters {
  rollup: boolean
  onlyInternal: boolean
}
interface State {
  allDepartmentsList: Array<components['schemas']['DepartmentSummaryResponse']>
  currentDepartmentsList: Array<components['schemas']['DepartmentSummaryResponse']>
  currentDepartment: ICurrentDepartment
  isLeafNodeDepartment: Computed<this, boolean>
  fetchedPiList: ICurrentPiList
  allPiList: Array<components['schemas']['SummaryResponse']>
  piListFetchError: boolean
  piListShowSkeleton: boolean
  fetchError: boolean
  showSkeleton: boolean
  loadingMorePis: boolean
  section: Section
  breadcrumbItems: Array<{ label?: string, clickHandler?: () => void }>
  // hideToggle: Computed<this, boolean>
  hidePiToggle: Computed<this, boolean>
  unitSearchString: string
  unitSearchResults: Array<components['schemas']['DepartmentSummaryResponse']>
  piListQueryParams: IPiListFilters
  piListSearch: string
  piListFiltersChanged: boolean
  piListPageToFetch: number
  piPictures: Array<{ piCode: string, url: string }>
  piPicByCode: Computed<this, (code: string | undefined) => { piCode: string, url: string } | undefined, {}>
}
interface Actions {
  setAllDepartmentsLists: Action<this, Array<components['schemas']['DepartmentSummaryResponse']>>
  setCurrentDepartmentsLists: Action<this, Array<components['schemas']['DepartmentSummaryResponse']>>
  setCurrentDepartment: Action<this, ICurrentDepartment>
  setFetchedPiList: Action<this, ICurrentPiList>
  setAllPiList: Action<this, Array<components['schemas']['SummaryResponse']>>
  pushAllPiList: Action<this, Array<components['schemas']['SummaryResponse']>>
  setPiListFetchError: Action<this, boolean>
  setPiListShowSkeleton: Action<this, boolean>
  setFetchError: Action<this, boolean>
  setShowSkeleton: Action<this, boolean>
  setLoadingMorePis: Action<this, boolean>
  setSection: Action<this, Section>
  setBreadcrumbItems: Action<this, Array<{ label?: string, clickHandler?: () => void }>>
  pushBreadcrumbItems: Action<this, { label?: string, clickHandler?: () => void }>
  removeBreadcrumbItems: Action<this, string | undefined>
  removeLastBreadcrumbItem: Action<this>
  clearBreadcrumbItems: Action<this>
  setSearchString: Action<this, string>
  clearSearchString: Action<this>
  setSearchResults: Action<this, Array<components['schemas']['DepartmentSummaryResponse']>>
  clearSearchResults: Action<this>
  setPiListQueryParams: Action<this, { rollup?: boolean, onlyInternal?: boolean }>
  setPiListSearch: Action<this, string>
  setPiListFiltersChanged: Action<this, boolean>
  setPiListPageToFetch: Action<this, number>
  incrPiListPageToFetch: Action<this>
  pushPiPicture: Action<this, { piCode: string, url: string }>
}

interface ActionOns {
  onChangeDepartment: ActionOn<this>
  onChangePiListFilters: ActionOn<this>
  onChangePiListSearch: ActionOn<this>
}

type ICurrentPiList = {
  departmentCode?: string
} & components['schemas']['SummaryListResponse']

export interface ICurrentDepartment {
  name?: string
  code?: string
}

export type Section = 'unitsList' | 'piList' | 'piItems' | 'deptItems'
interface Thunks {
  fetchInitialPage: Thunk<this, undefined>
  fetchPiList: Thunk<this, { departmentId: string, page: number, rollup: boolean, onlyInternal: boolean, principalInvestigatorName: string }>
}

export interface IHigherLevelAwardsModel extends State, Actions, ActionOns, Thunks {}

export const HigherLevelAwardsModel: IHigherLevelAwardsModel = {
  // Initial State
  allDepartmentsList: [],
  currentDepartmentsList: [],
  currentDepartment: {},
  isLeafNodeDepartment: computed((state) => state.currentDepartmentsList.length === 0),
  fetchedPiList: {},
  allPiList: [],
  piListFetchError: false,
  piListShowSkeleton: false,
  fetchError: false,
  showSkeleton: true,
  loadingMorePis: false,
  section: 'unitsList',
  breadcrumbItems: [],
  // hideToggle: computed(
  //   (state) =>
  //     (state.currentDepartmentsList.length === 0 && !state.showSkeleton && !state.fetchError) /*  || state.currentDepartment.name === undefined */ || state.section === 'piItems'
  // ),
  hidePiToggle: computed((state) => state.currentDepartment.name === undefined || state.unitSearchString !== '' /* || state.currentDepartmentsList.length === 0 */),
  unitSearchString: '',
  unitSearchResults: [],
  piListQueryParams: { rollup: false, onlyInternal: false },
  piListSearch: '',
  piListFiltersChanged: false,
  piListPageToFetch: 1,
  piPictures: [],
  piPicByCode: computed((state) => {
    // Note how we are returning a function instead of state
    //      👇
    return (code) => state.piPictures.find((item) => item.piCode === code)
  }),

  // Actions
  setAllDepartmentsLists: action((state, payload) => {
    state.allDepartmentsList = payload
  }),

  setCurrentDepartmentsLists: action((state, payload) => {
    state.currentDepartmentsList = payload
  }),

  setCurrentDepartment: action((state, payload) => {
    state.currentDepartment = payload
  }),

  setFetchedPiList: action((state, payload) => {
    state.fetchedPiList = payload
  }),

  setAllPiList: action((state, payload) => {
    state.allPiList = payload
  }),

  pushAllPiList: action((state, payload) => {
    state.allPiList = state.allPiList.concat(payload)
  }),

  setPiListFetchError: action((state, payload) => {
    state.piListFetchError = payload
  }),

  setPiListShowSkeleton: action((state, payload) => {
    state.piListShowSkeleton = payload
  }),

  setLoadingMorePis: action((state, payload) => {
    state.loadingMorePis = payload
  }),

  setFetchError: action((state, payload) => {
    state.fetchError = payload
  }),

  setShowSkeleton: action((state, payload) => {
    state.showSkeleton = payload
  }),

  setSection: action((state, payload) => {
    state.section = payload
  }),

  setBreadcrumbItems: action((state, payload) => {
    state.breadcrumbItems = payload
  }),

  pushBreadcrumbItems: action((state, payload) => {
    state.breadcrumbItems.push(payload)
  }),

  removeBreadcrumbItems: action((state, payload) => {
    const breadcrumbItemIndex = state.breadcrumbItems.findIndex((item) => item.label === payload) + 1
    state.breadcrumbItems = state.breadcrumbItems.slice(0, breadcrumbItemIndex)
  }),

  removeLastBreadcrumbItem: action((state) => {
    state.breadcrumbItems = state.breadcrumbItems.slice(0, -1)
  }),

  clearBreadcrumbItems: action((state) => {
    state.breadcrumbItems = []
  }),

  setSearchString: action((state, payload) => {
    state.unitSearchString = payload
  }),

  clearSearchString: action((state) => {
    state.unitSearchString = ''
  }),

  setSearchResults: action((state, payload) => {
    state.unitSearchResults = payload
  }),

  clearSearchResults: action((state) => {
    state.unitSearchResults = []
  }),

  setPiListQueryParams: action((state, payload) => {
    state.piListQueryParams = { ...state.piListQueryParams, ...payload }
  }),

  setPiListSearch: action((state, payload) => {
    state.piListSearch = payload
  }),

  setPiListFiltersChanged: action((state, payload) => {
    state.piListFiltersChanged = payload
  }),

  setPiListPageToFetch: action((state, payload) => {
    state.piListPageToFetch = payload
  }),

  incrPiListPageToFetch: action((state) => {
    state.piListPageToFetch += 1
  }),

  pushPiPicture: action((state, payload) => {
    state.piPictures.push(payload)
  }),

  // ActionOns
  onChangeDepartment: actionOn(
    (actions) => actions.setCurrentDepartment,
    (state) => {
      if (state.allPiList.length > 0) {
        state.fetchedPiList = {}
        state.allPiList = []
      }
    }
  ),

  onChangePiListFilters: actionOn(
    (actions) => actions.setPiListQueryParams,
    (state) => {
      state.piListFiltersChanged = true
      state.piListPageToFetch = 1
    }
  ),

  onChangePiListSearch: actionOn(
    (actions) => actions.setPiListSearch,
    (state) => {
      state.piListFiltersChanged = true
      state.piListPageToFetch = 1
    }
  ),

  // Thunks
  fetchInitialPage: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    if (!state.showSkeleton) {
      actions.setShowSkeleton(true)
    }
    if (state.fetchError) {
      actions.setFetchError(false)
    }
    try {
      const data = await piDashController.getDepartmentsAwardsSummary(/* `${ITEMS_PER_PAGE}`, '1', payload */)
      actions.setAllDepartmentsLists(data)
      actions.setCurrentDepartmentsLists(data)
      if (state.fetchError) {
        actions.setFetchError(false)
      }
    } catch (err: any) {
      console.log(err)
      if (err.name !== 'AbortError') {
        actions.setFetchError(true)
        return await Promise.reject(err)
      }
    } finally {
      actions.setShowSkeleton(false)
    }
  }),

  fetchPiList: thunk(async (actions, payload, { getState }) => {
    const isFirstPage = payload.page === undefined || payload.page === 1
    const { piListFetchError, piListShowSkeleton } = getState()
    const { setFetchedPiList, setPiListShowSkeleton, setPiListFetchError, setAllPiList, pushAllPiList, setLoadingMorePis } = actions
    if (isFirstPage) {
      if (!piListShowSkeleton) {
        setPiListShowSkeleton(true)
      }
    } else {
      // Not first page
      setLoadingMorePis(true)
    }
    if (piListFetchError) {
      setPiListFetchError(false)
    }
    if (payload !== undefined) {
      try {
        const data = await piDashController.getDepartmentAwardPiList(payload.departmentId, payload.rollup, payload.page, payload.onlyInternal, payload.principalInvestigatorName)
        setFetchedPiList({ ...data, departmentCode: payload.departmentId })
        if (data.results !== undefined) {
          if (payload.page === 1) {
            setAllPiList(data.results)
          } else {
            pushAllPiList(data.results)
          }
        }
      } catch (err: any) {
        if (err.name !== 'AbortError') {
          setPiListFetchError(true)
          return await Promise.reject(err)
        }
      } finally {
        setPiListShowSkeleton(false)
        setLoadingMorePis(false)
      }
    }
  })
}
