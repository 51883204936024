import { persist } from 'easy-peasy'

// APP SPECIFIC STORES - EDIT THESE OR ADD MORE HERE:
import { IAppConfigModel, AppConfigModel } from './AppConfig'
import { IGlobalModel, GlobalModel } from './Global'
import { IAwardsModel, AwardsModel } from './Awards'
import { IProposalsModel, ProposalsModel } from './Proposals'
import { IProtocolsModel, ProtocolsModel } from './Protocols'
import { IAgreementsModel, AgreementsModel } from './Agreements'
import { IActionsModel, ActionsModel } from './Actions'
import { IGettingStartedModel, GettingStartedModel } from './GettingStarted'
import { IHigherLevelProposalsModel, HigherLevelProposalsModel } from './HigherLevelProposals'
import { IHigherLevelAwardsModel, HigherLevelAwardsModel } from './HigherLevelAwards'
import { ILocalStorageModel, LocalStorageModel } from './LocalPresist'
import { ISessionStorageModel, SessionStorageModel } from './SessionPresist'
import { IProfileModel, ProfileModel } from './Profile'

export interface Model {
  AppConfigModel: IAppConfigModel
  GlobalModel: IGlobalModel
  AwardsModel: IAwardsModel
  ProposalsModel: IProposalsModel
  ProtocolsModel: IProtocolsModel
  AgreementsModel: IAgreementsModel
  ActionsModel: IActionsModel
  GettingStartedModel: IGettingStartedModel
  LocalStorageModel: ILocalStorageModel
  ProfileModel: IProfileModel
  SessionStorageModel: ISessionStorageModel
  HigherLevelProposalsModel: IHigherLevelProposalsModel
  HigherLevelAwardsModel: IHigherLevelAwardsModel
}

export const model: Model = {
  // FOR APP CONFIG MANEGEMENT - AppConfigModel
  AppConfigModel,
  // FOR GLOBAL STATE MANAGEMENT - GlobalModel
  GlobalModel,
  // FOR AWARDS STATE MANAGEMENT - AwardsModel
  AwardsModel,
  // FOR PROPOSALS STATE MANAGEMENT - ProposalsModel
  ProposalsModel,
  // FOR PROTOCOLS STATE MANAGEMENT - ProtocolsModel
  ProtocolsModel,
  // FOR AGREEMENTS STATE MANAGEMENT - AgreementsModel
  AgreementsModel,
  // FOR GETTING STARTED STATE MANAGEMENT - GettingStartedModel
  ActionsModel,
  // FOR GETTING ACTIONS MANAGEMENT - ActionsModel
  GettingStartedModel,
  // FOR PROFILE STATE MANAGEMENT - ProfileModel
  ProfileModel,
  // FOR HIGHER LEVEL PROPOSALS STATE MANAGEMENT - HigherLevelProposalsModel
  HigherLevelProposalsModel,
  // FOR HIGHER LEVEL AWARD STATE MANAGEMENT - HigherLevelAwardsModel
  HigherLevelAwardsModel,
  // FOR LOCALSTORAGE MANAGEMENT - LocalStorageModel
  LocalStorageModel: persist(LocalStorageModel, {
    storage: 'localStorage'
  }),
  // FOR SESSIONSTORAGE MANAGEMENT - SESSIONSTORAGE
  SessionStorageModel: persist(SessionStorageModel, {
    storage: 'sessionStorage'
  })
}
