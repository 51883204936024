import Masonry from '@mui/lab/Masonry'
import { Paper, Skeleton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
// import { UnderConstruction } from 'components/routes'
// import ConfigureScreen from '../GettingStarted/ConfigureScreen'
import LayoutSettings from 'components/GettingStarted/LayoutSettings'
import MasonryComponent from 'components/GettingStarted/MasonryComponent'
import { gettingStartedCardsSx } from 'components/styles'
import cloneDeep from 'lodash/cloneDeep'
import React, { useEffect } from 'react'
// import { gettingStartedData } from '../../mock-data/gettingStarted'
// import { HuiIcon } from '@mit/hui-core'
import { Helmet } from 'react-helmet'
import { useStoreActions, useStoreState } from 'store/hooks'
import { components } from 'types'

export interface Items {
  shown: Array<components['schemas']['GettingStartedResponse']>
  deleted: Array<components['schemas']['GettingStartedResponse']>
}

const loadingSkeleton = (
  <Masonry columns={{ xs: 1, md: 2, lg: 3, xl: 4 }} sx={{ m: 0 }} spacing={2}>
    {[...Array(10)].map((_, i) => (
      <Paper key={`gssk-${i}`} sx={gettingStartedCardsSx} elevation={0}>
        <Skeleton variant='circular' sx={{ width: '30px', height: '30px', margin: 'auto', mb: '16px' }} />
        {/* <FontAwesomeIcon icon={['far', item.icon as IconName]} /> */}
        <Typography variant='h4' sx={{ textAlign: 'center' }}>
          <Skeleton />
        </Typography>
        {[...Array(5)].map((_, i2) => (
          <Typography key={`text-Line-${i}-${i2}`} variant='body1'>
            <Skeleton />
          </Typography>
        ))}
      </Paper>
    ))}
  </Masonry>
)

export const GettingStarted: React.FC = () => {
  const { configureActive, configuredItems, removedItems, loadingItems } = useStoreState((state) => state.GettingStartedModel)
  const { fetchGettingStartedItems /* , setConfigureActive, setGettingStartedItems, setRemovedItems */ } = useStoreActions((actions) => actions.GettingStartedModel)
  const { appName } = useStoreState((state) => state.AppConfigModel)
  const { profileFetched } = useStoreState((store) => store.ProfileModel)
  const shownArr = cloneDeep(configuredItems)
  const deletedArr = cloneDeep(removedItems)
  const [items, setItems] = React.useState<Items>({ shown: shownArr, deleted: deletedArr })

  useEffect(() => {
    if (profileFetched) {
      fetchGettingStartedItems()
    }
  }, [fetchGettingStartedItems, profileFetched])

  // const configBtnHandler = (): void => {
  //   if (!configureActive) {
  //     setConfigureActive(true)
  //   } else {
  //     setGettingStartedItems(items.shown)
  //     setRemovedItems(items.deleted)
  //     // setGettingStartedItems(configuredItems)
  //     setConfigureActive(false)
  //   }
  // }

  return (
    <>
      <Helmet>
        <title>HELPFUL LINKS</title>
        <meta name='description' content={` ${appName} HOME PAGE DESCRIPTION`} />
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h2' mb={4} ml={1} sx={{ color: 'black', fontSize: '36px' }}>
            Helpful Links
          </Typography>
          {/* Temporarily removed Configure Layout Button */}
          {/* <Button onClick={configBtnHandler} sx={{ alignSelf: 'flex-start' }} startIcon={<FontAwesomeIcon icon={['far', 'gear']} />}>
            {configureActive ? 'Save Layout' : 'Configure Screen'}
          </Button> */}
        </Box>
        {(loadingItems || !profileFetched) && loadingSkeleton}
        {!configureActive && !loadingItems && <MasonryComponent />}
        {configureActive && <LayoutSettings items={items} setItems={setItems} />}
      </Box>
    </>
  )
}
