import { Grid } from '@mui/material'
import { CardsSkeleton, NoResults } from 'components/UIComponents'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'store/hooks'
import AwardSummItemCardComp from './AwardSummItemCardComp'
import { GenericError } from '../UIComponents/Common/GenericError'
import { components } from 'types'
// import { components } from 'types'

// interface UnitsCardsProps {
//   searchResults: Array<components['schemas']['DepartmentSummaryResponse']>
// }

export const UnitsCards: React.FC /* <UnitsCardsProps> */ = (/* { searchResults } */) => {
  const { profileFetched } = useStoreState((store) => store.ProfileModel)
  const [fetchError, setFetchError] = useState(false)
  const {
    allDepartmentsList,
    currentDepartmentsList,
    showSkeleton,
    currentDepartment,
    unitSearchResults: searchResults,
    isLeafNodeDepartment
  } = useStoreState((store) => store.HigherLevelAwardsModel)
  const { fetchInitialPage, setSection } = useStoreActions((store) => store.HigherLevelAwardsModel)

  const allDeptListLength = allDepartmentsList.length
  useEffect(() => {
    if (profileFetched && allDeptListLength < 1) {
      fetchInitialPage().catch(() => setFetchError(true))
    }
  }, [fetchInitialPage, profileFetched, allDeptListLength])

  const currDeptName = currentDepartment.name
  useLayoutEffect(() => {
    if (isLeafNodeDepartment && currDeptName !== undefined) {
      setSection('piList')
    }
  }, [currDeptName, isLeafNodeDepartment, setSection])

  const listToRender = (): Array<components['schemas']['DepartmentSummaryResponse']> => {
    if (searchResults.length > 0) {
      return searchResults
    }
    return currentDepartmentsList
  }

  return (
    <>
      {!showSkeleton && !fetchError && currentDepartmentsList.length === 0 && <NoResults page='Units' />}
      <Grid container spacing={2.5} sx={{ mt: 0 }}>
        {showSkeleton || (currentDepartmentsList.length === 0 && !fetchError) ? (
          <CardsSkeleton />
        ) : (
          listToRender().length > 0 &&
          listToRender().map((dept, idx) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={`dptItem-${idx}`}>
              <AwardSummItemCardComp item={dept} index={idx} />
            </Grid>
          ))
        )}
      </Grid>
      {fetchError && <GenericError page='Units' />}
    </>
  )
}
