import { NavigateNext } from '@mui/icons-material'
import { Breadcrumbs, Button } from '@mui/material'
import React from 'react'

interface DrawersBreadcrumbsProps {
  breadcrumbItems: Array<{
    label: string
    onClick: () => void
  }>
}

const DrawersBreadcrumbs: React.FC<DrawersBreadcrumbsProps> = ({ breadcrumbItems }) => {
  return (
    <>
      <Breadcrumbs sx={{ '& .MuiBreadcrumbs-separator': { margin: 0 } }} aria-label='breadcrumb' maxItems={3} separator={<NavigateNext fontSize='small' />}>
        {breadcrumbItems.map((item, i) => {
          const { onClick } = item
          return (
            <Button size='small' sx={{ p: 0.5, minWidth: '14px' }} key={`bc-${item.label}`} variant='text' onClick={onClick}>
              {item.label}
            </Button>
          )
        })}
      </Breadcrumbs>
    </>
  )
}

export default DrawersBreadcrumbs
