import { Box, Grid } from '@mui/material'
import { FilterDropDown } from 'components/Filters/FilterDropDown'
import { CardsSkeleton, ListFilterInputBox, NoResults } from 'components/UIComponents'
import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useStoreActions, useStoreState } from 'store/hooks'
import { components } from 'types'
import ProposalItemCardComp from '../ProposalItem/ProposalItemCardComp'
import { ProposalTypeFilterDropDown } from './ProposalTypeFilterDropDown'

export const ProposalsCards: React.FC = () => {
  const [doFetchInitialProposals, setDoFetchInitialProposals] = useState(true)
  const { profileFetched } = useStoreState((store) => store.ProfileModel)
  const { section, isLeafNodeDepartment } = useStoreState((store) => store.HigherLevelProposalsModel)
  const {
    allProposalsLists,
    proposalsListTotalPages,
    proposalsListCurrentPage,
    proposalsListTotalItems,
    listFilters,
    showSkeleton,
    pageToFetch,
    fetchError,
    reFetchProposals,
    rolledUp
  } = useStoreState((store) => store.ProposalsModel)
  const { setListFilters, clearListFilters, fetchInitialPage, fetchMorePages, setFiltersChanged, setPageToFetch, incrementPageToFetch, setRolledUp } = useStoreActions(
    (store) => store.ProposalsModel
  )

  const { ref, inView /* , entry */ } = useInView({
    /* Optional options */
    triggerOnce: false,
    initialInView: true,
    threshold: 0
  })

  const adminRolesCheck = true
  useEffect(() => {
    if (reFetchProposals || doFetchInitialProposals) {
      setDoFetchInitialProposals(false)
      if (profileFetched) {
        fetchInitialPage({ listFilters, adminRolesCheck, rolledUp }) /* .catch(() => setFetchError(true)) */
      }
    }
  }, [adminRolesCheck, fetchInitialPage, doFetchInitialProposals, listFilters, profileFetched, reFetchProposals, rolledUp])

  useEffect(() => {
    if (pageToFetch > 1) {
      if (proposalsListCurrentPage < proposalsListTotalPages && pageToFetch > proposalsListCurrentPage) {
        fetchMorePages({ pageToFetch, listFilters, adminRolesCheck, rolledUp }) /* .catch(() => setFetchError(true)) */
      }
    }
  }, [pageToFetch, listFilters, proposalsListCurrentPage, proposalsListTotalPages, proposalsListTotalItems, fetchMorePages, adminRolesCheck, rolledUp])

  useEffect(() => {
    if (inView) {
      incrementPageToFetch()
    }
  }, [inView, incrementPageToFetch])

  useEffect(() => {
    return () => {
      setPageToFetch(1)
      clearListFilters()
      setRolledUp(false)
    }
  }, [clearListFilters, setPageToFetch, setRolledUp])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setListFilters({ title: e.target.value })
    setFiltersChanged(true)
    setPageToFetch(1)
  }

  const debounceHandleSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      handleSearch(e)
    },
    800,
    { leading: false }
  )

  return (
    <>
      <Box sx={{ p: 1, px: 0, width: '100%' }}>
        <Box sx={{ mt: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ListFilterInputBox
            label='Filter List'
            changeHandler={(e) => {
              debounceHandleSearch(e)
            }}
          />
          <Box>
            <FilterDropDown filterFor='Proposals' />
            {section === 'deptItems' && !isLeafNodeDepartment && <ProposalTypeFilterDropDown />}
          </Box>
        </Box>
        {/* <Divider sx={{ mt: 2 }} /> */}
        {allProposalsLists[0]?.results?.length === 0 && !showSkeleton && <NoResults page='Proposals' />}
        <>
          <Grid container spacing={2.5} sx={{ mt: 0 /* , justifyContent: 'center' */ }}>
            {showSkeleton || (allProposalsLists.length === 0 && !fetchError) ? (
              <CardsSkeleton />
            ) : (
              allProposalsLists.length > 0 &&
              allProposalsLists.map((page, idx1) => {
                return page.results?.map((listItem, idx2) => <MemoGridItem key={listItem.documentNumber} idx2={idx2} listItem={listItem} />)
              })
            )}
            {proposalsListCurrentPage < proposalsListTotalPages && !fetchError && <CardsSkeleton />}
          </Grid>
          <Box ref={ref} />
        </>
      </Box>
    </>
  )
}

interface MemoGridItemProps {
  idx2: number
  listItem: components['schemas']['ProposalResponse']
}

const MemoGridItem = React.memo(
  ({ idx2, listItem }: MemoGridItemProps) => {
    return (
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        xl={3}
        // ref={idx1 === allProposalsLists.length - 1 && idx2 === ITEMS_PER_PAGE - 1 ? ref : undefined}
      >
        <ProposalItemCardComp item={listItem} index={idx2} />
      </Grid>
    )
  },
  (prevProps, nextProps) => prevProps.listItem.documentNumber === nextProps.listItem.documentNumber
)
