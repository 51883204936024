export const getChipColor = (statusCode?: string): 'default' | 'error' | 'success' | 'primary' | 'secondary' | 'info' | 'warning' | undefined => {
  switch (statusCode) {
    case 'Active':
    case 'Approved':
    case 'Approved and Submitted':
    case 'Funded':
    case 'Submitted':
      return 'success'

    case 'Inactive':
    case 'Closed':
    case 'Terminated':
    case 'Restricted - No Postings Allowed':
    case 'HIGH':
      return 'error'

    case 'Pending Submission':
    case 'Action Required':
    case 'Pending':
    case 'Revisions Requested':
    case 'Hold':
    case 'MEDIUM':
      return 'warning'

    case 'In Progress':
    case 'Approval Pending':
      return 'default'

    default:
      return 'default'
  }
}
