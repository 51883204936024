import { Box, Grid, IconButton, Typography } from '@mui/material'
import { FilterDropDown } from 'components/Filters/FilterDropDown'
import { CardsSkeleton, DialogModal, GenericError, /* , FetchErrorToast, SearchBoxIconWrapper */ ListFilterInputBox, NoResults } from 'components/UIComponents'
// import { AwardsFilterModal } from 'components/Filters/AwardsFilterModal'
// import AwardsFilterBadges from 'components/Filters/AwardFilterBadges'
import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
import { useStoreActions, useStoreState } from 'store/hooks'
import AwardItemCardComp from '../AwardItem/AwardItemCardComp'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MDWithCompMapping from 'components/UIComponents/Common/MDWithCompMapping'
import { components } from 'types'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { ITEMS_PER_PAGE } from '../../store/constants'

export const AwardsPage: React.FC = () => {
  const [infoDialogueOpen, setInfoDialogueOpen] = useState(false)
  const [doFetchInitialAwards, setDoFetchInitialAwards] = useState(true) // temp. Need to rework this logic
  const [fetchError, setFetchError] = useState(false)
  const { appName } = useStoreState((state) => state.AppConfigModel)
  const { texts } = useStoreState((state) => state.GlobalModel)
  const infoTextTitle = texts.find((entry) => entry.textId === 'awards.info.title')?.text
  const infoTextBody = texts.find((entry) => entry.textId === 'awards.info.body')?.text
  // const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const {
    allAwardsLists,
    awardsListTotalPages,
    awardsListCurrentPage,
    awardsListTotalItems,
    listFilters,
    showSkeleton,
    // filtersChanged,
    /* loadingMore,  */ pageToFetch /* , fetchError */,
    reFetchAwards
  } = useStoreState((store) => store.AwardsModel)
  const { profileFetched } = useStoreState((store) => store.ProfileModel)
  const {
    setListFilters,
    fetchInitialPage,
    fetchMorePages,
    setFiltersChanged,
    setPageToFetch,
    incrementPageToFetch
    // clearAllAwardsFinancials
  } = useStoreActions((store) => store.AwardsModel)

  const { ref, inView /* , entry */ } = useInView({
    /* Optional options */
    triggerOnce: false,
    initialInView: true,
    threshold: 0
  })

  // const listLength = allAwardsLists.length
  const adminRolesCheck = false
  const rolledUp = false
  useEffect(() => {
    // if (listLength === 0 || (listLength > 0 && pageToFetch === 1 && filtersChanged)) {
    if (reFetchAwards || doFetchInitialAwards) {
      setDoFetchInitialAwards(false)
      if (profileFetched) {
        fetchInitialPage({ listFilters, adminRolesCheck, rolledUp }).catch(() => setFetchError(true))
      }
    }
  }, [adminRolesCheck, doFetchInitialAwards, fetchInitialPage, listFilters, profileFetched, reFetchAwards, rolledUp])
  // }, [pageToFetch, listLength, listFilters, filtersChanged, fetchInitialPage, clearIndexOfFinancialToFetch, clearAllAwardsFinancials, profileFetched, adminRolesCheck])

  useEffect(() => {
    if (pageToFetch > 1) {
      if (awardsListCurrentPage < awardsListTotalPages && pageToFetch > awardsListCurrentPage) {
        fetchMorePages({ pageToFetch, listFilters, adminRolesCheck, rolledUp }).catch(() => setFetchError(true))
      }
    }
  }, [pageToFetch, listFilters, awardsListCurrentPage, awardsListTotalPages, awardsListTotalItems, fetchMorePages, adminRolesCheck, rolledUp])

  useEffect(() => {
    if (inView) {
      incrementPageToFetch()
      // setPageToFetch((ptf) => ptf + 1)
    }
  }, [inView, incrementPageToFetch])

  useEffect(() => {
    return () => setPageToFetch(1)
  }, [setPageToFetch])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setListFilters({ title: e.target.value })
    setFiltersChanged(true)
    setPageToFetch(1)
  }

  const debounceHandleSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      handleSearch(e)
    },
    800,
    { leading: false }
  )

  return (
    <>
      <Helmet>
        <title>AWARDS</title>
        <meta name='description' content={` ${appName} AWARDS PAGE DESCRIPTION`} />
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <Typography variant='h3' sx={{ color: 'common.AtlasBrand.brand_2.main', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box component='span'>Awards</Box>
          <IconButton onClick={() => setInfoDialogueOpen(true)}>
            <InfoOutlinedIcon />
          </IconButton>
        </Typography>
        <Box sx={{ mt: 2.5, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {/* <SearchBoxIconWrapper /> */}
          <ListFilterInputBox
            label='Filter List'
            changeHandler={(e) => {
              debounceHandleSearch(e)
            }}
          />
          <FilterDropDown filterFor='Awards' />

          {/* <Box sx={{width:'100px', height: '24px', backgroundColor:'blue', ml: 'auto', mr: 0}} /> */}
          {/* <IconButton sx={{ ml: 1 }} aria-label='filter' onClick={() => setShowFilterModal(true)}>
            <FontAwesomeIcon icon={['far', 'filter']} color='#097AFF' />
          </IconButton>
          <AwardsFilterBadges listFilters={listFilters}/> */}
        </Box>
        {/* <Divider sx={{ mt: 2 }} /> */}
        {allAwardsLists[0]?.results?.length === 0 && !showSkeleton && <NoResults page='Awards' />}
        <Grid container spacing={2.5} sx={{ mt: 0 }}>
          {showSkeleton || (allAwardsLists.length === 0 && !fetchError) ? (
            <CardsSkeleton />
          ) : (
            allAwardsLists.length > 0 &&
            allAwardsLists.map((page) => {
              return page.results?.map((listItem, idx2) => <MemoGridItem key={listItem.awardNumber} idx2={idx2} listItem={listItem} />)
            })
          )}
          {awardsListCurrentPage < awardsListTotalPages && !fetchError && <CardsSkeleton />}
        </Grid>
        <Box ref={ref} />
        {fetchError && <GenericError page='Awards' />}
        {/* <FetchErrorToast open={fetchError} closeHandler={() => setFetchError(false)} /> */}
        {/* <AwardsFilterModal
          open={showFilterModal}
          closeHandler={() => setShowFilterModal(false)}
          applyBtnHandler={() => {
            setFiltersChanged(true)
            setPageToFetch(1)
          }}
        /> */}
      </Box>
      <DialogModal open={infoDialogueOpen} closeHandler={() => setInfoDialogueOpen(false)} title={<Typography variant='h3'>{infoTextTitle}</Typography>}>
        <MDWithCompMapping content={infoTextBody} />
      </DialogModal>
    </>
  )
}

interface MemoGridItemProps {
  idx2: number
  listItem: components['schemas']['AwardResponse']
}

const MemoGridItem = React.memo(
  ({ idx2, listItem }: MemoGridItemProps) => {
    return (
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        xl={3}
        // ref={idx1 === allProposalsLists.length - 1 && idx2 === ITEMS_PER_PAGE - 1 ? ref : undefined}
      >
        <AwardItemCardComp item={listItem} cardIndex={idx2} />
      </Grid>
    )
  },
  (prevProps, nextProps) => prevProps.listItem.awardNumber === nextProps.listItem.awardNumber
)
